import React from 'react';
import { useBulkVerifyStepsContext } from '../context/BulkVerifyStepsContext';

import { ADDRESS_FIELDS } from './BulkStep2';
import CountryFieldMapping from './CountryFieldMapping';
import FieldMap from './FieldMap';

export interface FieldMappingProps {
    title: string;
    value: string;
}

const FieldMapping: React.FC<FieldMappingProps> = (props) => {
    const { setInputMapping, inputMapping } = useBulkVerifyStepsContext();

    if (props.value === ADDRESS_FIELDS.Country) {
        return <CountryFieldMapping {...props} />;
    }

    const handleChange = (e: React.ChangeEvent<{ value: any }>) => {
        setInputMapping({
            ...inputMapping,
            [props.value]: e.target.value,
        });
    };

    return <FieldMap {...props} onSelectChange={handleChange} />;
};

export default FieldMapping;
