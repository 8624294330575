import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import {
    useAuthContext,
    LoginPayload,
    State as AuthState,
} from '../context/Auth';
import { fetchAPI, APIRequestInit } from './util';

export interface EmbeddedSecret {
    id: string;
    isRetired: boolean;
    user: string;
    organization: string;
    createdAt: Date;
    updatedAt: Date;
}

export class Service {
    constructor(
        private auth: AuthState,
        private persistAuth: (payload: LoginPayload) => void,
        private history: ReturnType<typeof useHistory>,
        private endpoint: string
    ) {}

    private async fetchAPI<T>(
        path: string,
        init: APIRequestInit = {}
    ): Promise<T> {
        if (this.auth?.token) {
            Object.assign(init, {
                headers: {
                    ...init.headers,
                    Authorization: `Bearer ${this.auth.token}`,
                },
            });
        }

        try {
            return await fetchAPI(path, init);
        } catch (err: any) {
            if (err.status === 401 || err.status === 403) {
                // TODO: Unauthorized embedded route.
                // this.history.replace(route)
            }

            // Requries the `/maintenance` route authorized to be iframed
            if (err.status === 503) {
                this.history.push(`/maintenance?msg=${err.message}`);
            }

            throw err;
        }
    }

    async authenticate(userID: string, secret: string) {
        const payload = await this.fetchAPI<LoginPayload>(
            `/users/${userID}/embed_secrets/${secret}/sessions/`,
            { method: 'POST' }
        );

        Object.assign(payload.user, { embeddedSecret: secret });
        this.persistAuth(payload);
        return payload;
    }

    async get() {
        return await this.fetchAPI<EmbeddedSecret | null>(this.endpoint);
    }

    async create() {
        return await this.fetchAPI<EmbeddedSecret>(this.endpoint, {
            method: 'POST',
        });
    }

    async retire(id: string) {
        await this.fetchAPI(`${this.endpoint}/${id}`, {
            method: 'DELETE',
        });
        return true;
    }
}

export const useService = () => {
    const { state, dispatchTempLogin } = useAuthContext();
    const history = useHistory();

    return useMemo(() => {
        return new Service(state, dispatchTempLogin, history, '/embed_secrets');
    }, [history, state, dispatchTempLogin]);
};
