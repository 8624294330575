import React from 'react';

import { Link } from 'react-router-dom';

import {
    Theme,
    ThemeProvider,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ListItemLink from './ListItemLink';
import logo from '../logo.png';
import {
    overviewIcon,
    developerIcon,
    bulkIcon,
    profileIcon,
    supportIcon,
    invoiceIcon,
    upgradeIcon,
    apiDocIcon,
    printMailIcon,
    userIcon,
} from './Icons';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '-10px',
    },
    navRoot: {
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
        height: '100%',
    },
    listItemRoot: {
        bottom: '0',
        position: 'absolute',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
}));

const Sidenav = () => {
    const classes = useStyles();

    return (
        <ThemeProvider
            theme={(theme: Theme) =>
                createMuiTheme({
                    ...theme,
                    typography: {
                        ...theme.typography,
                        body2: {
                            ...theme.typography.body2,
                            fontFamily: 'Poppins',
                            fontSize: '0.765rem',
                        },
                    },
                    overrides: {
                        MuiListItemIcon: {
                            root: {
                                minWidth: '24px',
                            },
                        },
                    },
                })
            }
        >
            <Paper
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '255px',
                    overflowY: 'auto',
                    padding: '10px 0 10px 0',
                    zIndex: 999,
                    lineHeight: 1,
                }}
            >
                <List component="nav" dense classes={{ root: classes.navRoot }}>
                    <ListItem>
                        <Box pr={2.5}>
                            <Link to="/dashboard">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    style={{ width: '132px' }}
                                />
                            </Link>
                        </Box>
                    </ListItem>

                    <Box ml={-2.5} style={{ marginTop: 10, marginBottom: 10 }}>
                        <Divider style={{ backgroundColor: '#eee' }} />
                    </Box>

                    <Box ml={1} mt={3} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Dashboard
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Overview"
                        icon={overviewIcon}
                        to="/dashboard"
                    />

                    <ListItemLink
                        primary="Developers"
                        icon={developerIcon}
                        to="/dashboard/developers"
                    />

                    <ListItemLink
                        primary="Bulk Verify"
                        icon={bulkIcon}
                        to="/dashboard/bulk_verify"
                    />

                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Settings
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Profile"
                        icon={profileIcon}
                        to="/dashboard/profile"
                    />

                    <ListItemLink
                        primary="Users"
                        icon={userIcon}
                        to="/dashboard/users"
                    />

                    <ListItemLink
                        primary="Support"
                        icon={supportIcon}
                        to="/dashboard/support"
                    />

                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Billing
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Invoices"
                        icon={invoiceIcon}
                        to="/dashboard/invoices"
                    />

                    <ListItemLink
                        primary="Upgrade"
                        icon={upgradeIcon}
                        to="/dashboard/upgrade"
                    />
                    <Box ml={1} mt={2} mb={1} px={2}>
                        <Typography
                            variant="subtitle2"
                            style={{ lineHeight: 1 }}
                        >
                            Links
                        </Typography>
                    </Box>

                    <ListItemLink
                        primary="Standard API Docs"
                        icon={apiDocIcon}
                        external
                        to="https://avdocs.postgrid.com"
                    />
                    <ListItemLink
                        primary="International API Docs"
                        icon={apiDocIcon}
                        external
                        to="https://intdocs.postgrid.com"
                    />

                    <ListItemLink
                        primary="Print & Mail"
                        icon={printMailIcon}
                        external
                        to="https://www.postgrid.com/print-mail-api/"
                    />
                </List>
            </Paper>
        </ThemeProvider>
    );
};

export default Sidenav;
