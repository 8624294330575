import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
    Grid,
    TableFooter,
    Typography,
    Dialog,
    Box,
    CircularProgress,
} from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import { BulkList, BulkListStatus } from '../services/BulkLists';

import ResetTablePagination from './ResetTablePagination';
import Button from './Button';

const useStyles = makeStyles({
    tableRow: {
        padding: '1.3rem',
        backgroundColor: 'white',
        boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
    },

    tableHeading: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        font: 'normal normal normal 18px/22px Roboto',
        letterSpacing: '0px',
        color: '#000000',
        opacity: 1,
    },

    tableCellRoot: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        font: 'normal normal normal 16px/19px Roboto',
        color: '#000000',
        opacity: 1,
        borderBottom: '1px #f2f2f2 solid',
    },
});

const snakeToTitleCase = (key: string) =>
    key
        .replace(/_/g, ' ')
        .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());

const DetailsDialog = ({
    open,
    onClose,
    list,
}: {
    open: boolean;
    onClose: () => void;
    list: BulkList;
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <Grid
                container
                justify="center"
                style={{
                    padding: '30px',
                }}
            >
                <Grid item container justify="flex-end" spacing={1}>
                    <Grid item>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid
                    item
                    style={{
                        maxWidth: '400px',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}
                >
                    <Typography variant="h5" display="inline">
                        {list.name}
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    spacing={1}
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Box pt={3} />
                    <Grid item container justify="center">
                        <Grid
                            item
                            container
                            spacing={2}
                            xs={6}
                            direction="column"
                        >
                            <Grid item>
                                <Typography>Created At</Typography>
                                <Typography variant="caption">
                                    {list.createdAt.toLocaleString()}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography>Last Updated At</Typography>
                                <Typography variant="caption">
                                    {list.updatedAt.toLocaleString()}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography>Address Count</Typography>
                                <Typography variant="caption">
                                    {list.count}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            spacing={2}
                            xs={6}
                            direction="column"
                        >
                            {list.metadata?.statusCount &&
                                Object.entries(list.metadata.statusCount).map(
                                    ([status, count]) => (
                                        <Grid item>
                                            <Typography variant="body1">
                                                {snakeToTitleCase(status)}
                                            </Typography>
                                            <Typography variant="caption">
                                                {count ?? 0}
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container justify="center" spacing={2}>
                    <Grid item xs={12}>
                        <Box mt={2} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(e) => onClose()}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                window.open(list.result, '_blank');
                                onClose();
                            }}
                            fullWidth
                            disabled={list.status !== BulkListStatus.PROCESSED}
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

const BulkTable = (props: {
    rows: BulkList[];

    totalCount: number;
    setTotalCount: (v: number) => void;

    rowsPerPage: number;
    setRowsPerPage: (v: number) => void;

    page: number;
    setPage: (v: number) => void;
}) => {
    const classes = useStyles();

    const { rows, totalCount, rowsPerPage, setRowsPerPage, page, setPage } =
        props;

    const [showDetails, setShowDetails] = useState(false);
    const [detailsRow, setDetailsRow] = useState<BulkList>();

    return (
        <>
            {detailsRow && (
                <DetailsDialog
                    open={showDetails}
                    onClose={() => setShowDetails(false)}
                    list={detailsRow}
                />
            )}
            <TableContainer>
                <Grid container>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeading}>
                                    File Name
                                </TableCell>

                                <TableCell className={classes.tableHeading}>
                                    Date
                                </TableCell>

                                <TableCell className={classes.tableHeading}>
                                    Address Count
                                </TableCell>

                                <TableCell className={classes.tableHeading}>
                                    Status
                                </TableCell>

                                <TableCell
                                    align="center"
                                    className={classes.tableHeading}
                                >
                                    Download
                                </TableCell>

                                <TableCell
                                    align="center"
                                    className={classes.tableHeading}
                                >
                                    View Details
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows?.map((row: BulkList, i: number) => (
                                <>
                                    <TableRow className={classes.tableRow}>
                                        <TableCell
                                            className={classes.tableCellRoot}
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCellRoot}
                                        >
                                            {row.createdAt.toLocaleString()}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCellRoot}
                                        >
                                            {row.count}
                                        </TableCell>
                                        <TableCell
                                            className={classes.tableCellRoot}
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {row.status ===
                                            BulkListStatus.PENDING ? (
                                                <CircularProgress size={30} />
                                            ) : (
                                                row.status
                                            )}
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCellRoot}
                                            align="center"
                                        >
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() =>
                                                    window.open(row.result)
                                                }
                                                disabled={
                                                    row.status !==
                                                    BulkListStatus.PROCESSED
                                                }
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                        </TableCell>

                                        <TableCell
                                            className={classes.tableCellRoot}
                                            align="center"
                                        >
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => {
                                                    setDetailsRow(row);
                                                    setShowDetails(true);
                                                }}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <ResetTablePagination
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={totalCount}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </Grid>
            </TableContainer>
        </>
    );
};

export default BulkTable;
