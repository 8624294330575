import React from 'react';

import { useBulkVerifyStepsContext } from '../context/BulkVerifyStepsContext';

import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { FieldMappingProps } from './FieldMapping';
import FieldMapInputContainer from './FieldMapInputContainer';

interface FieldMapProps extends FieldMappingProps {
    onSelectChange: (e: React.ChangeEvent<{ value: any }>) => void;
}

const FieldMap: React.FC<FieldMapProps> = ({
    title,
    value,
    onSelectChange,
}) => {
    const { inputMapping, fileHeaders } = useBulkVerifyStepsContext();

    return (
        <FieldMapInputContainer title={title}>
            <Select
                required={value === 'line1'}
                value={`${(inputMapping as any)[value] ?? ''}`}
                displayEmpty
                renderValue={(value) =>
                    !value ? (
                        <Typography color="textSecondary">
                            Select Column
                        </Typography>
                    ) : (
                        <Typography>{(value as any).toString()}</Typography>
                    )
                }
                fullWidth
                variant="outlined"
                onChange={onSelectChange}
            >
                <MenuItem key="none" value="">
                    Select an Option
                </MenuItem>

                {fileHeaders
                    .filter((h) => !Object.values(inputMapping).includes(h))
                    .map((h) => (
                        <MenuItem key={h} value={h}>
                            {h}
                        </MenuItem>
                    ))}
            </Select>
        </FieldMapInputContainer>
    );
};

export default FieldMap;
