import React from 'react';

import { Typography, Grid, makeStyles } from '@material-ui/core';

interface ContainerProps {
    children: JSX.Element;
    heading: string;
}

const useStyles = makeStyles({
    root: {
        backgroundColor: '#fff',
        border: '1px #e8e8e8 solid',
        padding: '1.1rem',
        borderRadius: '5px',
    },
});

const Container = (props: ContainerProps) => {
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid item>
                <Typography variant="h6">{props.heading}</Typography>
            </Grid>

            <Grid item>{props.children}</Grid>
        </Grid>
    );
};

export default Container;
