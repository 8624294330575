import React from 'react';
import { TextField, TextFieldProps, Typography } from '@material-ui/core';

const TextInput = (props: TextFieldProps & { showLabel?: boolean }) => {
    return (
        <>
            <Typography
                variant="body2"
                color="textSecondary"
                // onlly options ts is allowign for font weight are bold, bolder, light etc
                // @ts-ignore
                style={{ fontWeight: '400', marginBottom: '5px' }}
            >
                {props.label}
            </Typography>

            <TextField
                error={!!props.error}
                helperText={
                    props.variant !== 'outlined'
                        ? (props.defaultValue as any).toString()
                        : props.helperText
                }
                size={props.size || 'small'}
                variant={props.variant}
                style={props.style}
                disabled={props.disabled}
                defaultValue={
                    props.variant !== 'outlined'
                        ? props.label
                        : props.defaultValue
                }
                fullWidth={props.fullWidth}
                type={props.type}
                id={props.id}
                name={props.name}
                onBlur={props.onBlur}
                multiline={props.multiline}
                rows={props.rows}
                color={props.color || 'primary'}
                autoComplete={props.autoComplete}
                value={props.value}
                onChange={props.onChange}
                InputProps={props.InputProps}
            />
        </>
    );
};
export default TextInput;
