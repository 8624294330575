import React, { PropsWithoutRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import Button from './Button';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        borderRadius: '6px',
        '&>.MuiDialog-scrollPaper': {
            '&>.MuiDialog-paper': {
                borderRadius: '8px',
                padding: '20px 30px',
            },
        },
    },
}));

const ConfirmActionDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        onClose: (e: {}) => void;
        title: string;
        text: string;
        actionLabel: string;
        cancelLabel?: string;
        confirm: (e: {}) => void;
        image?: string;
    }>
) => {
    const classes = useStyles();

    return (
        <Dialog {...props} classes={classes}>
            <Grid container justify="flex-end" spacing={0} alignItems="center">
                <Grid item>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            props.onClose(e);
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <DialogContent>
                {props.image ? (
                    <img
                        src={props.image}
                        width={180}
                        alt={props.title}
                        style={{ margin: '10px auto', display: 'block' }}
                    />
                ) : null}
                <DialogTitle>
                    <Typography variant="h5">{props.title}</Typography>
                </DialogTitle>
                <DialogContentText>{props.text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(e) => {
                                props.onClose(e);
                            }}
                            fullWidth
                        >
                            {props.cancelLabel || 'Cancel'}
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                props.confirm(e);
                                props.onClose(e);
                            }}
                            fullWidth
                        >
                            {props.actionLabel}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmActionDialog;
