import React, { PropsWithoutRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: 0,
    },
    spacer: {
        flex: 'initial',
    },
    caption: {
        fontFamily: 'Roboto',
    },
}));

// Small wrapper over TablePagination that resets
// the page when rows per page is changed.
const ResetTablePagination = (
    props: PropsWithoutRef<{
        page: number;
        setPage: (value: number) => void;
        rowsPerPage: number;
        setRowsPerPage: (value: number) => void;
        count: number;
    }>
) => {
    const classes = useStyles();

    return (
        <TablePagination
            rowsPerPage={props.rowsPerPage}
            onChangeRowsPerPage={(e) => {
                const newRowsPerPage = parseInt(e.target.value);

                props.setPage(
                    Math.floor(
                        (props.rowsPerPage * props.page) / newRowsPerPage
                    )
                );
                props.setRowsPerPage(newRowsPerPage);
            }}
            page={props.page}
            onChangePage={(e, page) => {
                props.setPage(page);
            }}
            count={props.count}
            classes={classes}
            backIconButtonText="Previous"
            nextIconButtonText="Next"
        />
    );
};

export default ResetTablePagination;
