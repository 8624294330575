import logo from '../logo.png';

import loginGraphic from '../img/login-image.png';

import React, {
    FormEvent,
    useState,
    useRef,
    useEffect,
    useContext,
} from 'react';

import {
    useHistory,
    useLocation,
    useParams,
    Link as RouterLink,
} from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';

import { RECAPTCHA_SITE_KEY } from '../config';

import { notifySignup } from '../services/util';
import { useService as useUsersService } from '../services/Users';
import { useService as useInvitesService } from '../services/Invites';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { ActionType, Context as AuthContext } from '../context/Auth';

import Button from '../components/Button';

import { RequiredField, PasswordField } from './Login';

const Signup = () => {
    const history = useHistory();
    const location = useLocation();

    const { userID: inviteUserID } = useParams<{ userID: string }>();

    const service = useUsersService();
    const invService = useInvitesService();

    const { dispatch } = useContext(NotificationContext);
    const { dispatch: authDispatch } = useContext(AuthContext);

    const queryParams = new URLSearchParams(location.search);

    const [firstName, setFirstName] = useState(queryParams.get('fname') || '');
    const [lastName, setLastName] = useState(queryParams.get('lname') || '');

    const [email, setEmail] = useState(queryParams.get('email') || '');

    // HACK Kept in because we keep on flip-flopping between wanting number and not wanting number
    // const [phoneNumber, setPhoneNumber] = useState(queryParams.get('phone') || '');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);

    const [organizationName, setOrganizationName] = useState(
        queryParams.get('company') || ''
    );

    const [loading, setLoading] = useState(false);

    const confirmRef = useRef<HTMLInputElement>();
    // TODO Figure out the type for this ref
    const recaptchaRef = useRef<any>();

    // Sign out the user when they hit this page
    useEffect(() => {
        authDispatch({
            type: ActionType.LOGOUT,
        });
    }, [history.location, authDispatch]);

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== confirmPassword || !recaptchaRef) {
            return;
        }

        try {
            setLoading(true);

            // Check the recaptcha
            const recaptchaToken = await recaptchaRef.current.executeAsync();

            if (!inviteUserID) {
                notifySignup({
                    firstName,
                    lastName,
                    companyName: organizationName,
                    email,
                    // HACK See above for why this is kept in
                    // phoneNumber
                });

                await service.createSignup({
                    name: organizationName,
                    firstName,
                    lastName,
                    email,
                    password,
                    recaptchaToken,
                });

                dispatch({
                    type: MessageType.SUCCESS,
                    message:
                        'Check your email for a link to complete your signup.',
                });

                history.push(
                    `/signup_check_email?email=${encodeURIComponent(email)}`
                );
            } else {
                await invService.complete(inviteUserID, {
                    firstName,
                    lastName,
                    password,
                });

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Signed up successfully. Log in.',
                });

                history.push('/login');
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        const match = password === confirmPassword;

        if (confirmRef.current) {
            confirmRef.current.setCustomValidity(
                match ? '' : 'Passwords do not match.'
            );
        }
    }, [password, confirmPassword]);

    return (
        <form onSubmit={onSubmit}>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_SITE_KEY}
            />

            <Grid
                container
                alignItems="center"
                style={{ minHeight: '100vh', backgroundColor: 'white' }}
            >
                <Grid item xs={12} sm={6} style={{ height: '100vh' }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <img
                            src={loginGraphic}
                            alt="Login Graphic"
                            style={{
                                display: 'block',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Typography variant="h4">
                                    <Box fontSize={24}>
                                        {inviteUserID
                                            ? 'You have been invited to PostGrid. Complete your signup.'
                                            : 'Sign up to get access to PostGrid.'}
                                    </Box>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Box mt={1}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <RequiredField
                                                type="text"
                                                label="First Name"
                                                value={firstName}
                                                setValue={setFirstName}
                                                autoFocus
                                                siqatrib="firstname"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/* TODO Refactor: Make this not required */}
                                            <RequiredField
                                                type="text"
                                                label="Last Name"
                                                value={lastName}
                                                setValue={setLastName}
                                                siqatrib="lastname"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            {inviteUserID === undefined && (
                                <>
                                    <Grid item>
                                        <RequiredField
                                            type="text"
                                            label="Company Name"
                                            value={organizationName}
                                            setValue={setOrganizationName}
                                            disabled={!!inviteUserID}
                                            siqatrib="company"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <RequiredField
                                            type="email"
                                            label="Email"
                                            value={email}
                                            setValue={setEmail}
                                            disabled={!!inviteUserID}
                                            siqatrib="email"
                                        />
                                    </Grid>
                                </>
                            )}

                            {/* HACK See above for why this is kept in
                            <Grid item>
                                <RequiredField
                                    type="tel"
                                    label="Phone Number"
                                    value={phoneNumber}
                                    setValue={setPhoneNumber}
                                />
                            </Grid>
                             */}

                            <Grid item>
                                <PasswordField
                                    label="Password"
                                    value={password}
                                    setValue={setPassword}
                                    showPassword={showPassword}
                                    setShowPassword={setShowPassword}
                                />
                            </Grid>
                            <Grid item>
                                <PasswordField
                                    inputRef={confirmRef}
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    setValue={setConfirmPassword}
                                    showPassword={showPassword}
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox required color="primary" />
                                    }
                                    label={
                                        <Typography>
                                            I have read and agree to PostGrid's{' '}
                                            <Link
                                                target="_blank"
                                                href="https://postgrid.com/legal"
                                            >
                                                Terms of Service
                                            </Link>{' '}
                                            and{' '}
                                            <Link
                                                target="_blank"
                                                href="https://postgrid.com/legal"
                                            >
                                                Privacy Policy
                                            </Link>
                                            .
                                        </Typography>
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    size="large"
                                    fullWidth
                                >
                                    Sign Up
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box mt={4} />
                            </Grid>

                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Already have an account?{' '}
                                    <Link component={RouterLink} to="/login">
                                        Sign in
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
        </form>
    );
};

export default Signup;
