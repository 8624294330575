import React, { useEffect, useState } from 'react';
import { Typography, Grid, makeStyles, Box } from '@material-ui/core';

import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import ChatIcon from '@material-ui/icons/Chat';

import TextField from '../components/TextInput';
import Button from '../components/Button';
import TopNav from '../components/TopNav';

const styles = makeStyles({
    root: {
        padding: '1.3rem 2rem',
        backgroundColor: 'white',
        boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
        borderRadius: '5px',
    },
});

interface Support {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    message: string | undefined;
}

export function intercom() {
    if (window.Intercom) {
        window.Intercom('show');
    }
}

const Support = () => {
    useEffect(() => {
        intercom();
        return () => {
            if (window.Intercom) {
                window.Intercom('hide');
            }
        };
    }, []);

    const classes = styles();

    const [message, setMessage] = useState('');

    return (
        <>
            <TopNav />
            <Grid container className={classes.root}>
                <Grid
                    item
                    sm={12}
                    style={{
                        borderBottom: '1px #f2f2f2 solid',
                        paddingBottom: '1.3rem',
                    }}
                >
                    <Typography variant="h6">Chat with us</Typography>
                </Grid>

                <Grid justify="space-between" item spacing={4} container>
                    <Grid item>
                        <Box marginTop={1} />
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={6}
                            size="small"
                            id="message"
                            label="Message"
                            onChange={(e) => setMessage(e.target.value)}
                            defaultValue={message}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                if (window.Intercom) {
                                    window.Intercom('showNewMessage', message);
                                }
                            }}
                        >
                            Send
                        </Button>
                    </Grid>
                    <Grid item></Grid>
                </Grid>

                <Grid item container spacing={4}>
                    <Grid
                        item
                        container
                        xs={4}
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <PhoneIcon color="primary" />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">Call us</Typography>
                            <Typography
                                variant="caption"
                                style={{ color: '#A2A2A2' }}
                            >
                                Phone number : +1 (855) 870-0661
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        style={{ borderLeft: '1px #f2f2f2 solid' }}
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <MailIcon color="primary" />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">Write to us</Typography>
                            <Typography
                                variant="caption"
                                style={{ color: '#A2A2A2' }}
                            >
                                Email: support@postgrid.com
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        style={{ borderLeft: '1px #f2f2f2 solid' }}
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={1}>
                            <ChatIcon color="primary" />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="body1">Contact us</Typography>
                            <Typography
                                variant="caption"
                                style={{ color: '#A2A2A2' }}
                            >
                                Live chat
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Support;
