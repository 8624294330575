import { useMemo } from 'react';

import { ListResponse } from './util';
import { Service as BaseService, useService as useBaseService } from './Base';
import { Account } from './Organization';

// TODO Once we have Stripe.js installed, we can just export those interfaces
export interface Price {
    id: string;
    nickname: string;
    recurring: {
        interval: 'month' | 'year';
    };
    unit_amount: number;
    currency: string;
    metadata: {
        monthly_limit: string;
        pdn_type: 'standard' | 'premium' | 'priority';
    };
}

export interface Invoice {
    id: string;
    created: number;
    account_name: string;
    amount_paid: number;
    status: string;
    invoice_pdf: string;
    currency: string;
}

// TODO Make card optional once we actually allow other method types
export interface PaymentMethod {
    id: string;
    card: {
        exp_month: string;
        exp_year: string;
        last4: string;
    };
}

export interface Subscription {
    id: string;
    created: number;
    items: {
        data: {
            price: Price;
        }[];
    }[];
    // Equivalent to price id
    plan: {
        id: string;
    };
}

interface CreateOrUpdateSubscriptionParams {
    stripeSubscriptionPaymentMethod: string;
    stripePrice: string;
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async createPaymentMethod(data: {
        name: string;
        stripePaymentMethodId: string;
    }) {
        return await this.base.fetchAPI<Partial<Account>>(`/accounts`, {
            method: 'POST',
            body: data,
        });
    }

    async updatePaymentMethod(
        orgAccountId: string,
        stripePaymentMethodId: string
    ) {
        return await this.base.fetchAPI<PaymentMethod>(
            `/accounts/${orgAccountId}`,
            {
                method: 'POST',
                body: {
                    stripePaymentMethodId: stripePaymentMethodId,
                },
            }
        );
    }
}

export const useService = () => {
    const base = useBaseService();

    return useMemo(() => new Service(base), [base]);
};
