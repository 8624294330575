import React from 'react';
import { getCodes, getName } from 'country-list';
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const ALL_COUNTRY_CODES = getCodes();
const NA_COUNTRY_CODES = ['US', 'CA'];

interface CountrySelectorProps {
    countryCode: string;
    setCountryCode: (code: string) => void;
    showInternationalCountries?: boolean;
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& ::placeholder': {
            color: theme.palette.text.secondary,
            opacity: 100,
        },
    },
}));

const CountrySelector: React.FC<CountrySelectorProps> = ({
    countryCode,
    setCountryCode,
    showInternationalCountries,
}) => {
    const classes = useStyles();
    const handleChange = (_: React.ChangeEvent<{}>, value: string | null) => {
        setCountryCode(value || '');
    };

    return (
        <Autocomplete
            options={
                showInternationalCountries
                    ? ALL_COUNTRY_CODES
                    : NA_COUNTRY_CODES
            }
            autoHighlight
            getOptionLabel={(code) => getName(code) || ''}
            renderInput={(params) => {
                return (
                    <TextField
                        className={classes.root}
                        {...params}
                        variant="outlined"
                        placeholder="Select Country"
                    />
                );
            }}
            value={countryCode}
            onChange={handleChange}
        ></Autocomplete>
    );
};

export default CountrySelector;
