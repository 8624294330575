import React, { useState } from 'react';

import {
    Typography,
    Grid,
    makeStyles,
    Box,
    createMuiTheme,
    Theme,
    ThemeProvider,
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Plan, Interval, Organization } from '../services/Organization';

import Button from './Button';
import DialogBox from './DialogBox';

const useStyles = makeStyles({
    root: {
        height: '360px',
        zIndex: 2,
        position: 'relative',
        padding: '10px',
        overflow: 'hidden',
        borderRadius: '5px',
    },
    card: {
        height: '360px',
        zIndex: 2,
        position: 'relative',
        padding: '10px',
        overflow: 'hidden',
        borderRadius: '5px',
    },
    selected: {},
    cardRoot: {
        borderRadius: '15px',
        backgroundColor: '#fff',
        overflow: 'hidden',
        border: '1px #e8e8e8 solid',

        '&:hover': {
            backgroundColor: '#F9FBFF',
            border: '1px #2f71eb solid',
            borderRadius: '10px',
            overflow: 'hidden',
            padding: '0',
            margin: 0,
        },
    },
    activeCardRoot: {
        backgroundColor: '#F9FBFF',
        border: '1px #2f71eb solid',
        borderRadius: '10px',
        overflow: 'hidden',
        padding: '0',
        margin: 0,
    },
});

export const formatCents = (amount: number) =>
    (amount / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 3,
    });

const SubscribeCard = ({
    plan,
    active,
    onClick,
    isAlreadySubscribed,
    onPlanChange,
}: {
    plan: Partial<Omit<Plan, 'billingInterval'>> & {
        amount: number;
        billingInterval: Interval | 'address';
    };
    active?: boolean;
    onClick: () => void;
    isAlreadySubscribed?: boolean;
    onPlanChange?: () => void;
}) => {
    const classes = useStyles();

    const chargeText = plan.metadata?.additionalLookupCharge
        ? formatCents(plan.metadata.additionalLookupCharge)
        : plan.metadata?.additionalIntlLookupCharge
        ? formatCents(plan.metadata.additionalIntlLookupCharge)
        : undefined;
    const [showConfirmPlanChangeDialog, setShowConfirmPlanChangeDialog] =
        useState(false);
    return (
        <Box className={active ? classes.activeCardRoot : classes.cardRoot}>
            <Box padding={5} className={classes.root}>
                <Grid container className={classes.card}>
                    <ThemeProvider
                        theme={(theme: Theme) =>
                            createMuiTheme({
                                ...theme,
                                typography: {
                                    h5: {
                                        fontWeight: 400,
                                        fontSize: '20px',
                                    },
                                    h6: {
                                        fontWeight: 300,
                                        fontSize: '20px',
                                    },
                                    subtitle1: {
                                        fontWeight: 400,
                                        fontSize: '12px',
                                    },
                                    body1: {
                                        fontWeight: 400,
                                        fontSize: '13px',
                                    },
                                    body2: {
                                        fontSize: '11px',
                                    },
                                },

                                overrides: {
                                    MuiListItemIcon: {
                                        root: {
                                            minWidth: '24px',
                                        },
                                    },
                                    MuiButton: {
                                        outlinedPrimary: {},
                                    },
                                },
                            })
                        }
                    >
                        <Grid item container spacing={1} alignItems="center">
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            {plan.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item style={{ padding: 0 }}>
                                            <Typography
                                                variant="h4"
                                                display="inline"
                                            >
                                                {plan.billingInterval === 'year'
                                                    ? formatCents(
                                                          Math.floor(
                                                              plan.amount / 12
                                                          )
                                                      )
                                                    : formatCents(plan.amount)}
                                            </Typography>
                                            <Typography
                                                display="inline"
                                                variant="h6"
                                            >
                                                /
                                                {plan.billingInterval === 'year'
                                                    ? 'month'
                                                    : plan.billingInterval}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left">
                                            {plan.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                {isAlreadySubscribed ? (
                                    <Button
                                        fullWidth
                                        variant={
                                            active ? 'contained' : 'outlined'
                                        }
                                        disableRipple={active}
                                        color="primary"
                                        onClick={() => {
                                            if (!active && onPlanChange) {
                                                setShowConfirmPlanChangeDialog(
                                                    true
                                                );
                                            }
                                        }}
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        {active
                                            ? plan.billingInterval === 'address'
                                                ? 'Bulk Verify'
                                                : 'Currently Active'
                                            : 'Checkout'}
                                    </Button>
                                ) : (
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        onClick={onClick}
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        {plan.billingInterval === 'address'
                                            ? 'Bulk Verify'
                                            : 'Checkout'}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                        {(plan.limit || plan.intlLimit) && (
                            <Grid
                                item
                                container
                                alignItems="center"
                                alignContent="center"
                                spacing={1}
                            >
                                <Grid
                                    item
                                    sm={12}
                                    style={{ borderTop: '1px #EEEEEE solid' }}
                                ></Grid>
                                <Grid item>
                                    <Typography variant="body1">
                                        Includes
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    alignContent="center"
                                    item
                                >
                                    <Grid item>
                                        <CheckCircleIcon color="primary" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1">
                                            {(
                                                plan.limit! || plan.intlLimit!
                                            ).toLocaleString()}{' '}
                                            lookups per month
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {plan.metadata && (
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                        alignContent="center"
                                        item
                                    >
                                        <Grid item>
                                            <CheckCircleIcon color="primary" />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {chargeText} per additional
                                                lookup
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}

                                {plan.billingInterval === 'year' && (
                                    <Grid
                                        container
                                        spacing={1}
                                        alignItems="center"
                                        alignContent="center"
                                        item
                                    >
                                        <Grid item>
                                            <CheckCircleIcon color="primary" />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                Billed annually
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </ThemeProvider>
                </Grid>
                <DialogBox
                    open={showConfirmPlanChangeDialog}
                    actionLabel="Confirm"
                    onConfirm={() => {
                        if (onPlanChange) {
                            onPlanChange();
                            setShowConfirmPlanChangeDialog(false);
                        }
                    }}
                    onClose={() => setShowConfirmPlanChangeDialog(false)}
                >
                    <Typography variant="h6">
                        Are you sure you want to change your plan?
                    </Typography>
                </DialogBox>
            </Box>
        </Box>
    );
};

const EnterpriseCard = () => {
    const classes = useStyles();

    return (
        <Box className={classes.cardRoot}>
            <Box padding={5} className={classes.root}>
                <Grid container className={classes.card}>
                    <ThemeProvider
                        theme={(theme: Theme) =>
                            createMuiTheme({
                                ...theme,
                                typography: {
                                    h5: {
                                        fontWeight: 400,
                                        fontSize: '20px',
                                    },
                                    h6: {
                                        fontWeight: 300,
                                        fontSize: '20px',
                                    },
                                    subtitle1: {
                                        fontWeight: 400,
                                        fontSize: '12px',
                                    },
                                    body1: {
                                        fontWeight: 400,
                                        fontSize: '13px',
                                    },
                                    body2: {
                                        fontSize: '11px',
                                    },
                                },

                                overrides: {
                                    MuiListItemIcon: {
                                        root: {
                                            minWidth: '24px',
                                        },
                                    },
                                    MuiButton: {
                                        outlinedPrimary: {},
                                    },
                                },
                            })
                        }
                    >
                        <Grid item container spacing={1} alignItems="center">
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            Enterprise
                                        </Typography>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item style={{ padding: 0 }}>
                                            <Typography
                                                variant="h4"
                                                display="inline"
                                            >
                                                Custom
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left">
                                            For large-scale use cases, custom
                                            features, and dedicated support.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        window.open(
                                            'https://postgrid.com/contact-us',
                                            '_blank'
                                        );
                                    }}
                                    style={{
                                        fontSize: '12px',
                                    }}
                                >
                                    Contact Us
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            alignItems="center"
                            alignContent="center"
                            spacing={1}
                        >
                            <Grid
                                item
                                sm={12}
                                style={{ borderTop: '1px #EEEEEE solid' }}
                            ></Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    Includes
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                alignContent="center"
                                item
                            >
                                <Grid item>
                                    <CheckCircleIcon color="primary" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Pricing that fits your needs
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Box>
        </Box>
    );
};

// TODO Refactor
const PurchaseCreditsCard = (props: {
    onClick: () => void;
    org: Organization;
}) => {
    const { org } = props;

    const classes = useStyles();

    return (
        <>
            <Box className={classes.cardRoot}>
                <Box padding={5} className={classes.root}>
                    <Grid container className={classes.card}>
                        <ThemeProvider
                            theme={(theme: Theme) =>
                                createMuiTheme({
                                    ...theme,
                                    typography: {
                                        h5: {
                                            fontWeight: 400,
                                            fontSize: '18px',
                                            paddingTop: '7px',
                                        },
                                        h6: {
                                            fontWeight: 300,
                                            fontSize: '20px',
                                        },
                                        subtitle1: {
                                            fontWeight: 400,
                                            fontSize: '12px',
                                        },
                                        body1: {
                                            fontWeight: 400,
                                            fontSize: '13px',
                                        },
                                        body2: {
                                            fontSize: '11px',
                                        },
                                    },

                                    overrides: {
                                        MuiListItemIcon: {
                                            root: {
                                                minWidth: '24px',
                                            },
                                        },
                                        MuiButton: {
                                            outlinedPrimary: {},
                                        },
                                    },
                                })
                            }
                        >
                            <Grid
                                item
                                container
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Pre-Purchase Lookups
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <Grid item style={{ padding: 0 }}>
                                                <Typography
                                                    variant="h4"
                                                    display="inline"
                                                >
                                                    Custom
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align="left">
                                                Purchase bulk verification
                                                lookups. You have{' '}
                                                {Math.max(
                                                    0,
                                                    org.limit.bulkLookups -
                                                        org.usage.bulkLookups
                                                ).toLocaleString()}{' '}
                                                standard free lookups remaining
                                                and{' '}
                                                {Math.max(
                                                    0,
                                                    org.limit.intlBulkLookups -
                                                        org.usage
                                                            .intlBulkLookups
                                                ).toLocaleString()}{' '}
                                                international free lookups
                                                remaining.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="outlined"
                                        color="primary"
                                        onClick={props.onClick}
                                        style={{
                                            fontSize: '12px',
                                        }}
                                    >
                                        Purchase
                                    </Button>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

const ContactUsBulkCard = () => {
    const classes = useStyles();

    return (
        <Box className={classes.cardRoot}>
            <Box padding={5} className={classes.root}>
                <Grid container className={classes.card}>
                    <ThemeProvider
                        theme={(theme: Theme) =>
                            createMuiTheme({
                                ...theme,
                                typography: {
                                    h5: {
                                        fontWeight: 400,
                                        fontSize: '20px',
                                    },
                                    h6: {
                                        fontWeight: 300,
                                        fontSize: '20px',
                                    },
                                    subtitle1: {
                                        fontWeight: 400,
                                        fontSize: '12px',
                                    },
                                    body1: {
                                        fontWeight: 400,
                                        fontSize: '13px',
                                    },
                                    body2: {
                                        fontSize: '11px',
                                    },
                                },

                                overrides: {
                                    MuiListItemIcon: {
                                        root: {
                                            minWidth: '24px',
                                        },
                                    },
                                    MuiButton: {
                                        outlinedPrimary: {},
                                    },
                                },
                            })
                        }
                    >
                        <Grid item container spacing={1} alignItems="center">
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Typography variant="h5">
                                            Very Large Lists
                                        </Typography>
                                    </Grid>
                                    <Grid item container>
                                        <Grid item style={{ padding: 0 }}>
                                            <Typography
                                                variant="h4"
                                                display="inline"
                                            >
                                                Contact Us
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left">
                                            For more than 100,000 addresses
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        window.open(
                                            'https://postgrid.com/contact-us',
                                            '_blank'
                                        );
                                    }}
                                    style={{
                                        fontSize: '12px',
                                    }}
                                >
                                    Contact Us
                                </Button>
                            </Grid>
                        </Grid>
                    </ThemeProvider>
                </Grid>
            </Box>
        </Box>
    );
};

export {
    EnterpriseCard,
    ContactUsBulkCard,
    PurchaseCreditsCard,
    SubscribeCard as default,
};
