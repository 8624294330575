import React from 'react';
// Components
import { BulkVerifyList } from './BulkVerify';
import EmbedWrapper from '../components/EmbedWrapper';

const EmbeddedBulkVerify = () => {
    return (
        <EmbedWrapper>
            <BulkVerifyList createLink="/embed/bulk_verify/create" />
        </EmbedWrapper>
    );
};

export default EmbeddedBulkVerify;
