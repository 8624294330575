import React, { PropsWithChildren, useState, useEffect } from 'react';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';

import {
    createMuiTheme,
    Grid,
    TableFooter,
    Theme,
    ThemeProvider,
    Typography,
    Box,
} from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import {
    Invoice,
    useService as useInvoiceService,
} from '../services/Organization';

import TopNav from '../components/TopNav';
import TableBodyLoader from '../components/TableBodyLoader';
import GridPaper from '../components/GridPaper';
import ResetTablePagination from '../components/ResetTablePagination';

const useStyles = makeStyles({
    tableRow: {
        padding: '1.3rem',
        backgroundColor: 'white',
        boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
    },

    tableHeading: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        font: 'normal normal normal 18px/22px Roboto',
        letterSpacing: '0px',
        color: '#000000',
        opacity: 1,
    },

    tableCellRoot: {
        fontFamily: 'Roboto',
        fontSize: '14px',
        font: 'normal normal normal 16px/19px Roboto',
        color: '#000000',
        opacity: 1,
        borderBottom: '1px #f2f2f2 solid',
        textTransform: 'capitalize',
    },
});

const StyledTableCell = (props: PropsWithChildren<TableCellProps>) => {
    const classes = useStyles();

    return (
        <TableCell
            classes={{
                root: classes.tableCellRoot,
            }}
            {...props}
        />
    );
};

const InvoiceTable = () => {
    const headings = [
        'Invoice ID',
        'Date',
        'Customer Name',
        'Amount Due',
        'Amount Paid',
        'Status',
    ];

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [allInvoices, setAllInvoices] = useState<Invoice[]>([]);
    const [loading, setLoading] = useState(false);

    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const service = useInvoiceService();

    const classes = useStyles();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const res = await service.getInvoices();

                setAllInvoices(res);

                setInvoices(res.slice(0, rowsPerPage));

                setTotalCount(res.length);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        setInvoices(
            allInvoices.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        );
    }, [rowsPerPage, page, allInvoices]);

    return (
        <>
            <TopNav />
            <TableContainer>
                <Grid container>
                    <ThemeProvider
                        theme={(theme: Theme) =>
                            createMuiTheme({
                                ...theme,
                                typography: {
                                    ...theme.typography,
                                },
                            })
                        }
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headings.map((heading) => (
                                        <TableCell
                                            variant="head"
                                            className={classes.tableHeading}
                                            key={heading}
                                        >
                                            {heading}
                                        </TableCell>
                                    ))}
                                    <TableCell
                                        variant="head"
                                        className={classes.tableHeading}
                                        align="center"
                                    >
                                        Download
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBodyLoader show={loading} columns={6}>
                                {invoices.map((row: Invoice, i: number) => (
                                    <TableRow
                                        classes={{ root: classes.tableRow }}
                                        onClick={() => {}}
                                        key={row.id}
                                    >
                                        <StyledTableCell>
                                            {row.id}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {moment
                                                .unix(row.created)
                                                .toLocaleString()}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {row.customer_name}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {(
                                                row.amount_due / 100
                                            ).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {(
                                                row.amount_paid / 100
                                            ).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })}
                                        </StyledTableCell>

                                        <StyledTableCell>
                                            {row.status}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                disabled={
                                                    row.status === 'draft'
                                                }
                                                onClick={() =>
                                                    window.open(row.invoice_pdf)
                                                }
                                            >
                                                <GetAppIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBodyLoader>
                            <TableFooter>
                                <TableRow>
                                    <ResetTablePagination
                                        rowsPerPage={rowsPerPage}
                                        setRowsPerPage={setRowsPerPage}
                                        page={page}
                                        setPage={setPage}
                                        count={totalCount}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </ThemeProvider>
                </Grid>
            </TableContainer>
        </>
    );
};

const Invoices = () => {
    const classes = useStyles();

    return (
        <GridPaper direction="column">
            <Grid item>
                <Typography variant="h6">Invoices</Typography>
            </Grid>

            <Grid item>
                <Box mt={2} />
            </Grid>

            <Grid item>
                <Divider />
            </Grid>

            <Grid item>
                <Box mt={4} />
            </Grid>

            <Grid item xs={12}>
                <InvoiceTable />
            </Grid>
        </GridPaper>
    );
};

export default Invoices;
