import React from 'react';

import BulkVerifyStepsProvider, {
    StepTitles,
    useBulkVerifyStepsContext,
} from '../context/BulkVerifyStepsContext';

import { Typography, Grid, Divider, Box } from '@material-ui/core';
import Step1 from '../components/BulkStep1';
import Step2 from '../components/BulkStep2';
import Step3 from '../components/BulkStep3';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import TopNav from '../components/TopNav';

export const BulkVerifySteps = () => {
    const { activeStep } = useBulkVerifyStepsContext();

    return (
        <GridPaper direction="column" justify="center">
            <Grid item>
                <Typography variant="h5">Verify a List</Typography>
                <Typography variant="caption" color="textSecondary">
                    Follow the steps to verify your list
                </Typography>
            </Grid>

            <Grid item>
                <Box mt={2} mb={3}>
                    <Divider />
                </Box>
            </Grid>

            <Grid item container spacing={3} justify="center">
                {Object.values(StepTitles).map((title, i) => (
                    <Grid item xs={4} key={i}>
                        <Button
                            color={activeStep === title ? 'primary' : undefined}
                            disableRipple
                            disableTouchRipple
                            variant="outlined"
                            fullWidth
                            size="large"
                            style={{
                                justifyContent: 'flex-start',
                                cursor: 'default',
                            }}
                        >
                            {i + 1}. {title}
                        </Button>
                    </Grid>
                ))}
            </Grid>

            <Grid item>
                <Box mt={4} />
            </Grid>

            <Grid item>
                {activeStep === StepTitles.UPLOAD_FILE && <Step1 />}
                {activeStep === StepTitles.MAP_FIELDS && <Step2 />}
                {activeStep === StepTitles.PREVIEW_AND_IMPORT && <Step3 />}
            </Grid>
        </GridPaper>
    );
};

const BulkVerifyStepsPage = () => {
    return (
        <>
            <TopNav />
            <BulkVerifyStepsProvider>
                <BulkVerifySteps />
            </BulkVerifyStepsProvider>
        </>
    );
};

export default BulkVerifyStepsPage;
