import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Backdrop } from '@material-ui/core';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: '7px',
        minWidth: '120px',
        boxSizing: 'border-box',
        overflowX: 'hidden',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontVariant: 'subtitle2',
        width: '100%',
        boxSizing: 'border-box',
        fontSize: '0.745rem',
        fontWeight: 500,
        '&:focus': {
            backgroundColor: theme.palette.primary.light,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface popperContent {
    name?: string | JSX.Element;
    icon?: JSX.Element;
    handleClick?: any;
    subHeading?: string | JSX.Element;
}
const Popper = ({
    name,
    handleClose,
    anchorEl,
    content,
    noUpperTriangle = false,
}: {
    name?: string;
    handleClose: () => void;
    anchorEl: HTMLElement | null;
    content: popperContent[] | undefined;
    noUpperTriangle?: boolean;
}) => {
    return (
        <>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                // transition={true}
                style={{ zIndex: 21200, marginTop: '10px' }}
            >
                {!noUpperTriangle && (
                    <p
                        style={{
                            height: '10px',
                            width: '10px',
                            transform: 'rotate(45deg)',
                            position: 'absolute',
                            top: '-5px',
                            left: '47%',
                            overflow: 'visible',
                            backgroundColor: '#fff',
                        }}
                    />
                )}
                {content?.map((item, i) => (
                    <StyledMenuItem
                        disableRipple
                        key={i}
                        style={
                            content[i + 1]?.hasOwnProperty('subHeading') ||
                            i + 1 === content.length
                                ? undefined
                                : { borderBottom: '1px #ededed solid' }
                        }
                        onClick={() => {
                            item.handleClick();
                            handleClose();
                        }}
                    >
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        <ListItemText
                            primaryTypographyProps={{
                                variant: 'body2',
                            }}
                            primary={item.name}
                        />
                    </StyledMenuItem>
                ))}
            </StyledMenu>
            <Backdrop
                open={!!anchorEl}
                onClick={handleClose}
                style={{
                    zIndex: 10000,
                    width: '100%',
                    height: '100%',
                    opacity: 0.3,
                }}
            />
        </>
    );
};
export default Popper;
