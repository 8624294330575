import React, { useEffect, useState } from 'react';
import { LocalFile, parse, ParseStepResult } from 'papaparse';

import type { BulkListMapping } from '../services/BulkLists';
import {
    StepTitles,
    useBulkVerifyStepsContext,
} from '../context/BulkVerifyStepsContext';
import { MessageType, useNotifcationContext } from '../context/Notification';
import { NCOA_ADDRESS_FIELDS } from './BulkStep2';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';

// Extract the number of rows we want to display
const extractCSVRows = async (
    file: File
): Promise<Record<string, string>[]> => {
    const rows: Record<string, string>[] = [];
    const numRowsToRead = 5;
    let rowsEncountered = 0;

    return new Promise((resolve) => {
        parse(file as LocalFile, {
            skipEmptyLines: true,
            header: true,
            step: (row: ParseStepResult<Record<string, string>>, parser) => {
                if (rowsEncountered >= numRowsToRead) {
                    parser.abort();
                    return;
                }
                rows.push(row.data);
                ++rowsEncountered;
            },
            complete: () => {
                resolve(rows);
            },
        });
    });
};

interface DenseTableProps {
    mapping: Partial<BulkListMapping>;
    rows?: Partial<BulkListMapping>[];
}
const DenseTable = ({ mapping, rows }: DenseTableProps) => {
    if (!rows || !rows.length) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {Object.entries(NCOA_ADDRESS_FIELDS).map(
                            ([key, value]) => {
                                if (!mapping[value]) {
                                    return null;
                                }

                                return <TableCell key={value}>{key}</TableCell>;
                            }
                        )}
                    </TableRow>
                </TableHead>
                <DenseTableBody rows={rows} />
            </Table>
        </TableContainer>
    );
};

const DenseTableBody = ({
    rows,
}: Required<Omit<DenseTableProps, 'mapping'>>) => {
    return (
        <TableBody>
            {rows.map((row, i) => {
                return (
                    <TableRow key={i}>
                        {Object.entries(NCOA_ADDRESS_FIELDS).map(
                            ([key, value]) => {
                                if (row[value] === undefined) {
                                    return null;
                                }

                                return (
                                    <TableCell key={key}>
                                        {row[value]}
                                    </TableCell>
                                );
                            }
                        )}
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

const BulkRowSamples = () => {
    const { file, inputMapping, setActiveStep } = useBulkVerifyStepsContext();
    const { dispatch } = useNotifcationContext();
    const [rawRows, setRawRows] = useState<Record<string, string>[]>();
    const [rows, setRows] = useState<Partial<BulkListMapping>[]>();

    useEffect(() => {
        let isMounted = true;
        if (!file) {
            return;
        }

        const setRowsFromFile = async () => {
            const r = await extractCSVRows(file);

            if (!isMounted) {
                return;
            }

            setRawRows(r);
        };
        setRowsFromFile();
        return () => {
            isMounted = false;
        };
    }, [file, inputMapping]);

    // When the mapping changes, re-set the rows to the new values
    // from the mapping
    useEffect(() => {
        if (!rawRows || !rawRows.length) {
            return;
        }
        try {
            const newRows = [];

            for (const row of rawRows) {
                const data = {};

                for (const [key, val] of Object.entries(inputMapping)) {
                    if (val) {
                        Object.assign(data, { [key]: row[val].trim() });
                    }
                }

                newRows.push(data);
            }

            setRows(newRows);
        } catch (err) {
            console.error(err);

            dispatch({
                type: MessageType.ERROR,
                message:
                    'Malformed CSV file found. Please check the file and try again.',
            });
            setRows([]);
            setActiveStep(StepTitles.UPLOAD_FILE);
        }
    }, [rawRows, inputMapping]);

    const subtitleText = (() => {
        if (!rows || !rows.length) {
            return 'We were unable to find any rows in your file';
        }

        return `Here are the first ${rows.length} rows we found`;
    })();

    return (
        <Grid item container xs={8} direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h6" align="center">
                    Mapping Preview
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center">{subtitleText}</Typography>
            </Grid>
            <DenseTable mapping={inputMapping} rows={rows} />
        </Grid>
    );
};

export default BulkRowSamples;
