import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
    ChangeEvent,
    FormEvent,
} from 'react';

import { useHistory } from 'react-router';

import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import moment from 'moment';

import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    createStyles,
    Grid,
    makeStyles,
    Paper,
    SvgIconTypeMap,
    Switch,
    Tab,
    Tabs,
    Theme,
    Typography,
    TextField,
    withStyles,
    Checkbox,
} from '@material-ui/core';

import { OverridableComponent } from '@material-ui/core/OverridableComponent';

import RoomIcon from '@material-ui/icons/Room';
import LanguageIcon from '@material-ui/icons/Language';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import PaymentIcon from '@material-ui/icons/Payment';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import Alert from '@material-ui/lab/Alert';

import {
    Subscription,
    Plan,
    Account,
    Interval,
    useService as useOrganizationService,
    useOrganization,
    Organization,
} from '../services/Organization';

import { useService as useBulkListsService } from '../services/BulkLists';

import { useService as useBillingService } from '../services/Billing';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import { Context as AuthContext } from '../context/Auth';

import { intercom } from './Support';

import Chip from '../components/Chip';
import Button from '../components/Button';
import Container from '../components/Container';

import SubscribeCard, {
    EnterpriseCard,
    ContactUsBulkCard,
    PurchaseCreditsCard,
    formatCents,
} from '../components/SubscribeCard';

import TopNav from '../components/TopNav';

import DialogBox from '../components/DialogBox';
import { CreatePaymentCard } from '../components/BulkStep3';

const useStyles = makeStyles({
    root: {
        padding: '1.3rem',
        backgroundColor: 'white',
        boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
        borderRadius: '5px',
    },
    tabRoot: {
        flexGrow: 1,
    },

    switchLabelOn: {
        color: '#2f71eb',
    },
    switchLabelOff: {
        color: '#B9B9B9',
    },
});

const COUNTRY = 'us';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

const TabLabel = (props: {
    name: string;
    description: string;
    icon: JSX.Element | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}) => (
    <Grid container alignItems="center">
        <Grid item xs={2}>
            <Avatar>{props.icon}</Avatar>
        </Grid>
        <Grid container item xs={10}>
            <Grid item>
                <Typography style={{ textTransform: 'none' }} variant="body1">
                    {props.name}
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    style={{
                        textTransform: 'none',
                        textAlign: 'start',
                        fontSize: '12px',
                        color: '#848484',
                    }}
                    variant="body1"
                >
                    {props.description}
                </Typography>
            </Grid>
        </Grid>
    </Grid>
);

const TabPanel = (
    props: PropsWithChildren<{
        value: number;
        index: number;
    }>
) => {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export const AntSwitch = withStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 48,
            height: 26,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(22px)',
                color: theme.palette.common.white,
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 22,
            height: 22,
            boxShadow: 'none',
            color: theme.palette.common.white,
        },
        track: {
            border: `1px solid ${theme.palette.primary}`,
            color: theme.palette.common.white,
            borderRadius: 100 / 2,
            opacity: 1,
            backgroundColor: theme.palette.grey[500],
        },
        checked: {},
    })
)(Switch);

const IntervalSwitch = ({
    interval,
    setInterval,
}: {
    interval: Interval;
    setInterval: (v: Interval) => void;
}) => {
    const classes = useStyles();

    return (
        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid
                    item
                    className={
                        interval === 'month'
                            ? classes.switchLabelOn
                            : classes.switchLabelOff
                    }
                >
                    Monthly
                </Grid>
                <Grid item>
                    <AntSwitch
                        checked={interval === 'year'}
                        onChange={() =>
                            setInterval(interval === 'month' ? 'year' : 'month')
                        }
                        name="checkedC"
                    />
                </Grid>
                <Grid
                    item
                    className={
                        interval === 'year'
                            ? classes.switchLabelOn
                            : classes.switchLabelOff
                    }
                >
                    Yearly (10% off)
                </Grid>
            </Grid>
        </Typography>
    );
};

const PurchaseCreditsDialog = ({
    org,
    open,
    setOpen,
}: {
    org: Organization;
    open: boolean;
    setOpen: (v: boolean) => void;
}) => {
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const [purchaseCount, setPurchaseCount] = useState(1);
    const [purchaseAmount, setPurchaseAmount] = useState<number>();

    const [loading, setLoading] = useState(false);

    const [international, setInternational] = useState(false);

    const service = useBulkListsService();

    useEffect(() => {
        setPurchaseAmount(undefined);

        if (isNaN(purchaseCount) || purchaseCount <= 0) {
            return;
        }

        const to = setTimeout(async () => {
            try {
                const unitCost = await service.getCreditUnitCost(
                    purchaseCount,
                    international
                );

                setPurchaseAmount(unitCost * purchaseCount);
            } catch (err) {
                console.error(err);
            }
        }, 200);

        return () => {
            clearTimeout(to);
        };
    }, [purchaseCount, international]);

    return (
        <DialogBox
            open={open}
            actionLabel="Purchase"
            onConfirm={async () => {
                try {
                    setLoading(true);

                    const res = await service.addCredits(
                        purchaseCount,
                        international
                    );

                    notificationDispatch({
                        type: MessageType.SUCCESS,
                        message: `Successfully added ${purchaseCount} bulk lookups.`,
                    });

                    setOpen(false);
                } catch (err) {
                    console.error(err);
                }

                setLoading(false);
            }}
            onClose={() => {
                setOpen(false);
                setLoading(false);
            }}
            disabled={loading || !purchaseAmount}
        >
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Typography variant="h6">
                        How many credits do you want to purchase?
                    </Typography>
                </Grid>

                <Grid
                    item
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                    container
                    wrap="wrap"
                    spacing={0}
                    xs={12}
                >
                    <Grid item>
                        <Checkbox
                            name="International Select"
                            color="primary"
                            onChange={() => {
                                setInternational!(international ? false : true);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>Purchase International Credits</Typography>
                    </Grid>
                </Grid>

                <Grid item>
                    <TextField
                        variant="outlined"
                        type="number"
                        value={purchaseCount}
                        inputProps={{ min: 1 }}
                        onChange={(e) =>
                            setPurchaseCount(parseInt(e.target.value))
                        }
                    />
                </Grid>

                {purchaseAmount && (
                    <Grid item>
                        <Typography variant="body2">
                            Cost: {purchaseCount} x{' '}
                            {formatCents(purchaseAmount / purchaseCount)} ={' '}
                            {formatCents(purchaseAmount)}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </DialogBox>
    );
};

const PlanSelector = ({
    allStandardPlans,
    allIntlPlans,
    handleCreateSubscription,
    handlePlanChange,
    currentlyActivePlan,
    account,
    setAccount,
    setShowCreateAccountDialog,
    showCreateAccountDialog,
    purchaseDialogOpen,
    setPurchaseDialogOpen,
    org,
}: {
    allStandardPlans: Plan[];
    allIntlPlans: Plan[];
    currentlyActivePlan: Subscription | null | undefined;
    handleCreateSubscription: (id: string) => void;
    handlePlanChange: (id: string) => void;
    setShowCreateAccountDialog: (arg1: boolean) => void;
    account: Account | undefined;
    setAccount: (a: Account) => void;
    showCreateAccountDialog: boolean;
    purchaseDialogOpen: boolean;
    setPurchaseDialogOpen: (v: boolean) => void;
    org: Organization;
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [tabIndex, setTabIndex] = useState(0);
    const [interval, setInterval] = useState<Interval>('month');

    const tabLabel = [
        {
            icon: (i: number) => (
                <VerifiedUserIcon
                    color={tabIndex === i ? 'primary' : undefined}
                />
            ),
            name: 'Bulk Verification',
            desc: 'For verifying and cleaning up a CSV file of addresses through our dashboard',
        },
        {
            icon: (i: number) => (
                <RoomIcon color={tabIndex === i ? 'primary' : undefined} />
            ),
            name: 'Standard API',
            desc: 'For verifying and autocompleting addresses across US & Canada',
        },
        {
            icon: (i: number) => (
                <LanguageIcon color={tabIndex === i ? 'primary' : undefined} />
            ),
            name: 'International API',
            desc: 'For verifying and autocompleting addresses worldwide with additional features',
        },
    ];

    const standardPlans = allStandardPlans
        .filter(
            (p) =>
                p.billingInterval === interval &&
                (!p.enterprise || p.organization === org.id)
        )
        .sort((a, b) => a.amount - b.amount);

    const intlPlans = allIntlPlans
        .filter(
            (p) =>
                p.billingInterval === interval &&
                (!p.enterprise || p.organization === org.id)
        )
        .sort((a, b) => a.amount - b.amount);

    const bulkPlans: {
        name: string;
        amount: number;
        billingInterval: 'address';
        description: string;
    }[] = [
        {
            name: 'Small Lists',
            amount: 5,
            billingInterval: 'address',
            description:
                'For up to 10,000 addresses.\n International rate is $0.06/address.',
        },
        {
            name: 'Large Lists',
            amount: 3,
            billingInterval: 'address',
            description:
                'For 10,000 to 100,000 addresses.\n International rate is $0.06/address.',
        },
    ];

    return (
        <>
            <PurchaseCreditsDialog
                org={org}
                open={purchaseDialogOpen}
                setOpen={setPurchaseDialogOpen}
            />
            <Paper className={classes.tabRoot}>
                <Tabs
                    value={tabIndex}
                    onChange={(e: ChangeEvent<{}>, v: number) => setTabIndex(v)}
                    variant="fullWidth"
                    TabIndicatorProps={{ color: 'tranparent' }}
                    textColor="primary"
                    aria-label="icon label tabs example"
                >
                    {tabLabel.map((tab, i) => (
                        <Tab
                            style={{ padding: '10px' }}
                            label={
                                <TabLabel
                                    icon={tab.icon(i)}
                                    name={tab.name}
                                    description={tab.desc}
                                />
                            }
                        />
                    ))}
                </Tabs>

                <TabPanel value={tabIndex} index={0}>
                    <Grid container justify="center" spacing={4}>
                        {bulkPlans.map(
                            (plan: typeof bulkPlans[0], i: number) => (
                                <Grid item sm={3}>
                                    <SubscribeCard
                                        active={false}
                                        plan={plan}
                                        onClick={() => {
                                            history.push(
                                                '/dashboard/bulk_verify'
                                            );
                                        }}
                                    />
                                </Grid>
                            )
                        )}

                        <Grid item sm={3}>
                            <ContactUsBulkCard />
                        </Grid>

                        <Grid item sm={3}>
                            <PurchaseCreditsCard
                                org={org}
                                onClick={() => {
                                    if (org.accounts.length === 0) {
                                        setShowCreateAccountDialog(true);
                                    } else {
                                        setPurchaseDialogOpen(true);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={tabIndex} index={1}>
                    <Grid container spacing={4}>
                        <Grid item container justify="center">
                            <IntervalSwitch
                                interval={interval}
                                setInterval={setInterval}
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            justify="center"
                            style={{ padding: '0 16px' }}
                        >
                            <Alert variant="outlined" color="info">
                                These plans are for developers looking to
                                integrate our autocomplete or verification APIs.
                            </Alert>
                        </Grid>

                        <Grid item container justify="center" spacing={4}>
                            {standardPlans.map((plan: Plan, i: number) => (
                                <Grid item sm={3}>
                                    <SubscribeCard
                                        isAlreadySubscribed={
                                            !!currentlyActivePlan
                                        }
                                        active={
                                            currentlyActivePlan?.plan?.id ===
                                            plan.stripePriceId
                                        }
                                        onPlanChange={() =>
                                            currentlyActivePlan?.status
                                                ? handlePlanChange(
                                                      plan.stripePriceId
                                                  )
                                                : null
                                        }
                                        plan={plan}
                                        onClick={() => {
                                            handleCreateSubscription(
                                                plan.stripePriceId
                                            );
                                        }}
                                    />
                                </Grid>
                            ))}
                            <Grid item sm={3}>
                                <EnterpriseCard />
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={tabIndex} index={2}>
                    <Grid container spacing={4}>
                        <Grid item container justify="center">
                            <IntervalSwitch
                                interval={interval}
                                setInterval={setInterval}
                            />
                        </Grid>

                        <Grid
                            item
                            container
                            justify="center"
                            style={{ padding: '0 16px' }}
                        >
                            <Alert variant="outlined" color="info">
                                These plans are for developers looking to
                                integrate our autocomplete or verification APIs.
                            </Alert>
                        </Grid>

                        <Grid item container justify="center" spacing={4}>
                            {intlPlans.map((plan: Plan, i: number) => (
                                <Grid item sm={3}>
                                    <SubscribeCard
                                        isAlreadySubscribed={
                                            !!currentlyActivePlan
                                        }
                                        active={
                                            currentlyActivePlan?.plan?.id ===
                                            plan.stripePriceId
                                        }
                                        onPlanChange={() =>
                                            currentlyActivePlan?.status
                                                ? handlePlanChange(
                                                      plan.stripePriceId
                                                  )
                                                : null
                                        }
                                        plan={plan}
                                        onClick={() => {
                                            handleCreateSubscription(
                                                plan.stripePriceId
                                            );
                                        }}
                                    />
                                </Grid>
                            ))}

                            <Grid item sm={3}>
                                <EnterpriseCard />
                            </Grid>
                        </Grid>
                    </Grid>
                </TabPanel>

                <Elements stripe={stripePromise}>
                    <CreatePaymentCard
                        setAccount={setAccount}
                        showCreateAccountDialog={showCreateAccountDialog}
                        setShowCreateAccountDialog={setShowCreateAccountDialog}
                    />
                </Elements>
            </Paper>
        </>
    );
};

export const PaymentMethodContainer = ({
    account,
    currentlyActivePlan,
    setShowUpdateDialogBox,
}: {
    account: Account;
    currentlyActivePlan?: Subscription | null;
    setShowUpdateDialogBox: (v: boolean) => void;
}) => {
    return (
        <Container heading="Payment Method">
            <Grid container spacing={0}>
                <Grid container xs={9} item spacing={0}>
                    <Grid
                        item
                        justify="space-between"
                        alignItems="center"
                        container
                        xs={10}
                    >
                        <Grid item xs={1}>
                            <PaymentIcon color="primary" fontSize="large" />
                        </Grid>
                        <Grid item xs={2}>
                            <Chip
                                size="small"
                                label={`${account.card.expMonth}/${account.card.expYear}`}
                                style={{
                                    backgroundColor: '#D5DBE5',
                                }}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            {currentlyActivePlan && (
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Last Payment made on:{' '}
                                    <b>
                                        {' '}
                                        {moment
                                            .unix(currentlyActivePlan?.created)
                                            .toLocaleString()}
                                    </b>
                                </Typography>
                            )}
                            <Typography
                                display="block"
                                variant="caption"
                                color="textSecondary"
                            >
                                from <b>XXXX XXXX XXXX {account.card.last4}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item></Grid>
                    <Grid item xs={2}></Grid>{' '}
                    <Grid item xs={12}>
                        <Button
                            color="primary"
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                                setShowUpdateDialogBox(true);
                            }}
                        >
                            Update Card
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={3} style={{ padding: 0 }}>
                    <Grid container style={{ padding: 0 }} justify="flex-end">
                        <Grid
                            item
                            style={{
                                padding: 0,
                            }}
                        >
                            <AccountBalanceRoundedIcon
                                style={{
                                    opacity: '0.2',
                                    fontSize: '110px',
                                }}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

const PaymentMethodUpdateDialogBoxInner = (props: {
    open: boolean;
    onClose: () => void;
    org: Organization;
}) => {
    const { open, onClose } = props;
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const billingService = useBillingService();

    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const handleSubmitUpgradeCardReq = async (
        e?: FormEvent<HTMLFormElement>
    ) => {
        if (e) {
            e.preventDefault();
        }

        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement) as StripeCardElement,
        });

        if (error) {
            notificationDispatch({
                type: MessageType.ERROR,
                message: error.message as string,
            });

            setLoading(false);
            return;
        }

        if (!paymentMethod) {
            setLoading(false);
            return;
        }

        try {
            await billingService.updatePaymentMethod(
                props.org.accounts[0]?.id,
                paymentMethod.id
            );

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Payment method Updated Successfully.',
            });

            onClose();
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <DialogBox
            open={open}
            onClose={onClose}
            title="Update Card"
            actionLabel="Update"
            onConfirm={() => handleSubmitUpgradeCardReq()}
            disabled={loading}
        >
            <Grid container direction="column" spacing={1}>
                <Box margin={3}>
                    <Typography variant="body1">Enter Card Details</Typography>
                </Box>
                <Grid item xs={12} style={{ width: '300px' }}>
                    <Box margin={3}>
                        <CardElement />
                    </Box>
                </Grid>
            </Grid>
        </DialogBox>
    );
};

export const PaymentMethodUpdateDialogBox = (props: {
    org: Organization;
    open: boolean;
    onClose: () => void;
}) => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentMethodUpdateDialogBoxInner {...props} />
        </Elements>
    );
};

const Upgrade = () => {
    const service = useOrganizationService();
    const billingService = useBillingService();

    const classes = useStyles();

    const { dispatch: notificationDispatch } = useContext(NotificationContext);
    const { state: authState } = useContext(AuthContext);

    const [plans, setPlans] = useState<Plan[]>([]);
    const [showUpdateDialogBox, setShowUpdateDialogBox] = useState(false);
    const [showCreateAccountDialog, setShowCreateAccountDialog] =
        useState(false);
    const [purchaseDialogOpen, setPurchaseDialogOpen] = useState(false);

    const [currentlyActivePlan, setCurrentlyActivePlan] =
        useState<Subscription | null>(null);

    const [account, setAccount] = useState<Account>();

    const org = useOrganization([currentlyActivePlan, purchaseDialogOpen]);

    const [loading, setLoading] = useState(false);

    const getAccount = async () => {
        try {
            setLoading(true);

            setAccount(await service.getAccount());
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    const getSubPrices = async () => {
        try {
            setLoading(true);

            const res: Plan[] = await service.getPlans();

            await setPlans(res);
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    const getCurrentPlan = async () => {
        try {
            setLoading(true);

            const res = await service.getSubscription();

            if (res) {
                setCurrentlyActivePlan(res);
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    const handlePlanChange = async (planId: string) => {
        try {
            setLoading(true);

            await service.changePlan(planId);
            getCurrentPlan();

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Plan changed successfully',
            });
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    const handleCreateSubscription = async (planId: string) => {
        try {
            setLoading(true);

            setAccount(await service.getAccount());

            const res = await service.subscribeToPlan(planId);

            const stripe = await stripePromise;
            stripe?.redirectToCheckout({
                sessionId: res.id,
            });
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    useEffect(() => {
        getSubPrices();
        getCurrentPlan();
        getAccount();
    }, [showUpdateDialogBox]);

    useEffect(() => {
        let params = new URLSearchParams(document.location.search.substring(1));

        params.get('response') === 'success' &&
            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Successfully subscribed to plan',
            });

        if (params.get('response') === 'failure') {
            intercom();
            notificationDispatch({
                type: MessageType.ERROR,
                message:
                    'Error while subscribing to plan, please retry or Contact Us.',
            });
        }
        return () => {
            if (window.Intercom) {
                window.Intercom('hide');
            }
        };
    }, []);

    const standardPlans = plans?.filter(
        (plan) => !plan.metadata.additionalIntlLookupCharge
    );
    const intlPlans = plans?.filter(
        (plan) => !!plan.metadata.additionalIntlLookupCharge
    );

    return (
        <>
            <TopNav />
            <Backdrop open={loading} style={{ zIndex: 20000 }}>
                <CircularProgress />
            </Backdrop>
            <Grid container className={classes.root}>
                <Grid
                    item
                    xl={12}
                    xs={12}
                    style={{ borderBottom: '1px #f2f2f2 solid' }}
                >
                    <Typography variant="h6">Pricing</Typography>
                    <Box marginBottom={3} />
                </Grid>
                <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid container spacing={3}>
                        {/* meta info about active plan */}
                        {/* HACK So customers can easily change their payment method */}
                        {org && !currentlyActivePlan && account && (
                            <Grid
                                item
                                xs
                                container
                                spacing={2}
                                justify="space-between"
                            >
                                <Grid item xs={6}>
                                    <PaymentMethodContainer
                                        account={account}
                                        currentlyActivePlan={
                                            currentlyActivePlan
                                        }
                                        setShowUpdateDialogBox={
                                            setShowUpdateDialogBox
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {org && currentlyActivePlan && account && (
                            <Grid
                                item
                                xs
                                container
                                spacing={2}
                                justify="space-between"
                            >
                                <Grid item xs={6}>
                                    <Container heading="Current Plan">
                                        <Grid container spacing={1}>
                                            <Grid item container xs={8}>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems="center"
                                                    justify="space-between"
                                                >
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            Status
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {currentlyActivePlan.status ===
                                                        'active' ? (
                                                            <Chip
                                                                size="small"
                                                                label="Active"
                                                                color="primary"
                                                            />
                                                        ) : (
                                                            <Chip
                                                                size="small"
                                                                label="Inactive"
                                                                color="secondary"
                                                            />
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    container
                                                    justify="center"
                                                    direction="column"
                                                >
                                                    <Grid item>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Plan Type:{' '}
                                                            <b>
                                                                {' '}
                                                                {
                                                                    currentlyActivePlan
                                                                        .plan
                                                                        ?.nickname
                                                                }
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            Standard Lookup
                                                            Limit:{' '}
                                                            <b>
                                                                {org.limit.lookups.toLocaleString()}
                                                            </b>
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item>
                                                        <Typography
                                                            variant="body2"
                                                            color="textSecondary"
                                                        >
                                                            International Lookup
                                                            Limit:{' '}
                                                            <b>
                                                                {' '}
                                                                {org.limit.intlLookups.toLocaleString()}
                                                            </b>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                style={{ padding: 0 }}
                                            >
                                                <Grid
                                                    container
                                                    style={{ padding: 0 }}
                                                    justify="flex-end"
                                                >
                                                    <Grid
                                                        item
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <CheckCircleOutlineSharpIcon
                                                            style={{
                                                                opacity: '0.2',
                                                                fontSize:
                                                                    '110px',
                                                            }}
                                                            color="primary"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>

                                <Grid item xs={6}>
                                    <PaymentMethodContainer
                                        account={account}
                                        currentlyActivePlan={
                                            currentlyActivePlan
                                        }
                                        setShowUpdateDialogBox={
                                            setShowUpdateDialogBox
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}

                        {org?.accounts && (
                            <PaymentMethodUpdateDialogBox
                                open={showUpdateDialogBox}
                                onClose={() => setShowUpdateDialogBox(false)}
                                org={org}
                            />
                        )}
                        {org && (
                            <Grid item xs={12}>
                                <PlanSelector
                                    allStandardPlans={standardPlans}
                                    allIntlPlans={intlPlans}
                                    handleCreateSubscription={
                                        handleCreateSubscription
                                    }
                                    setAccount={setAccount}
                                    account={account}
                                    currentlyActivePlan={currentlyActivePlan}
                                    handlePlanChange={handlePlanChange}
                                    showCreateAccountDialog={
                                        showCreateAccountDialog
                                    }
                                    setShowCreateAccountDialog={
                                        setShowCreateAccountDialog
                                    }
                                    purchaseDialogOpen={purchaseDialogOpen}
                                    setPurchaseDialogOpen={
                                        setPurchaseDialogOpen
                                    }
                                    org={org}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Upgrade;
