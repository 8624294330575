import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Sidenav from '../components/Sidenav';

import Overview from './Overview';
import Developers from './Developers';
import Logout from './Logout';
import Profile from './Profile';
import BulkVerify from './BulkVerify';
import BulkVerifySteps from './BulkVerifySteps';
import Support from './Support';
import Invoices from './Invoices';
import Upgrade from './Upgrade';
import Users from './Users';

const useGridWrapperStyle = makeStyles((theme) => ({
    root: {
        margin: '90px auto 0',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
    },
}));

const Dashboard = () => {
    const match = useRouteMatch();

    const gridWrapper = useGridWrapperStyle();

    return (
        <>
            <Sidenav />
            <Box display="flex">
                <Box flexShrink={0} width="255px" />
                <Box flexGrow={1}>
                    <Container classes={{ root: gridWrapper.root }}>
                        <Box m={4}>
                            <Switch>
                                <Route
                                    exact
                                    path={`${match.path}`}
                                    component={Overview}
                                />
                                <Route
                                    exact
                                    path={`${match.path}/developers`}
                                    component={Developers}
                                />
                                <Route
                                    path={`${match.path}/profile`}
                                    component={Profile}
                                />
                                <Route
                                    path={`${match.path}/bulk_verify/create`}
                                    component={BulkVerifySteps}
                                />
                                <Route
                                    path={`${match.path}/bulk_verify`}
                                    component={BulkVerify}
                                />
                                <Route
                                    path={`${match.path}/support`}
                                    component={Support}
                                />
                                <Route
                                    path={`${match.path}/invoices`}
                                    component={Invoices}
                                />
                                <Route
                                    path={`${match.path}/upgrade`}
                                    component={Upgrade}
                                />
                                <Route
                                    path={`${match.path}/users`}
                                    component={Users}
                                />
                                <Route path={`/logout`} component={Logout} />
                            </Switch>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default Dashboard;
