import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow, { TableRowProps } from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Checkbox from '@material-ui/core/Checkbox';

import { CircularProgress } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import {
    APIKey,
    UpdateParams as APIKeyUpdateParams,
    useService as useAPIKeysService,
} from '../services/APIKeys';
import { validateOrigin } from '../services/util';

import TopNav from '../components/TopNav';
import Button from '../components/Button';
import GridPaper from '../components/GridPaper';
import EmbedCodePublic from '../components/EmbedCodePublic';
import EmbedCodeServer from '../components/EmbedCodeServer';
import Popper from '../components/Popper';
import DialogBox from '../components/DialogBox';
import ConfirmActionDialog from '../components/ConfirmActionDialog';

import apiKeyDeleteImage from '../img/APIKeyDelete.png';
import TableBodyLoader from '../components/TableBodyLoader';
import APIKeyField from '../components/APIKey';

enum APIKeyType {
    PUBLIC = 'public',
    SERVER = 'server',
}

const useStyles = makeStyles(() => ({
    tableRoot: {
        border: 'none',
    },
    tableCellHead: {
        fontWeight: 'inherit',
        fontSize: '1rem',
    },
    tableCellRoot: {
        borderBottom: '1px solid #ebebeb',
        padding: '12px',
    },
    tableRowRoot: {
        border: '0px',
    },
    listHeading: {
        paddingBottom: '10px',
    },
    'MuiListItem-dense': {
        paddingTop: '0px',
        paddingBottom: '0px',
    },
    toolTipIcon: {
        'vertical-align': 'text-bottom',
    },
}));

const OriginList = (props: {
    origins: string[];
    setOrigins: (v: string[]) => void;
}) => {
    const { origins, setOrigins } = props;

    // Initialize this state to the origins provided
    const [localEntries, setLocalEntries] = useState<string[]>(origins);

    const originOrErrors = useMemo(
        () => localEntries.map(validateOrigin),
        [localEntries]
    );

    useEffect(() => {
        // Do not set orgins on parent component unless
        // all origins are valid.
        const areValidOrigins = (arr: (string | Error)[]): arr is string[] =>
            arr.every((o) => typeof o === 'string');

        if (areValidOrigins(originOrErrors)) {
            setOrigins(originOrErrors);
        }
    }, [localEntries, setOrigins]);

    return (
        <Grid container direction="column" spacing={1}>
            {localEntries.map((o, i) => (
                <Grid item key={i}>
                    <TextField
                        error={originOrErrors[i] instanceof Error}
                        type="text"
                        size="small"
                        fullWidth
                        variant="outlined"
                        required
                        helperText={(() => {
                            // Help typescript out with the control flow analysis because it likely
                            // just sees 'i' as a dynamic value and so it can't assign a type to
                            // originOrErrors[i] from control flow.
                            const origin = originOrErrors[i];

                            return origin instanceof Error
                                ? origin.message
                                : '';
                        })()}
                        value={o}
                        onChange={(e) => {
                            const newOrigins = localEntries.slice();

                            newOrigins[i] = e.target.value;

                            setLocalEntries(newOrigins);
                        }}
                        InputProps={{
                            endAdornment: i === localEntries.length - 1 && (
                                <>
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() =>
                                                setLocalEntries([
                                                    ...localEntries,
                                                    '',
                                                ])
                                            }
                                            size="small"
                                        >
                                            <AddIcon
                                                color="primary"
                                                fontSize="small"
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                    {localEntries.length > 1 && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    const newOrigins =
                                                        localEntries.slice();
                                                    newOrigins.pop();

                                                    setLocalEntries(newOrigins);
                                                }}
                                                size="small"
                                            >
                                                <RemoveIcon fontSize="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    )}
                                </>
                            ),
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const StyledTableCell = (props: PropsWithChildren<TableCellProps>) => {
    const classes = useStyles();

    return (
        <TableCell
            classes={{
                root: classes.tableCellRoot,
                head: classes.tableCellHead,
            }}
            {...props}
        />
    );
};

const StyledTableRow = (props: PropsWithChildren<TableRowProps>) => {
    const classes = useStyles();

    return <TableRow classes={{ root: classes.tableRowRoot }} {...props} />;
};

const KeyDialog = (props: {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;

    title: string;
    actionLabel: string;

    name: string;
    setName: (v: string) => void;

    keyType: APIKeyType;
    setKeyType?: (v: APIKeyType) => void;

    liveKey: boolean;
    setLiveKey?: (v: boolean) => void;

    origins: string[];
    setOrigins: (v: string[]) => void;

    isCreateDialogBox: boolean;

    loading: boolean;
}) => {
    const {
        open,
        onClose,
        onConfirm,
        title,
        actionLabel,
        name,
        setName,
        keyType,
        setKeyType,
        liveKey,
        setLiveKey,
        origins,
        setOrigins,
        isCreateDialogBox,
        loading,
    } = props;

    const [localName, setLocalName] = useState('');

    useEffect(() => {
        setLocalName(name);
    }, [open, name]);

    return (
        <DialogBox
            open={open}
            onClose={onClose}
            title={title}
            actionLabel={actionLabel}
            onConfirm={onConfirm}
            disabled={loading}
        >
            <Grid container direction="column" spacing={2}>
                <Grid container item spacing={1}>
                    <Grid item xs={12}>
                        <Typography>Name</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            fullWidth
                            required
                            variant="outlined"
                            value={localName}
                            onChange={(e) => {
                                setLocalName(e.target.value);
                            }}
                            onBlur={(e) => setName(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container item spacing={1}>
                    <Grid item>
                        <Typography>Select the API Key Type</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            style={{
                                fontWeight: 400,
                            }}
                            variant="outlined"
                            onClick={() => setKeyType!(APIKeyType.PUBLIC)}
                            disabled={!setKeyType}
                            color={
                                keyType === APIKeyType.PUBLIC
                                    ? 'primary'
                                    : undefined
                            }
                        >
                            Public (for use on your website)
                            {keyType === APIKeyType.PUBLIC && (
                                <CheckCircleIcon
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                    }}
                                />
                            )}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            fullWidth
                            style={{
                                fontWeight: 400,
                            }}
                            variant="outlined"
                            disabled={!setKeyType}
                            color={
                                keyType === APIKeyType.SERVER
                                    ? 'primary'
                                    : undefined
                            }
                            onClick={() => setKeyType!(APIKeyType.SERVER)}
                            endIcon={
                                keyType === APIKeyType.SERVER && (
                                    <CheckCircleIcon />
                                )
                            }
                        >
                            Server (for use offline or server-side)
                        </Button>
                    </Grid>
                </Grid>

                {keyType === APIKeyType.PUBLIC && (
                    <Grid container item spacing={1}>
                        <Grid item>
                            <Typography>Origins</Typography>
                        </Grid>

                        <Grid item>
                            <Typography variant="caption">
                                Domain URLs which will have access to this API
                                Key. You will be able to make requests using
                                this key from the given origins only.
                                <br />
                                <br />
                                You may use wildcards in the URL e.g.{' '}
                                <code>*.example.*.com</code>. You can also
                                include the protocol before the wildcard like{' '}
                                <code>{'https://*.example.com'}</code>.
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <OriginList
                                origins={origins}
                                setOrigins={setOrigins}
                            />
                        </Grid>
                    </Grid>
                )}

                {isCreateDialogBox && (
                    <Grid container item spacing={1}>
                        <Grid item>
                            <Typography>Choose Key Mode</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                If you make API requests using a test key, it
                                will return dummy data and no lookups will be
                                used, but it will simulate a real request.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <label>Test Key</label>
                            <Checkbox
                                name="Test Key"
                                color="primary"
                                onChange={() => {
                                    setLiveKey!(liveKey ? false : true);
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </DialogBox>
    );
};

const DeleteDialog = (props: {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}) => {
    const { open, onClose, onConfirm } = props;

    return (
        <ConfirmActionDialog
            open={open}
            onClose={onClose}
            title="Delete API Key"
            text="Are you sure you want to delete this API Key? It will be permanently deleted."
            actionLabel="Delete"
            confirm={onConfirm}
            image={apiKeyDeleteImage}
        />
    );
};

const Developers = () => {
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const service = useAPIKeysService();

    const [keys, setKeys] = useState<APIKey[]>();

    const [openStepsIndex, setOpenStepsIndex] = useState<number>();

    const [name, setName] = useState('');

    const [keyType, setKeyType] = useState(APIKeyType.PUBLIC);

    const [liveKey, setLiveKey] = useState(true);

    // Origins for API key being created/edited
    const [origins, setOrigins] = useState(['']);

    const [selectedKeyIndex, setSelectedKeyIndex] = useState<number>();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const [showCreateDialogBox, setShowCreateDialogBox] = useState(false);
    const [showDeleteDialogBox, setShowDeleteDialogBox] = useState(false);
    const [showUpdateDialogBox, setShowUpdateDialogBox] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopperClose = () => {
        setAnchorEl(null);
    };

    const popperContent = [
        {
            name: 'Edit',
            handleClick: () => {
                if (!keys) {
                    return;
                }

                handlePopperClose();
                setShowUpdateDialogBox(true);

                setName(keys[selectedKeyIndex!].name);
                setKeyType(
                    keys[selectedKeyIndex!].public
                        ? APIKeyType.PUBLIC
                        : APIKeyType.SERVER
                );

                setOrigins(keys[selectedKeyIndex!].public?.origins || origins);
            },
        },
        {
            name: 'Delete',
            handleClick: () => {
                handlePopperClose();
                setShowDeleteDialogBox(true);
            },
        },
    ];

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setKeys(await service.list());
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [service]);

    const updateKey = async (keyIndex: number, params: APIKeyUpdateParams) => {
        if (!keys) {
            return;
        }

        const key = keys[keyIndex];

        if (!key.public) {
            params.origins = undefined;
        }

        try {
            setLoading(true);
            await service.update(key.value, params);

            setKeys(await service.list());

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Successfully updated API Key.',
            });
        } catch (err) {
            console.log(err);
        }

        setShowUpdateDialogBox(false);
        setLoading(false);
    };

    const createKey = async () => {
        if (!name) {
            return;
        }

        try {
            setLoading(true);

            const res = await service.create({
                name,
                live: liveKey,
                origins: keyType == APIKeyType.PUBLIC ? origins : undefined,
            });

            setKeys([res, ...(keys || [])]);

            setLiveKey(true);

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Successfully created API Key.',
            });

            setOpenStepsIndex(0);
        } catch (err) {
            console.log(err);
        }

        setShowCreateDialogBox(false);
        setLoading(false);
    };

    const deleteKey = async (keyIndex: number) => {
        if (!keys) {
            return;
        }

        try {
            setLoading(true);
            const key = keys[keyIndex];

            await service.delete(key.value);

            const newKeys = keys.filter((k) => k.value !== key.value);

            setKeys(newKeys);

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Successfully deleted API Key.',
            });
        } catch (err) {
            console.log(err);
        }

        setShowDeleteDialogBox(false);
        setLoading(false);
    };

    const classes = useStyles();

    return (
        <>
            <TopNav />

            <GridPaper direction="column">
                <Grid item>
                    <Box mt={1} />
                </Grid>

                <Grid item>
                    <Typography variant="h5">Developers</Typography>
                </Grid>

                <Grid item>
                    <Box mt={2} />
                </Grid>

                <Grid item>
                    <Divider />
                </Grid>

                <Grid item>
                    <Box mt={3} />
                </Grid>

                <Grid item>
                    <Typography variant="h6">API Keys</Typography>
                </Grid>

                <Grid item>
                    <Box mt={1} />
                </Grid>
                {/* guide */}
                <Grid container>
                    <Grid item xs={6}>
                        <List dense>
                            <ListItem dense className={classes.listHeading}>
                                <Typography
                                    variant="body1"
                                    // font weight only accepst bold, light etc.
                                    // @ts-ignore
                                    style={{ fontWeight: '400' }}
                                >
                                    To integrate autocomplete into your website:
                                </Typography>
                            </ListItem>

                            <ListItem dense>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary=' Click "Create New Access Key"' />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary="Enter the name for this API key" />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary='Select "Public" and set the origins to your website URLs' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary='Click "Create" and follow the steps that appear in the "Embed Code" section' />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        <List dense>
                            <ListItem dense className={classes.listHeading}>
                                <Typography
                                    variant="body1"
                                    // font weight only accepst bold, light etc.
                                    // @ts-ignore
                                    style={{ fontWeight: '400' }}
                                >
                                    To integrate into your application or
                                    server:
                                </Typography>
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary='Click "Create New Access Key"' />
                            </ListItem>

                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary="Enter the name for this API key" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary='Select "Server"' />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon style={{ color: '#8A97C2' }} />
                                </ListItemIcon>
                                <ListItemText primary='Click "Create" and follow the steps that appear in the "Embed Code" section' />
                            </ListItem>
                        </List>
                    </Grid>
                </Grid>

                <Grid item>
                    <Box mt={3} />
                </Grid>
                {/* buttons in guide section */}
                <Grid container item spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowCreateDialogBox(true);

                                setName('');
                                setOrigins(['']);
                            }}
                        >
                            Create New Access Key
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            href="https://avdocs.postgrid.com"
                            // This is a valid prop but not actually added to the MUI interface
                            // @ts-ignore
                            target="_blank"
                        >
                            Standard API Documentation
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            href="https://intdocs.postgrid.com"
                            // This is a valid prop but not actually added to the MUI interface
                            // @ts-ignore
                            target="_blank"
                        >
                            International API Documentation
                        </Button>
                    </Grid>
                </Grid>

                <Grid item>
                    <Box mt={1} />
                </Grid>
            </GridPaper>

            <Box mt={4} />

            <GridPaper direction="column">
                <Box m={1}>
                    <Table classes={{ root: classes.tableRoot }}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>API Key</StyledTableCell>
                                <StyledTableCell>Lookups</StyledTableCell>
                                <StyledTableCell>
                                    <Grid
                                        container
                                        item
                                        spacing={1}
                                        alignItems="center"
                                    >
                                        <Grid item>Status</Grid>
                                        <Grid item>
                                            <Tooltip title="Indicates standard API statuses as verified/corrected/failed">
                                                <HelpIcon
                                                    classes={{
                                                        root: classes.toolTipIcon,
                                                    }}
                                                    fontSize="small"
                                                />
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                    Toggle Status
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Actions
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    Expand
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBodyLoader show={loading} columns={6}>
                            {keys &&
                                keys.map((key, keyIndex) => (
                                    <React.Fragment key={key.value}>
                                        <StyledTableRow hover>
                                            <StyledTableCell>
                                                {key.name}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                <APIKeyField
                                                    value={key.value}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {key.lookups}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {key.live
                                                    ? `${key.verified || 0}/${
                                                          key.corrected || 0
                                                      }/${key.failed || 0}`
                                                    : 'N/A'}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Switch
                                                    color="primary"
                                                    checked={key.enabled}
                                                    onChange={() =>
                                                        updateKey(keyIndex, {
                                                            ...key,
                                                            enabled:
                                                                !key.enabled,
                                                        })
                                                    }
                                                />
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        setSelectedKeyIndex(
                                                            keyIndex
                                                        );
                                                        handlePopperOpen(e);
                                                    }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                {openStepsIndex !== keyIndex ? (
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenStepsIndex(
                                                                keyIndex
                                                            );
                                                        }}
                                                    >
                                                        <ExpandMoreIcon />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setOpenStepsIndex(
                                                                undefined
                                                            );
                                                        }}
                                                    >
                                                        <ExpandLessIcon />
                                                    </IconButton>
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow
                                            style={{ borderTop: '0px' }}
                                        >
                                            <StyledTableCell
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                    width: '100%',
                                                }}
                                                colSpan={18}
                                            >
                                                <Collapse
                                                    in={
                                                        openStepsIndex ===
                                                        keyIndex
                                                    }
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    {key.public ? (
                                                        <EmbedCodePublic
                                                            apiKey={key.value}
                                                        />
                                                    ) : (
                                                        <EmbedCodeServer
                                                            apiKey={key.value}
                                                        />
                                                    )}
                                                </Collapse>
                                            </StyledTableCell>
                                        </StyledTableRow>

                                        <Popper
                                            name={key.name}
                                            handleClose={handlePopperClose}
                                            anchorEl={anchorEl}
                                            content={popperContent}
                                            noUpperTriangle={true}
                                        />
                                    </React.Fragment>
                                ))}
                        </TableBodyLoader>
                    </Table>
                </Box>
                {loading && (
                    <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{ minHeight: '50vh' }}
                    >
                        <CircularProgress />
                    </Grid>
                )}
            </GridPaper>
            <KeyDialog
                open={showCreateDialogBox}
                onClose={() => {
                    setLiveKey(true);
                    setShowCreateDialogBox(false);
                }}
                onConfirm={createKey}
                title="Create API Key"
                actionLabel="Create"
                name={name}
                setName={(v: string) => setName(v)}
                keyType={keyType}
                setKeyType={setKeyType}
                liveKey={liveKey}
                setLiveKey={setLiveKey}
                origins={origins}
                setOrigins={setOrigins}
                isCreateDialogBox={true}
                loading={loading}
            />

            {keys && (
                <>
                    <KeyDialog
                        open={showUpdateDialogBox}
                        onClose={() => {
                            setLiveKey(true);
                            setShowUpdateDialogBox(false);
                        }}
                        onConfirm={() =>
                            updateKey(selectedKeyIndex!, {
                                name,
                                origins,
                                enabled: keys[selectedKeyIndex!].enabled,
                            })
                        }
                        title="Update API Key"
                        actionLabel="Update"
                        name={name}
                        setName={(v: string) => setName(v)}
                        keyType={keyType}
                        liveKey={liveKey}
                        origins={origins}
                        setOrigins={setOrigins}
                        isCreateDialogBox={false}
                        loading={loading}
                    />

                    <DeleteDialog
                        open={showDeleteDialogBox}
                        onClose={() => setShowDeleteDialogBox(false)}
                        onConfirm={() => deleteKey(selectedKeyIndex!)}
                    />
                </>
            )}
        </>
    );
};
export default Developers;
