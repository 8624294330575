import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import {
    Typography,
    Grid,
    makeStyles,
    Box,
    CircularProgress,
} from '@material-ui/core';

import {
    BulkList,
    BulkListStatus,
    useService as useBulkListsService,
} from '../services/BulkLists';

import BulkTable from '../components/BulkTable';
import TopNav from '../components/TopNav';
import Button from '../components/Button';

import bulkCoverImage from '../img/bulkCover.png';

const useStyles = makeStyles({
    pageRoot: {
        padding: '1.3rem',
        backgroundColor: 'white',
        boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
        borderRadius: '5px',
    },
});

interface BulkVerifyListProps {
    createLink?: string;
}
export const BulkVerifyList = (props: BulkVerifyListProps) => {
    const history = useHistory();

    const classes = useStyles();

    const service = useBulkListsService();

    const [rows, setRows] = useState<BulkList[]>([]);

    const [totalCount, setTotalCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const query = {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                };

                let res = await service.list(query);

                for (;;) {
                    setRows(res.data);
                    setTotalCount(res.count);

                    setLoading(false);

                    // No pending lists
                    if (
                        !res.data.find(
                            (row) => row.status === BulkListStatus.PENDING
                        )
                    ) {
                        break;
                    }

                    // Wait 3-5 seconds between requests
                    await new Promise<void>((resolve) =>
                        setTimeout(() => resolve(), 3000 + Math.random() * 2000)
                    );

                    res = await service.list(query);
                }
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [service, page, rowsPerPage]);

    const handleNavigation = () => {
        const route = props?.createLink ?? '/dashboard/bulk_verify/create';
        history.push(route);
    };

    return (
        <Grid container className={classes.pageRoot}>
            <Grid container justify="space-between">
                <Grid item>
                    <Typography variant="h5">Lists</Typography>
                    <Typography variant="caption" color="textSecondary">
                        {totalCount > 0
                            ? 'Lists you have verified in the past'
                            : 'Click upload to get started'}
                    </Typography>
                </Grid>
                <Grid item>
                    {totalCount > 0 ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNavigation}
                        >
                            Upload File
                        </Button>
                    ) : null}
                </Grid>
            </Grid>

            {loading ? (
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ minHeight: '50vh' }}
                >
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : totalCount > 0 ? (
                <>
                    <Grid container direction="column">
                        <Grid item>
                            <Box pt={2} />
                        </Grid>
                        <Grid item>
                            <BulkTable
                                rows={rows}
                                totalCount={totalCount}
                                setTotalCount={setTotalCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                page={page}
                                setPage={setPage}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid
                    item
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                        <Typography variant="h6" display="inline">
                            Clean up your list in a few simple steps. The first{' '}
                        </Typography>
                        <Typography
                            variant="h6"
                            color="primary"
                            display="inline"
                        >
                            100 addresses
                        </Typography>
                        <Typography variant="h6" display="inline">
                            {' '}
                            are free.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img
                            src={bulkCoverImage}
                            width="800"
                            alt="roadmap"
                            style={{ cursor: 'grab' }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            style={{ padding: '7px 40px' }}
                            variant="contained"
                            color="primary"
                            onClick={handleNavigation}
                        >
                            Upload File
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const BulkVerify = () => {
    return (
        <>
            <TopNav />
            <BulkVerifyList />
        </>
    );
};

export default BulkVerify;
