import { useMemo } from 'react';

import { User } from '../context/Auth';

import { Service as BaseService, useService as useBaseService } from './Base';

interface CreateSignupParams {
    firstName: string;
    lastName: string;
    email: string;
    password: string;

    // Should be organizationName
    name: string;

    recaptchaToken: string;
}

interface Signup {
    id: string;
}

export interface UpdateUserParams {
    firstName: string;
    lastName: string;
}

export interface UpdatePasswordParams {
    oldPassword: string;
    newPassword: string;
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    // TODO Should have a separate service for signups
    async createSignup(params: CreateSignupParams) {
        return await this.base.fetchAPI<Signup>('/signups', {
            method: 'POST',
            body: params,
        });
    }

    async completeSignup(id: string) {
        return await this.base.fetchAPI<{}>(`/signups/${id}/organization`, {
            method: 'POST',
            body: {
                country: 'us',
            },
        });
    }

    async login(params: { email: string; password: string }) {
        return await this.base.fetchAPI<User>(`/users/auth`, {
            body: params,
        });
    }

    async get() {
        return await this.base.fetchAPI<User>(
            `/users/${this.base.authState!.user!.id}`
        );
    }

    async list(skip: number, limit: number) {
        const params = new URLSearchParams({
            skip: skip.toString(),
            limit: limit.toString(),
        });

        return await this.base.fetchAPI<{
            data: User[];
            count: number;
        }>(`/users?${params.toString()}`);
    }

    async updatePassword(params: UpdatePasswordParams) {
        return await this.base.fetchAPI<{}>(
            `/users/${this.base.authState!.user!.id}/password`,
            {
                method: 'PATCH',
                body: params,
            }
        );
    }

    async updateUser(params: UpdateUserParams) {
        return await this.base.fetchAPI<User>(
            `/users/${this.base.authState!.user!.id}`,
            {
                method: 'POST',
                body: params,
            }
        );
    }

    async createPasswordReset({ email }: { email: string }) {
        return await this.base.fetchAPI<{}>('/users/forgot_password', {
            method: 'POST',
            body: {
                email,
                resetLink: `${process.env.REACT_APP_URL}/reset_password`,
            },
        });
    }

    async resetPassword(params: { token: string; password: string }) {
        return await this.base.fetchAPI<{}>('/users/reset_password', {
            method: 'POST',
            body: params,
        });
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
