import { useEffect, useState } from 'react';

import moment from 'moment';

const BASE_URL = process.env.REACT_APP_API_URL;

export interface ListResponse<T> {
    skip: number;
    limit: number;
    totalCount: number;
    data: T[];
}

export interface DeleteResponse {
    id: string;
    deleted: true;
}

export enum APIErrorType {
    TOKEN_EXPIRED = 'token_expired_error',
    NOT_SUBSCRIBED = 'org_not_subscribed_error',
}

// We allow an arbitrary object to be passed in for the body
export type APIRequestInit = Omit<RequestInit, 'body'> & {
    body?: RequestInit['body'] | Record<string, any>;
};

export class APIRequestError extends Error {
    public status: number;

    constructor(status: number, message: string) {
        super(message);

        this.status = status;
    }
}

// TODO Refactor into common module across all of our APIs
export const flatten = (
    obj: object,
    parentKey: string = '',
    result: Record<string, any> = {}
) => {
    for (const [key, value] of Object.entries(obj)) {
        const newKey = parentKey ? `${parentKey}.${key}` : key;

        if (value !== null && typeof value === 'object') {
            flatten(value, newKey, result);
        } else {
            result[newKey] = value;
        }
    }

    return result;
};

export const unflatten = (obj: Record<string, unknown>) => {
    const result: Record<string, any> = {};

    for (const [key, value] of Object.entries(obj)) {
        if (key.indexOf('.') === -1) {
            // Already flattened
            result[key] = value;
            continue;
        }

        const keys = key.split('.');

        let prev = result;

        // Iteratively create all the nested objects
        for (const innerKey of keys.slice(0, -1)) {
            if (typeof prev[innerKey] !== 'object') {
                prev[innerKey] = {};
            }

            prev = prev[innerKey] as typeof prev;
        }

        prev[keys[keys.length - 1]] = value;
    }

    return result;
};

export const convertDatesInPlace = (obj: any) => {
    if (typeof obj !== 'object') {
        return;
    }

    if (obj === undefined || obj === null) {
        return;
    }

    if (Array.isArray(obj)) {
        obj.forEach(convertDatesInPlace);
        return;
    }

    for (const [key, value] of Object.entries(obj)) {
        if (
            (key === 'createdAt' ||
                key === 'updatedAt' ||
                key === 'sendDate' ||
                key === 'startDate' ||
                key === 'endDate' ||
                key === 'date') &&
            typeof value === 'string'
        ) {
            obj[key] = moment(value).toDate();
            continue;
        }

        convertDatesInPlace(value);
    }
};

// TODO Maybe we can use decorators on type T to validate
export async function fetchAPI<T>(
    path: string,
    init?: APIRequestInit
): Promise<T> {
    init = init || {};

    init.mode = 'cors';

    if (init.body && !(init.body instanceof FormData)) {
        if (typeof init.body !== 'string') {
            init.body = JSON.stringify(init.body);
        }

        const headers: Record<string, string> = Array.isArray(init.headers)
            ? Object.fromEntries(init.headers)
            : init.headers;

        init.headers = {
            ...headers,
            'Content-Type': headers?.['Content-Type'] || 'application/json',
        };
    }

    const resp = await fetch(`${BASE_URL}${path}`, init as RequestInit);
    const value = await resp.json();

    if (resp.status >= 400) {
        throw new APIRequestError(resp.status, value.message);
    }

    // Convert 'createdAt' and 'updatedAt' to dates
    convertDatesInPlace(value.data);

    return value.data;
}

// Will ensure that the returned value only changes once per 'timeout'
// across renders.
export const useDebouncedValue = (value: any, timeout: number) => {
    const [state, setState] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => setState(value), timeout);

        return () => clearTimeout(handler);
    }, [value, timeout]);

    return state;
};

// Taken from
// https://stackoverflow.com/questions/1688657/how-do-i-extract-google-analytics-campaign-data-from-their-cookie-with-javascrip
export const getGoogleAnalyticsData = () => {
    let data: {
        ga_source?: string;
        ga_campaign?: string;
        ga_medium?: string;
        ga_content?: string;
        ga_term?: string;
    } = {};

    let gc = '';
    let c_name = '__utmz';

    if (document.cookie.length > 0) {
        let c_start = document.cookie.indexOf(c_name + '=');

        if (c_start !== -1) {
            c_start = c_start + c_name.length + 1;

            let c_end = document.cookie.indexOf(';', c_start);

            if (c_end === -1) c_end = document.cookie.length;

            gc = unescape(document.cookie.substring(c_start, c_end));
        }
    }

    if (gc !== '') {
        let y = gc.split('|');

        for (let i = 0; i < y.length; i++) {
            if (y[i].indexOf('utmcsr=') >= 0)
                data.ga_source = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmccn=') >= 0)
                data.ga_campaign = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmcmd=') >= 0)
                data.ga_medium = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmcct=') >= 0)
                data.ga_content = y[i].substring(y[i].indexOf('=') + 1);
            if (y[i].indexOf('utmctr=') >= 0)
                data.ga_term = y[i].substring(y[i].indexOf('=') + 1);
        }
    }

    return data;
};

export const createAnalyticsEvent = (params: {
    event: string;
    eventProps: {
        category: string;
        action: string;
        label?: string;
        value?: string;
    };
}) => {
    if ((window as any).dataLayer) {
        (window as any).dataLayer.push(params);
    }
};

export const notifySignup = async (params: {
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    phoneNumber?: string;
}) => {
    const webhookURL = process.env.REACT_APP_SIGNUP_WEBHOOK_URL;

    const analyticsData = getGoogleAnalyticsData();

    const ip = await (async (): Promise<string> => {
        try {
            const res = await fetch('https://api.ipify.org/?format=json');
            return (await res.json()).ip;
        } catch (err) {
            return '(unknown)';
        }
    })();

    if (webhookURL) {
        await fetch(webhookURL, {
            method: 'POST',
            body: JSON.stringify({
                ...params,
                ...analyticsData,
                ip,
            }),
        });
    } else {
        console.error('Missing signup webhook URL.');
    }
};

export const validateOrigin = (entry: string): string | Error => {
    try {
        // Match any asterisk that is preceeded by a '.' or '/' or is that the start of a line
        // followed by a '/' or a '.'. And ensure that the number of matches equals the number of
        // asterisks in the URL
        if (
            entry.match(/(^|[/.])\*(?=$|[/.])/g)?.length !==
            entry.match(/\*/g)?.length
        ) {
            return new Error(
                'Wildcards must be surrounded by either a "." or a "/"'
            );
        }

        // HACK(Apaar): Let's hope nobody puts postgrid_wildcard as part of their domain name
        const placeholder = 'postgrid_wildcard';

        // Replace all wildcards with a valid domain identifier
        // and then ensure that the result is a valid URL.
        const wildcardReplacedURLStr = entry
            .trim()
            // If the `*` is at the start of the string, then we automatically add a protocol as well.
            .replace(/^\*\./, `https://${placeholder}.`)
            .replace(/\*/g, placeholder);

        const url = new URL(wildcardReplacedURLStr);

        // Revert the replacements.
        return (
            url.origin
                // FIXME(Apaar): Actually, this replaces https://*.postgrid.com with just
                // *.postgrid.com, not good. Should remove this 'replace'.
                .replace(`https://${placeholder}`, '*')
                .replace(new RegExp(placeholder, 'g'), '*')
        );
    } catch (err) {
        return new Error('Please enter a valid URL');
    }
};
