import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ActionType, Context as AuthContext } from '../context/Auth';

const Logout = () => {
    const { dispatch } = useContext(AuthContext);
    useEffect(() => {
        alert('fsd');
        dispatch({ type: ActionType.LOGOUT });
    }, []);
    return <Redirect to="/login" />;
};

export default Logout;
