import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface FieldMapInputContainerProps {
    title: string;
    toolTip?: React.ReactNode;
}

const FieldMapInputContainer: React.FC<FieldMapInputContainerProps> = ({
    title,
    toolTip,
    children,
}) => {
    return (
        <Grid container item xs={7} alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="subtitle1">{title}</Typography>
                    {toolTip}
                </div>
            </Grid>
            <Grid item xs={9}>
                {children}
            </Grid>
        </Grid>
    );
};

export default FieldMapInputContainer;
