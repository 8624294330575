import React, { useState, useEffect, useContext, PropsWithoutRef } from 'react';

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import DeleteIcon from '@material-ui/icons/Delete';

import { User } from '../context/Auth';
import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { useService as useUsersService } from '../services/Users';
import { useService as useInvitesService } from '../services/Invites';
import { useOrganization } from '../services/Organization';

import TableBodyLoader from '../components/TableBodyLoader';
import ButtonDisabledTooltip from '../components/ButtonDisabledTooltip';
import ResetTablePagination from '../components/ResetTablePagination';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';

import InviteUser from './InviteUser';
import deleteGraphicLogo from '../img/delete-graphic.png';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        borderRadius: '6px',
        '&>.MuiDialog-scrollPaper': {
            '&>.MuiDialog-paper': {
                borderRadius: '8px',
                padding: '20px',
            },
        },
    },
}));

const ConfirmDeleteDialog = (
    props: PropsWithoutRef<{
        open: boolean;
        setOpen: (open: boolean) => void;
        onClose: DialogProps['onClose'];
        userToDelete?: User;
        confirm: () => void;
    }>
) => {
    const classes = useStyles();

    return (
        <Dialog open={props.open} onClose={props.onClose} classes={classes}>
            <DialogContent>
                <img
                    src={deleteGraphicLogo}
                    alt="Delete graphic"
                    style={{ margin: '10px auto', display: 'block' }}
                />
                <DialogTitle>
                    <Typography variant="h5">Delete User</Typography>
                </DialogTitle>
                <DialogContentText>
                    Are you sure you want to delete the user{' '}
                    {props.userToDelete?.email}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={5}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                props.setOpen(false);
                                props.confirm();
                            }}
                            size="large"
                            fullWidth
                        >
                            Delete
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(e) => {
                                props.setOpen(false);
                            }}
                            size="large"
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

const Users = (props: {}) => {
    const match = useRouteMatch();
    const history = useHistory();

    const service = useUsersService();
    const invService = useInvitesService();

    const { dispatch } = useContext(NotificationContext);

    const org = useOrganization([history.location]);

    const [users, setUsers] = useState<User[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState<User>();
    const [deleting, setDeleting] = useState(false);

    const [loading, setLoading] = useState(false);

    const showDelete = (user: User) => {
        setUserToDelete(user);
        setDeleteOpen(true);
    };

    const confirmDelete = async () => {
        if (!userToDelete) {
            return;
        }

        try {
            setDeleting(true);

            await invService.delete(userToDelete.id);

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Deleted user.',
            });
        } catch (err) {
            console.error(err);
        }

        setDeleting(false);
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);

                const resp = await service.list(
                    page * rowsPerPage,
                    rowsPerPage
                );

                setTotalCount(resp.count);
                setUsers(resp.data);
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
        })();
    }, [service, deleting, rowsPerPage, page, history.location]);

    return (
        <Switch>
            <Route exact path={`${match.path}`}>
                <ConfirmDeleteDialog
                    userToDelete={userToDelete}
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                    onClose={() => {
                        setDeleteOpen(false);
                    }}
                    confirm={confirmDelete}
                />
                <TopNav />
                <GridPaper direction="column" spacing={2}>
                    <Grid item>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Typography variant="h5">Users</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <ButtonDisabledTooltip
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                history.push(
                                                    `${match.path}/create`
                                                );
                                            }}
                                            disabled={
                                                !org ||
                                                (!org.stripeSubscriptionId &&
                                                    !org.allowInvitingUsersWithoutSubscribing)
                                            }
                                            TooltipProps={{
                                                title: 'Subscribe to invite users',
                                            }}
                                        >
                                            Invite User
                                        </ButtonDisabledTooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Created At</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBodyLoader
                                    show={loading || deleting}
                                    columns={6}
                                >
                                    {users.map((user) => {
                                        return (
                                            <TableRow key={user.id}>
                                                <TableCell>
                                                    {user.firstName}{' '}
                                                    {user.lastName}
                                                </TableCell>
                                                <TableCell>
                                                    {user.email}
                                                </TableCell>
                                                <TableCell>
                                                    {user.pendingInvite ? (
                                                        <Box color="primary.main">
                                                            Invited
                                                        </Box>
                                                    ) : (
                                                        <Box color="success.main">
                                                            Registered
                                                        </Box>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {user.createdAt.toLocaleString()}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        onClick={() => {
                                                            showDelete(user);
                                                        }}
                                                        disabled={
                                                            !user.pendingInvite
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBodyLoader>
                                <TableFooter>
                                    <TableRow>
                                        <ResetTablePagination
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                            page={page}
                                            setPage={setPage}
                                            count={totalCount}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </GridPaper>
            </Route>
            <Route path={`${match.path}/create`} component={InviteUser} />
        </Switch>
    );
};

export default Users;
