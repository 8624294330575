import React from 'react';

const blogIcon = () => (
    <>
        <g
            id="Group_1580"
            data-name="Group 1580"
            transform="translate(6.65 6.635)"
        >
            <g id="Group_1579" data-name="Group 1579">
                <path
                    id="Path_1336"
                    data-name="Path 1336"
                    d="M179.2,176h-2.081a.68.68,0,1,0,0,1.357H179.2a.7.7,0,0,0,.724-.679A.687.687,0,0,0,179.2,176Z"
                    transform="translate(-176.4 -176)"
                    fill="#575e71"
                />
            </g>
        </g>
        <g
            id="Group_1582"
            data-name="Group 1582"
            transform="translate(6.65 10.857)"
        >
            <g id="Group_1581" data-name="Group 1581">
                <path
                    id="Path_1337"
                    data-name="Path 1337"
                    d="M181.361,288h-4.237a.761.761,0,0,0-.724.754.75.75,0,0,0,.724.754h4.237a.755.755,0,0,0,0-1.508Z"
                    transform="translate(-176.4 -288)"
                    fill="#575e71"
                />
            </g>
        </g>
        <g id="Group_1584" data-name="Group 1584">
            <g id="Group_1583" data-name="Group 1583">
                <path
                    id="Path_1338"
                    data-name="Path 1338"
                    d="M14.235,0H4.75A4.772,4.772,0,0,0,0,4.765V14.25A4.769,4.769,0,0,0,4.75,19h9.485A4.775,4.775,0,0,0,19,14.235V4.765A4.775,4.775,0,0,0,14.235,0Zm1.176,11.671a3.759,3.759,0,0,1-3.77,3.71H7.329a3.685,3.685,0,0,1-3.71-3.694V7.313a3.685,3.685,0,0,1,3.71-3.694H9.952a3.785,3.785,0,0,1,2.9,2.021,2.768,2.768,0,0,1,.332,1.267c.06.467.09.814.3,1.01.3.271,1.4.09,1.629.256l.166.136.106.211.03.151v3Z"
                    fill="#575e71"
                />
            </g>
        </g>
    </>
);

const overviewIcon = (props: { color?: string }) => (
    <>
        <svg
            id="_1"
            data-name="1"
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
        >
            <path
                id="Path_598"
                data-name="Path 598"
                d="M5.01,0H.948A.949.949,0,0,0,0,.948V3.385a.949.949,0,0,0,.948.948H5.01a.949.949,0,0,0,.948-.948V.948A.949.949,0,0,0,5.01,0Zm0,0"
                fill={props.color}
            />
            <path
                id="Path_599"
                data-name="Path 599"
                d="M5.01,213.332H.948A.949.949,0,0,0,0,214.28v5.688a.949.949,0,0,0,.948.948H5.01a.949.949,0,0,0,.948-.948V214.28A.949.949,0,0,0,5.01,213.332Zm0,0"
                transform="translate(0 -207.915)"
                fill={props.color}
            />
            <path
                id="Path_600"
                data-name="Path 600"
                d="M282.343,341.332H278.28a.949.949,0,0,0-.948.948v2.438a.949.949,0,0,0,.948.948h4.063a.949.949,0,0,0,.948-.948V342.28A.949.949,0,0,0,282.343,341.332Zm0,0"
                transform="translate(-270.29 -332.665)"
                fill={props.color}
            />
            <path
                id="Path_601"
                data-name="Path 601"
                d="M282.343,0H278.28a.949.949,0,0,0-.948.948V6.635a.949.949,0,0,0,.948.948h4.063a.949.949,0,0,0,.948-.948V.948A.949.949,0,0,0,282.343,0Zm0,0"
                transform="translate(-270.29)"
                fill={props.color}
            />
        </svg>
    </>
);

const developerIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.524"
            height="12.09"
            viewBox="0 0 16.524 12.09"
        >
            <g id="_2" data-name="2" transform="translate(-59.476 -344.6)">
                <g id="programming" transform="translate(59.476 344.6)">
                    <path
                        id="Path_602"
                        data-name="Path 602"
                        d="M14.186,68.7H2.339A2.341,2.341,0,0,0,0,71.039v7.412A2.342,2.342,0,0,0,2.339,80.79H14.186a2.342,2.342,0,0,0,2.339-2.339V71.039A2.341,2.341,0,0,0,14.186,68.7ZM6,72.421l-2.46,2.46,2.188,2.188a.484.484,0,0,1-.685.685l-2.53-2.53a.484.484,0,0,1,0-.685l2.8-2.8A.484.484,0,1,1,6,72.421ZM9.99,71.282,7.43,78.53a.484.484,0,1,1-.913-.322l2.56-7.248a.484.484,0,0,1,.913.322Zm4.025,3.669-2.8,2.8a.484.484,0,1,1-.685-.685l2.46-2.46L10.8,72.421a.484.484,0,0,1,.685-.685l2.53,2.53A.484.484,0,0,1,14.015,74.951Z"
                        transform="translate(0 -68.7)"
                        fill={props.color}
                    />
                </g>
            </g>
        </svg>
    </>
);

const bulkIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.672"
            height="13.696"
            viewBox="0 0 13.672 13.696"
        >
            <g id="_3" data-name="3" transform="translate(-60 -405)">
                <g
                    id="Layer_50"
                    data-name="Layer 50"
                    transform="translate(60 405)"
                >
                    <path
                        id="Path_603"
                        data-name="Path 603"
                        d="M14.7,7.725c-.268-.17-.654-.405-.654-.562a2.059,2.059,0,0,1,.242-.915c.222-.556.451-1.124.163-1.542s-.9-.373-1.49-.327a1.961,1.961,0,0,1-.876,0,2.209,2.209,0,0,1-.3-.876c-.131-.595-.268-1.209-.758-1.372s-.928.242-1.366.654c-.248.222-.582.523-.752.523s-.51-.3-.758-.523c-.431-.4-.882-.8-1.366-.654S6.134,2.882,6,3.477a2.222,2.222,0,0,1-.307.869,1.843,1.843,0,0,1-.876,0c-.582-.052-1.189-.1-1.484.327s-.065.987.157,1.542a2.085,2.085,0,0,1,.248.915c-.052.157-.418.392-.686.562-.5.314-1.026.654-1.026,1.176s.523.863,1.026,1.183c.268.17.654.4.686.556a2.111,2.111,0,0,1-.248.922c-.222.556-.451,1.124-.157,1.542s.9.373,1.484.32a1.961,1.961,0,0,1,.876.033A2.268,2.268,0,0,1,6,14.293c.131.595.268,1.216.752,1.379a.693.693,0,0,0,.222.085A1.961,1.961,0,0,0,8.121,15.1c.248-.222.588-.529.758-.529s.5.307.752.529c.438.392.889.8,1.366.654s.654-.784.758-1.379a2.17,2.17,0,0,1,.3-.869,1.961,1.961,0,0,1,.876-.033c.588.052,1.189.111,1.49-.32s.059-.987-.163-1.542a2.085,2.085,0,0,1-.242-.922c.046-.157.412-.386.654-.556.51-.32,1.033-.654,1.033-1.183S15.206,8.039,14.7,7.725Zm-3.385-.516L8.415,11a.353.353,0,0,1-.242.124.314.314,0,0,1-.235-.1L6.468,9.509a.327.327,0,0,1,0-.464.32.32,0,0,1,.457,0l1.189,1.242L10.781,6.8a.327.327,0,0,1,.516.4Z"
                        transform="translate(-2.03 -2.093)"
                        fill={props.color}
                    />
                </g>
            </g>
        </svg>
    </>
);

const profileIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.35"
            height="13.722"
            viewBox="0 0 12.35 13.722"
        >
            <path
                id="_4"
                data-name="4"
                d="M12.664,2.744H3.058a.688.688,0,0,1-.686-.686.738.738,0,0,1,.686-.686h9.606A.688.688,0,0,0,13.35.686.738.738,0,0,0,12.664,0H3.058A2.064,2.064,0,0,0,1,2.058v9.606a2.064,2.064,0,0,0,2.058,2.058h9.606a.688.688,0,0,0,.686-.686V3.431A.738.738,0,0,0,12.664,2.744ZM7.175,4.8A1.372,1.372,0,1,1,5.8,6.175,1.376,1.376,0,0,1,7.175,4.8ZM4.431,11.664a2.744,2.744,0,1,1,5.489,0Z"
                transform="translate(-1)"
                fill={props.color}
            />
        </svg>
    </>
);

const supportIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.667"
            height="16.647"
            viewBox="0 0 16.667 16.647"
        >
            <g id="_5" data-name="5" transform="translate(-5 -5.044)">
                <path
                    id="Path_1355"
                    data-name="Path 1355"
                    d="M22.649,16.456V13.825a4.825,4.825,0,1,0-9.649,0v2.632a4.825,4.825,0,0,0,9.649,0ZM16.2,17.9a.439.439,0,0,1,.623,0,1.417,1.417,0,0,0,2.009,0,.44.44,0,0,1,.623.623C17.93,20.027,15.478,18.619,16.2,17.9Z"
                    transform="translate(-4.491 -2.221)"
                    fill={props.color}
                />
                <path
                    id="Path_1356"
                    data-name="Path 1356"
                    d="M20.351,10.727h-.5a6.579,6.579,0,0,0-13.035,0h-.5A1.316,1.316,0,0,0,5,12.043v2.632A1.316,1.316,0,0,0,6.316,15.99H7.632a.439.439,0,0,0,.439-.439V11.166a.439.439,0,0,0-.368-.439,5.7,5.7,0,0,1,11.263,0,.439.439,0,0,0-.368.439v4.386a.439.439,0,0,0,.439.439,4.825,4.825,0,0,1-3.947,4.746,1.1,1.1,0,0,0-1.1-1.237h-.877a1.1,1.1,0,0,0,0,2.193h1.1a5.7,5.7,0,0,0,5.7-5.7h.439a1.316,1.316,0,0,0,1.316-1.316V12.043A1.316,1.316,0,0,0,20.351,10.727Z"
                    transform="translate(0 0)"
                    fill={props.color}
                />
            </g>
        </svg>
    </>
);

const invoiceIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.333"
            height="13.283"
            viewBox="0 0 12.333 13.283"
        >
            <g id="_6" data-name="6" transform="translate(-3 -2)">
                <path
                    id="Path_1357"
                    data-name="Path 1357"
                    d="M13.91,2H4.423A1.423,1.423,0,0,0,3,3.423V14.808a.474.474,0,0,0,.778.365l2.543-2.12,2.263,1.869a.949.949,0,0,0,1.214,0l2.215-1.869,2.543,2.12a.474.474,0,0,0,.778-.365V3.423A1.423,1.423,0,0,0,13.91,2Zm-1.423,8.064H5.846a.474.474,0,0,1,0-.949h6.641a.474.474,0,1,1,0,.949Zm0-2.372H5.846a.474.474,0,0,1,0-.949h6.641a.474.474,0,0,1,0,.949Zm0-2.372H5.846a.474.474,0,0,1,0-.949h6.641a.474.474,0,0,1,0,.949Z"
                    transform="translate(0 0)"
                    fill={props.color}
                />
            </g>
        </svg>
    </>
);

const upgradeIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.05"
            height="14.05"
            viewBox="0 0 14.05 14.05"
        >
            <path
                id="_7"
                data-name="7"
                d="M7.025,0A7.025,7.025,0,1,1,0,7.025,7.024,7.024,0,0,1,7.025,0Zm.53,6.589v4.687H6.5V6.589L5.088,8V6.5L7.022,4.566,8.957,6.5V8Zm3.721-2.755V2.774h-8.5V3.834Z"
                fill={props.color}
                fillRule="evenodd"
            />
        </svg>
    </>
);

const apiDocIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.429"
            height="10.996"
            viewBox="0 0 15.429 10.996"
        >
            <g id="_8" data-name="8" transform="translate(0 -73.56)">
                <g
                    id="Group_1593"
                    data-name="Group 1593"
                    transform="translate(8.166 79.096)"
                >
                    <g
                        id="Group_1592"
                        data-name="Group 1592"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_1148"
                            data-name="Path 1148"
                            d="M271.731,257.264H271v.77h.731a.385.385,0,0,0,0-.77Z"
                            transform="translate(-271 -257.264)"
                            fill={props.color}
                        />
                    </g>
                </g>
                <g
                    id="Group_1595"
                    data-name="Group 1595"
                    transform="translate(4.617 79.096)"
                >
                    <g
                        id="Group_1594"
                        data-name="Group 1594"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_1149"
                            data-name="Path 1149"
                            d="M153.787,257.266a.558.558,0,0,0-.558.558v.212h1.116v-.212A.558.558,0,0,0,153.787,257.266Z"
                            transform="translate(-153.229 -257.266)"
                            fill="#575e72"
                        />
                    </g>
                </g>
                <g
                    id="Group_1597"
                    data-name="Group 1597"
                    transform="translate(0 76.706)"
                >
                    <g
                        id="Group_1596"
                        data-name="Group 1596"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_1150"
                            data-name="Path 1150"
                            d="M0,177.964v6.493a1.358,1.358,0,0,0,1.356,1.356H14.073a1.358,1.358,0,0,0,1.356-1.356v-6.493Zm6.637,4.92a.452.452,0,0,1-.9,0v-.857H4.617v.857a.452.452,0,0,1-.9,0v-1.973h0a1.462,1.462,0,1,1,2.924,0v1.973Zm2.26-.857H8.166v.857a.452.452,0,0,1-.9,0V179.9a.452.452,0,0,1,.452-.452H8.9a1.289,1.289,0,1,1,0,2.578Zm2.818.857a.452.452,0,1,1-.9,0V179.9a.452.452,0,0,1,.9,0Z"
                            transform="translate(0 -177.964)"
                            fill="#575e72"
                        />
                    </g>
                </g>
                <g
                    id="Group_1599"
                    data-name="Group 1599"
                    transform="translate(0 73.56)"
                >
                    <g
                        id="Group_1598"
                        data-name="Group 1598"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_1151"
                            data-name="Path 1151"
                            d="M14.073,73.56H1.356A1.358,1.358,0,0,0,0,74.916V75.8H15.429v-.886A1.358,1.358,0,0,0,14.073,73.56Z"
                            transform="translate(0 -73.56)"
                            fill="#575e72"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </>
);

const printMailIcon = (props: { color?: string }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.485"
            height="13.463"
            viewBox="0 0 14.485 13.463"
        >
            <g id="_9" data-name="9" transform="translate(0 -18.065)">
                <g
                    id="Group_1626"
                    data-name="Group 1626"
                    transform="translate(4.668 28.866)"
                >
                    <g
                        id="Group_1625"
                        data-name="Group 1625"
                        transform="translate(0 0)"
                    >
                        <path
                            id="Path_1358"
                            data-name="Path 1358"
                            d="M169.645,399.834H165.46a.483.483,0,1,0,0,.966h4.185a.483.483,0,1,0,0-.966Z"
                            transform="translate(-164.977 -399.834)"
                            fill="#575e72"
                        />
                    </g>
                </g>
                <g
                    id="Group_1628"
                    data-name="Group 1628"
                    transform="translate(4.668 27.343)"
                >
                    <g id="Group_1627" data-name="Group 1627">
                        <path
                            id="Path_1359"
                            data-name="Path 1359"
                            d="M169.645,346.006H165.46a.483.483,0,1,0,0,.966h4.185a.483.483,0,1,0,0-.966Z"
                            transform="translate(-164.977 -346.006)"
                            fill="#575e72"
                        />
                    </g>
                </g>
                <g
                    id="Group_1630"
                    data-name="Group 1630"
                    transform="translate(0 18.065)"
                >
                    <g id="Group_1629" data-name="Group 1629">
                        <path
                            id="Path_1360"
                            data-name="Path 1360"
                            d="M13.359,21.342H11.823V18.548a.483.483,0,0,0-.483-.483H3.146a.483.483,0,0,0-.483.483v2.795H1.127A1.128,1.128,0,0,0,0,22.469v4.858a1.128,1.128,0,0,0,1.127,1.127H2.663v2.592a.483.483,0,0,0,.483.483H11.34a.483.483,0,0,0,.483-.483V28.454h1.536a1.128,1.128,0,0,0,1.127-1.127V22.469A1.128,1.128,0,0,0,13.359,21.342Zm-9.73-2.312h7.228v2.312H3.629Zm7.228,11.532H3.629v-3.95h7.228C10.857,26.732,10.857,30.472,10.857,30.563Zm.483-6.385H10.111a.483.483,0,0,1,0-.966H11.34a.483.483,0,0,1,0,.966Z"
                            transform="translate(0 -18.065)"
                            fill="#575e72"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </>
);

const infoIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.085"
        height="20.085"
        viewBox="0 0 20.085 20.085"
    >
        <path
            id="information"
            d="M10.042,0A10.042,10.042,0,1,0,20.085,10.042,10.054,10.054,0,0,0,10.042,0ZM10.7,16c-.477.08-1.426.278-1.909.318a1.175,1.175,0,0,1-1.028-.535,1.255,1.255,0,0,1-.152-1.149l1.9-5.22H7.532A2.359,2.359,0,0,1,9.389,7.22,7.267,7.267,0,0,1,11.3,6.9a1.514,1.514,0,0,1,1.028.535,1.255,1.255,0,0,1,.152,1.149l-1.9,5.22h1.973A2.222,2.222,0,0,1,10.7,16Zm.6-9.724a1.255,1.255,0,1,1,1.255-1.255A1.255,1.255,0,0,1,11.3,6.277Z"
            fill="#7c8db5"
        />
    </svg>
);

const notificationIcon = () => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.422"
            // height="23.591"
            height="18.591"
            viewBox="0 0 17.422 18.591"
        >
            <g id="Notifications" transform="translate(-1817.513 -33.333)">
                <g id="Notification" transform="translate(1807 21.933)">
                    <g id="bell" transform="translate(10.513 11.4)">
                        <path
                            id="Path_7"
                            data-name="Path 7"
                            d="M184.9,465.044a3.32,3.32,0,0,0,6.078,0Z"
                            transform="translate(-179.232 -448.436)"
                            fill="#7c8db5"
                        />
                        <path
                            id="Path_9"
                            data-name="Path 9"
                            d="M69.773,94.154h-16.1a.656.656,0,0,1-.647-.5.626.626,0,0,1,.344-.712,3.416,3.416,0,0,0,1.04-1.413A16.329,16.329,0,0,0,55.5,85.064a6.228,6.228,0,0,1,12.455-.025c0,.008,0,.016,0,.025a16.329,16.329,0,0,0,1.084,6.462,3.415,3.415,0,0,0,1.04,1.413.626.626,0,0,1,.344.712A.656.656,0,0,1,69.773,94.154Zm.31-1.211h0Z"
                            transform="translate(-53.013 -78.836)"
                            fill="#7c8db5"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </>
);

const personIcon = () => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.097"
            height="19.849"
            viewBox="0 0 15.097 19.849"
        >
            <g id="User" transform="translate(-1722.557 -32.076)">
                <g
                    id="Group_1017"
                    data-name="Group 1017"
                    transform="translate(-131)"
                >
                    <g
                        id="user-2"
                        data-name="user"
                        transform="translate(1811.661 32.076)"
                    >
                        <path
                            id="Path_1093"
                            data-name="Path 1093"
                            d="M108.531,9.707c2.207,0,4-2.173,4-4.854S111.94,0,108.531,0s-4,2.173-4,4.854S106.324,9.707,108.531,9.707Z"
                            transform="translate(-59.087)"
                            fill="#7c8db5"
                        />
                        <path
                            id="Path_1094"
                            data-name="Path 1094"
                            d="M41.9,300.456c0-.163,0-.046,0,0Z"
                            transform="translate(0 -283.337)"
                            fill="#7c8db5"
                        />
                        <path
                            id="Path_1095"
                            data-name="Path 1095"
                            d="M308.085,301.635c0-.045,0-.31,0,0Z"
                            transform="translate(-251.094 -284.389)"
                            fill="#7c8db5"
                        />
                        <path
                            id="Path_1096"
                            data-name="Path 1096"
                            d="M56.995,184.583c-.074-4.67-.684-6-5.351-6.843a3.279,3.279,0,0,1-4.376,0c-4.616.833-5.263,2.144-5.348,6.691-.007.371-.01.391-.011.348,0,.081,0,.23,0,.491,0,0,1.111,2.24,7.547,2.24S57,185.269,57,185.269c0-.167,0-.284,0-.363A3,3,0,0,1,56.995,184.583Z"
                            transform="translate(-0.012 -167.66)"
                            fill="#7c8db5"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </>
);

const logoutIcon = () => (
    <>
        <svg
            id="_10"
            data-name="10"
            xmlns="http://www.w3.org/2000/svg"
            width="16.001"
            height="16"
            viewBox="0 0 16.001 16"
        >
            <path
                id="Path_393"
                data-name="Path 393"
                d="M7.333,10a2,2,0,0,1,0-4h3.333V1.833A1.835,1.835,0,0,0,8.833,0h-7A1.835,1.835,0,0,0,0,1.833V14.167A1.835,1.835,0,0,0,1.833,16h7a1.835,1.835,0,0,0,1.833-1.833V10Z"
                fill="#575e72"
            />
            <path
                id="Path_394"
                data-name="Path 394"
                d="M15.745,13.616A.668.668,0,0,1,15.333,13V11H10.667a.667.667,0,1,1,0-1.333h4.667v-2A.666.666,0,0,1,16.471,7.2l2.667,2.667a.666.666,0,0,1,0,.943l-2.667,2.667A.667.667,0,0,1,15.745,13.616Z"
                transform="translate(-3.333 -2.333)"
                fill="#575e72"
            />
        </svg>
    </>
);

const csvIcon = () => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.723"
            height="20"
            viewBox="0 0 23.889 26.277"
        >
            <g id="csv_1_" data-name="csv (1)" transform="translate(0)">
                <g id="Group_1104" data-name="Group 1104">
                    <g id="Group_1103" data-name="Group 1103">
                        <path
                            id="Path_1136"
                            data-name="Path 1136"
                            d="M45.045,2.388H56.99V7.166h4.778V8.36h2.389V5.478L58.679,0H45.045a2.391,2.391,0,0,0-2.389,2.389V8.361h2.389Z"
                            transform="translate(-41.462)"
                            fill="#2569e6"
                        />
                        <path
                            id="Path_1137"
                            data-name="Path 1137"
                            d="M61.768,406.506H45.045v-1.194H42.656v1.194a2.391,2.391,0,0,0,2.389,2.389H61.768a2.391,2.391,0,0,0,2.389-2.389v-1.194H61.768v1.194Z"
                            transform="translate(-41.462 -382.618)"
                            fill="#2569e6"
                        />
                        <path
                            id="Path_1138"
                            data-name="Path 1138"
                            d="M28.49,201.534v-2.389H24.906a1.2,1.2,0,0,1-1.194-1.194v-2.389a1.2,1.2,0,0,1,1.194-1.194H28.49v-2.389H24.906a3.588,3.588,0,0,0-3.583,3.583v2.389a3.588,3.588,0,0,0,3.583,3.583Z"
                            transform="translate(-21.323 -181.229)"
                            fill="#2569e6"
                        />
                        <path
                            id="Path_1139"
                            data-name="Path 1139"
                            d="M173.641,194.367h4.181v-2.389h-4.181a2.986,2.986,0,0,0,0,5.972h1.194a.6.6,0,1,1,0,1.194h-4.181v2.389h4.181a2.986,2.986,0,1,0,0-5.972h-1.194a.6.6,0,1,1,0-1.195Z"
                            transform="translate(-162.294 -181.229)"
                            fill="#2569e6"
                        />
                        <path
                            id="Path_1140"
                            data-name="Path 1140"
                            d="M324.7,191.979l-1.143,4.661-1.176-4.661h-2.389l2.345,9.556h2.458l-.025-.1,2.389-9.459Z"
                            transform="translate(-303.266 -181.23)"
                            fill="#2569e6"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </>
);
const globeIcon = () => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21.723"
            height="20"
            viewBox="0 0 28.582 28.582"
        >
            <path
                id="globe"
                d="M24.394,4.188a14.288,14.288,0,1,0,0,20.206A14.28,14.28,0,0,0,24.394,4.188Zm-.6,18.169a14.322,14.322,0,0,0-2.134-1.581,23.108,23.108,0,0,0,.862-5.575h4.2A12.354,12.354,0,0,1,23.8,22.357ZM1.857,15.2h4.2a23.108,23.108,0,0,0,.862,5.575,14.322,14.322,0,0,0-2.134,1.581A12.354,12.354,0,0,1,1.857,15.2ZM4.785,6.224A14.322,14.322,0,0,0,6.92,7.8a23.108,23.108,0,0,0-.862,5.575h-4.2A12.354,12.354,0,0,1,4.785,6.224Zm8.6,1.769A12.37,12.37,0,0,1,9.137,6.918c.772-1.988,2.2-4.372,4.244-4.963Zm0,1.824V13.38H7.88a21.453,21.453,0,0,1,.69-4.721A14.192,14.192,0,0,0,13.381,9.817Zm0,5.384v3.563A14.192,14.192,0,0,0,8.57,19.922,21.453,21.453,0,0,1,7.88,15.2Zm0,5.387v6.038c-2.049-.591-3.472-2.975-4.244-4.963A12.37,12.37,0,0,1,13.381,20.588Zm1.821,0a12.37,12.37,0,0,1,4.244,1.075c-.772,1.988-2.2,4.372-4.244,4.963Zm0-1.824V15.2h5.5a21.453,21.453,0,0,1-.69,4.721A14.192,14.192,0,0,0,15.2,18.764Zm0-5.384V9.817a14.192,14.192,0,0,0,4.811-1.158,21.453,21.453,0,0,1,.69,4.721Zm0-5.387V1.955c2.049.591,3.472,2.975,4.244,4.963A12.371,12.371,0,0,1,15.2,7.993Zm4.09-5.129a12.412,12.412,0,0,1,3.228,2.061,12.515,12.515,0,0,1-1.465,1.112,13.708,13.708,0,0,0-1.763-3.173ZM7.528,6.037A12.519,12.519,0,0,1,6.063,4.925,12.412,12.412,0,0,1,9.291,2.864,13.713,13.713,0,0,0,7.528,6.037Zm0,16.508a13.709,13.709,0,0,0,1.763,3.173,12.412,12.412,0,0,1-3.228-2.061A12.514,12.514,0,0,1,7.528,22.545Zm13.526,0a12.52,12.52,0,0,1,1.465,1.112,12.412,12.412,0,0,1-3.228,2.061,13.708,13.708,0,0,0,1.763-3.173Zm1.47-9.165A23.107,23.107,0,0,0,21.663,7.8,14.325,14.325,0,0,0,23.8,6.224a12.354,12.354,0,0,1,2.928,7.156Z"
                transform="translate(0 0.001)"
                fill="#2569e6"
            />
        </svg>
    </>
);
const settingIcon = () => (
    <>
        <svg
            id="setting_1_"
            data-name="setting (1)"
            xmlns="http://www.w3.org/2000/svg"
            width="21.723"
            height="20"
            viewBox="0 0 26.723 28"
        >
            <path
                id="Path_1130"
                data-name="Path 1130"
                d="M15.585,2.333l.448,1.946A2.33,2.33,0,0,0,17.391,5.9a8.109,8.109,0,0,1,1.766,1.033,2.336,2.336,0,0,0,2.133.376l1.884-.6,1.678,2.918L23.4,10.967a2.328,2.328,0,0,0-.738,1.96,10.105,10.105,0,0,1,0,2.147,2.332,2.332,0,0,0,.746,1.966l1.453,1.329-1.687,2.92-1.881-.6a2.336,2.336,0,0,0-2.133.376A8.109,8.109,0,0,1,17.391,22.1a2.337,2.337,0,0,0-1.359,1.624l-.447,1.946H12.231l-.448-1.946A2.333,2.333,0,0,0,10.424,22.1a8.063,8.063,0,0,1-1.766-1.032,2.334,2.334,0,0,0-2.13-.375l-1.884.6L2.965,18.374l1.454-1.341a2.328,2.328,0,0,0,.738-1.96,10.1,10.1,0,0,1,0-2.147,2.332,2.332,0,0,0-.745-1.966L2.96,9.631l1.687-2.92,1.881.6A2.336,2.336,0,0,0,8.66,6.935,8.109,8.109,0,0,1,10.426,5.9a2.333,2.333,0,0,0,1.358-1.623l.448-1.946Zm7.806,4.307H23.4ZM15.845,0H11.972a2.021,2.021,0,0,0-1.96,1.575l-.5,2.182a10.444,10.444,0,0,0-2.275,1.33L5.112,4.41a2.042,2.042,0,0,0-.6-.091,2.009,2.009,0,0,0-1.752,1L.807,8.692a2.042,2.042,0,0,0,.385,2.485l1.645,1.505a12.449,12.449,0,0,0,0,2.637L1.192,16.835A2.019,2.019,0,0,0,.819,19.32l1.925,3.348a2.045,2.045,0,0,0,2.368.922l2.123-.677a10.444,10.444,0,0,0,2.275,1.33l.5,2.182A2.021,2.021,0,0,0,11.972,28h3.873a2.021,2.021,0,0,0,1.96-1.575l.5-2.182a10.444,10.444,0,0,0,2.275-1.33l2.123.677a2.042,2.042,0,0,0,.6.091,2.009,2.009,0,0,0,1.752-1l1.948-3.372a2.023,2.023,0,0,0-.385-2.473v-.012L24.98,15.318a12.45,12.45,0,0,0,0-2.637l1.645-1.517A2.019,2.019,0,0,0,27,8.68L25.074,5.332a2.045,2.045,0,0,0-2.368-.922l-2.123.677a10.444,10.444,0,0,0-2.275-1.33l-.5-2.182A2.021,2.021,0,0,0,15.845,0Z"
                transform="translate(-0.547)"
                fill="#2569e6"
            />
            <path
                id="Path_1131"
                data-name="Path 1131"
                d="M9.875,14.471a.882.882,0,0,1,.07,1.237.878.878,0,0,1-1.237.07L5.792,13.153a.878.878,0,0,1,0-1.307L8.708,9.221a.876.876,0,1,1,1.167,1.307L7.682,12.5Z"
                transform="translate(0.278 1.5)"
                fill="#2569e6"
            />
            <path
                id="Path_1132"
                data-name="Path 1132"
                d="M13.976,9.073l-1.75,7.583a.867.867,0,0,1-1.05.653.88.88,0,0,1-.653-1.05l1.75-7.583a.874.874,0,1,1,1.7.4Z"
                transform="translate(1.112 1.334)"
                fill="#2569e6"
            />
            <path
                id="Path_1133"
                data-name="Path 1133"
                d="M18.875,13.153l-2.917,2.625a.878.878,0,0,1-1.237-.07.882.882,0,0,1,.07-1.237L16.985,12.5l-2.193-1.972a.876.876,0,1,1,1.167-1.307l2.917,2.625a.878.878,0,0,1,0,1.307Z"
                transform="translate(1.778 1.5)"
                fill="#2569e6"
            />
        </svg>
    </>
);

const ParsesIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="65.75"
        height="69.916"
        viewBox="0 0 65.75 69.916"
    >
        <g id="Verifications" transform="translate(10660 5349.666)">
            <path
                id="Path_3296"
                data-name="Path 3296"
                d="M-10793.5-5326.333l32.333-26.333,32.167,27-14.667,35.333h-35Z"
                transform="translate(134.5 3)"
                fill="#e0e0e0"
            />
            <g id="Group_7632" data-name="Group 7632">
                <g
                    id="Rectangle_1301"
                    data-name="Rectangle 1301"
                    transform="translate(-10652 -5344)"
                    fill="#fff"
                    stroke="#dbdbdb"
                    stroke-width="0.5"
                >
                    <rect width="51" height="58" stroke="none" />
                    <rect
                        x="0.25"
                        y="0.25"
                        width="50.5"
                        height="57.5"
                        fill="none"
                    />
                </g>
                <path
                    id="Path_3294"
                    data-name="Path 3294"
                    d="M-10641.429-5335.571h34.571"
                    transform="translate(-2.357 10.821)"
                    fill="none"
                    stroke="#dbdbdb"
                    stroke-width="0.5"
                />
                <path
                    id="Path_3295"
                    data-name="Path 3295"
                    d="M-10641.429-5335.571h40.144"
                    transform="translate(-4.429 18.643)"
                    fill="none"
                    stroke="#dbdbdb"
                    stroke-width="0.5"
                />
                <path
                    id="Path_3289"
                    data-name="Path 3289"
                    d="M-10793.75-5326l29.25,24-29.25,19.25Z"
                    transform="translate(134 3)"
                    fill="#ededed"
                />
                <path
                    id="Path_3290"
                    data-name="Path 3290"
                    d="M-10764.5-5326l-29.25,24,29.25,19.25Z"
                    transform="translate(170.25 3)"
                    fill="#ededed"
                />
                <path
                    id="Path_3291"
                    data-name="Path 3291"
                    d="M-10793.75-5282.75h65.5l-32.75-22.5Z"
                    transform="translate(133.75 3)"
                    fill="#e0e0e0"
                />
                <circle
                    id="Ellipse_112"
                    data-name="Ellipse 112"
                    cx="18.5"
                    cy="18.5"
                    r="18.5"
                    transform="translate(-10645 -5328)"
                    fill="#2569e6"
                />
                <path
                    id="Path_3292"
                    data-name="Path 3292"
                    d="M-10636.751-5314.337l5.359,5.084,12.5-12.5"
                    transform="translate(2.75 5.253)"
                    fill="none"
                    stroke="#fff"
                    stroke-width="3"
                />
                <path
                    id="Path_3293"
                    data-name="Path 3293"
                    d="M-10641.429-5335.571h20.2"
                    transform="translate(4.929 5)"
                    fill="none"
                    stroke="#dbdbdb"
                    stroke-width="0.5"
                />
            </g>
        </g>
    </svg>
);

const AdditionalLookupIcon = () => (
    <svg
        id="Additional_Lookups"
        data-name="Additional Lookups"
        xmlns="http://www.w3.org/2000/svg"
        width="40.5"
        height="40.499"
        viewBox="0 0 40.5 40.499"
    >
        <path
            id="Path_1485"
            data-name="Path 1485"
            d="M22.359,0H4.64A4.638,4.638,0,0,0,0,4.64V30.8a4.638,4.638,0,0,0,4.64,4.64H20.064a10.846,10.846,0,0,1-2.346-6.75,10.632,10.632,0,0,1,.54-3.375,10.257,10.257,0,0,1,1.2-2.531A11.07,11.07,0,0,1,27,17.853V4.64A4.638,4.638,0,0,0,22.359,0Z"
            fill="#2569e6"
        />
        <path
            id="Path_1486"
            data-name="Path 1486"
            d="M20.437,28.875a8.437,8.437,0,1,1,8.437-8.437A8.447,8.447,0,0,1,20.437,28.875Zm0-14.343a5.906,5.906,0,1,0,5.906,5.906A5.913,5.913,0,0,0,20.437,14.531Z"
            transform="translate(8.249 8.249)"
            fill="#2569e6"
        />
        <path
            id="Path_1487"
            data-name="Path 1487"
            d="M25.875,27.141a1.256,1.256,0,0,1-.894-.371L19.8,21.592a1.266,1.266,0,0,1,1.79-1.79l5.177,5.177a1.266,1.266,0,0,1-.9,2.162Z"
            transform="translate(13.359 13.358)"
            fill="#2569e6"
        />
    </svg>
);
const RemainingLookupIcon = () => (
    <svg
        id="Remaining_Lookups"
        data-name="Remaining Lookups"
        xmlns="http://www.w3.org/2000/svg"
        width="39"
        height="39"
        viewBox="0 0 39 39"
    >
        <path
            id="Path_1483"
            data-name="Path 1483"
            d="M26.812,14.625a12.142,12.142,0,0,1,8.937,3.929V4.062A4.062,4.062,0,0,0,31.687,0H4.062A4.062,4.062,0,0,0,0,4.062V18.687A4.062,4.062,0,0,0,4.062,22.75H15.337A12.2,12.2,0,0,1,26.812,14.625ZM3.25,4.014l14.625,6.825L32.5,4.014v2.7L18.736,13.13a2,2,0,0,1-1.722,0L3.25,6.711Z"
            fill="#2569e6"
        />
        <path
            id="Path_1484"
            data-name="Path 1484"
            d="M31.649,29.351,27.3,25A8.957,8.957,0,1,0,25,27.3l4.353,4.353a1.625,1.625,0,0,0,2.3-2.3Zm-17.4-9.414a5.687,5.687,0,1,1,5.687,5.688A5.694,5.694,0,0,1,14.25,19.937Z"
            transform="translate(6.875 6.875)"
            fill="#2569e6"
        />
    </svg>
);
const AddressInFileIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="37"
        height="40.333"
        viewBox="0 0 37 49.333"
    >
        <g
            id="Addresses_in_the_file"
            data-name="Addresses in the file"
            transform="translate(-449.75 -622.334)"
        >
            <path
                id="Subtraction_4"
                data-name="Subtraction 4"
                d="M18.5,49.333a1.542,1.542,0,0,1-1.025-.391C16.761,48.309,0,33.261,0,18.633a18.5,18.5,0,1,1,37,0C37,33.22,20.239,48.309,19.525,48.944A1.552,1.552,0,0,1,18.5,49.333ZM18.37,5.692A13.455,13.455,0,1,0,31.824,19.146,13.469,13.469,0,0,0,18.37,5.692Z"
                transform="translate(449.75 622.334)"
                fill="#2569e6"
            />
            <g
                id="home_3_"
                data-name="home (3)"
                transform="translate(459.982 634.246)"
            >
                <g
                    id="Group_1911"
                    data-name="Group 1911"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_1476"
                        data-name="Path 1476"
                        d="M71.579,120.939l-5.87,4.84a.139.139,0,0,1-.005.031.14.14,0,0,0-.005.03v4.9a.663.663,0,0,0,.653.654h3.92v-3.921h2.614v3.921h3.92a.664.664,0,0,0,.654-.654v-4.9a.144.144,0,0,0-.01-.061Z"
                        transform="translate(-63.349 -118.295)"
                        fill="#2569e6"
                    />
                    <path
                        id="Path_1477"
                        data-name="Path 1477"
                        d="M16.346,53.375,14.11,51.517V47.351a.315.315,0,0,0-.327-.327h-1.96a.314.314,0,0,0-.327.327v1.991L9.006,47.259a1.267,1.267,0,0,0-1.552,0L.113,53.375A.3.3,0,0,0,0,53.595a.331.331,0,0,0,.071.24l.633.756a.348.348,0,0,0,.214.112.379.379,0,0,0,.245-.071L8.23,48.74l7.065,5.891a.315.315,0,0,0,.214.071h.031a.349.349,0,0,0,.214-.112l.633-.755a.33.33,0,0,0,.071-.24A.3.3,0,0,0,16.346,53.375Z"
                        transform="translate(0.001 -46.994)"
                        fill="#2569e6"
                    />
                </g>
            </g>
        </g>
    </svg>
);

const userIcon = () => (
    <>
        <g id="man-user" transform="translate(-41.895)">
            <path
                id="Path_389"
                data-name="Path 389"
                d="M107.756,7.825c1.779,0,3.221-1.752,3.221-3.913S110.5,0,107.756,0s-3.221,1.752-3.221,3.913S105.977,7.825,107.756,7.825Z"
                transform="translate(-59.776)"
                fill="inherit"
            />
            <path
                id="Path_390"
                data-name="Path 390"
                d="M41.9,300.44c0-.132,0-.037,0,0Z"
                transform="translate(0 -286.641)"
            />
            <path
                id="Path_391"
                data-name="Path 391"
                d="M308.085,301.606c0-.036,0-.25,0,0Z"
                transform="translate(-254.021 -287.704)"
            />
            <path
                id="Path_392"
                data-name="Path 392"
                d="M54.069,183.256c-.06-3.764-.551-4.837-4.313-5.516a2.643,2.643,0,0,1-3.528,0c-3.721.672-4.242,1.728-4.311,5.394-.006.3-.008.315-.009.28,0,.065,0,.186,0,.4,0,0,.9,1.806,6.084,1.806s6.084-1.806,6.084-1.806c0-.135,0-.229,0-.293A2.422,2.422,0,0,1,54.069,183.256Z"
                transform="translate(-0.012 -169.615)"
                fill="inherit"
            />
        </g>
    </>
);

export {
    overviewIcon,
    developerIcon,
    bulkIcon,
    profileIcon,
    supportIcon,
    invoiceIcon,
    upgradeIcon,
    apiDocIcon,
    printMailIcon,
    logoutIcon,
    personIcon as PersonIcon,
    notificationIcon as NotificationIcon,
    infoIcon as InfoIcon,
    blogIcon,
    csvIcon,
    globeIcon,
    settingIcon,
    userIcon,
    ParsesIcon,
    AdditionalLookupIcon,
    AddressInFileIcon,
    RemainingLookupIcon,
};
