import React, { useRef, useImperativeHandle } from 'react';

import { InputBaseComponentProps } from '@material-ui/core/InputBase';

export type StripeInputProps = InputBaseComponentProps;

// This adapts Stripe card elements to work with Material UI
// by forwarding inputRef.
// https://medium.com/javascript-in-plain-english/stripe-payment-form-with-reactjs-and-material-ui-part-4-118e60fca962
const StripeInput = (props: InputBaseComponentProps) => {
    const { component: Component, inputRef, ...otherProps } = props;

    const elementRef = useRef<HTMLElement>();

    // When focus is called on inputRef, it should basically focus our stripe element
    useImperativeHandle(inputRef, () => ({
        focus: () => elementRef.current!.focus,
    }));

    return (
        <Component
            onReady={(element: any) => (elementRef.current = element)}
            {...otherProps}
        />
    );
};

export default StripeInput;
