import { useMemo } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';

export interface APIKey {
    createdAt: Date;
    enabled: boolean;
    live: boolean;
    lookups: number;
    verified: number;
    corrected: number;
    failed: number;
    name: string;
    value: string;
    public?: { origins: string[] };
}

interface CreateParams {
    name: string;
    live?: boolean;
    origins?: string[];
}

export type UpdateParams = Partial<CreateParams> & { enabled: boolean };

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async create(params: CreateParams) {
        return await this.base.fetchAPI<APIKey>('/api_keys', {
            method: 'POST',
            body: params,
        });
    }

    async list() {
        return await this.base.fetchAPI<APIKey[]>('/api_keys');
    }

    async update(value: string, params: UpdateParams) {
        return await this.base.fetchAPI<APIKey>(`/api_keys/${value}`, {
            method: 'PUT',
            body: params,
        });
    }

    async delete(value: string) {
        return await this.base.fetchAPI(`/api_keys/${value}`, {
            method: 'DELETE',
        });
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
