import React, { PropsWithChildren, useContext } from 'react';

import { Typography, Grid, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';

import {
    Context as notificationContext,
    MessageType,
} from '../context/Notification';

const useStyles = makeStyles({
    highlight: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
});

const CodeSection = (
    props: PropsWithChildren<{
        title: string | null;
    }>
) => (
    <Box
        py={0}
        style={{
            border: '1px #dae5ed solid',
            borderRadius: '10px',
            width: '100%',
            overflow: 'hidden',
        }}
    >
        {props.title ? (
            <Typography
                variant="body2"
                style={{
                    backgroundColor: '#D4E3FF',
                    padding: '8px 14px',
                    fontFamily: 'Roboto',
                    fontSize: '13px',
                }}
            >
                {props.title}
            </Typography>
        ) : null}
        <Box paddingX={2} py={1}>
            {props.children}
        </Box>
    </Box>
);

export const embedAPICodeGuide = (apiKey: string) => {
    return {
        steps: [
            {
                title: 'Step 1:',
                desc: 'Copy the following script onto your page and make sure it is after your form',
                explanation: null,
                code: [
                    {
                        title: 'If you are using the standard (US & Canada) API',
                        source: [
                            `<script src="https://scripts.postgrid.com/autocomplete.min.js" data-pg-key="${apiKey}" ></script>`,
                        ],
                    },
                    {
                        title: 'For the international API',
                        source: [
                            `<script src="https://scripts.postgrid.com/autocomplete.min.js" data-pg-international="true" data-pg-key="${apiKey}" ></script>`,
                        ],
                    },
                ],
            },

            {
                title: 'Step 2:',
                desc: 'Tell us where your form fields are',
                explanation:
                    'Locate the address <input> tags on your page and tell us what field they correspond to. For example:',
                code: [
                    {
                        title: 'Inputs',
                        source: [
                            '<input name="address" data-pg-address-line1 /> ',
                            '<input name="address2" data-pg-address-line2 /> ',
                            '<input name="city" data-pg-city /> ',
                            '<input name="prov" data-pg-prov /> ',
                            '<input name="postal" data-pg-pc /> ',
                            '<input name="country" data-pg-country />',
                        ],
                    },
                ],
                extra: (
                    <>
                        <Typography variant="caption">
                            You can put these data-pg-* attributes on any input
                            fields on your page. They do not need to be named in
                            any particular way.
                        </Typography>
                        <Box mt={1} />
                        <Typography variant="caption">
                            Once this is done, when a user types into the
                            data-pg-address-line1 field, we will provide
                            completions. When they select a result, we will fill
                            out the rest of the form automatically.
                        </Typography>
                    </>
                ),
            },
            {
                title: 'Step 3:',

                desc: 'Tell us where you want to show error information',
                explanation:
                    'If you want to show any errors (like missing unit number), you can do that as well with the following attributes.',
                code: [
                    {
                        title: null,
                        source: [
                            `<span class="error-message" data-pg-address-line1-message></span>`,
                        ],
                    },
                ],
                extra: (
                    <>
                        <Typography variant="body2" display="inline">
                            data-pg-address-line1-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : Error in address line 1
                        </Typography>
                        <br />
                        <Typography variant="body2" display="inline">
                            data-pg-address-line2-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : Error in address line 2
                        </Typography>
                        <br />
                        <Typography variant="body2" display="inline">
                            data-pg-city-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : Error in city
                        </Typography>
                        <br />
                        <Typography variant="body2" display="inline">
                            data-pg-prov-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : Error in province/state
                        </Typography>
                        <br />
                        <Typography variant="body2" display="inline">
                            data-pg-pc-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : Error in postal code/zip code
                        </Typography>
                        <br />
                        <Typography variant="body2" display="inline">
                            data-pg-country-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : Error in country
                        </Typography>
                        <br />
                        <Typography variant="body2" display="inline">
                            data-pg-generic-message
                        </Typography>
                        <Typography variant="caption">
                            {' '}
                            : General Error
                        </Typography>
                        <br />
                    </>
                ),
            },
            {
                title: 'Step 4:',

                desc: 'Enclose your input fields in a form tag and add attribute data-pg-verify to the form.',
                explanation: null,
                code: [
                    {
                        title: null,
                        source: [
                            `<form data-pg-verify>
                ...input fields
             </form>`,
                        ],
                    },
                ],
            },
            {
                title: 'Step 5:',

                desc: 'Add attribute data-pg-status to a span/div. The status of the address (corrected/failed/success) will be rendered inside this box.',
                explanation: null,
                code: [{ title: null, source: [`<div data-pg-status></div>`] }],
            },
        ],
        sampleCode: {
            title: 'Code',
            code: `
<html lang="en">
    <head>
        <style>
            .mt-3 {
                margin-top: 30px;
            }
            label {
                display: inline-block;
                min-width: 200px !important;
            }
            .wrapper {
                margin: 15px 0;
            }
            input[type='text'] {
                min-width: 300px;
                border: 1px solid transparent;
                background-color: #f1f1f1;
                padding: 10px;
                font-size: 16px;
            }
            .error > ul > li {
                color: red;
            }
            .generic-message > ul > li {
                color: red;
            }
        </style>
        <title>AutoComplete</title>
    </head>

    <body>
        <h1>Autocomplete Search</h1>
        <form 
            action="javascript:alert('Form Submitted')" 
            data-pg-verify
        >
            <div class="wrapper">
                <label for="line1">Line 1</label>
                <input type="text" id="line1" data-pg-address-line1></input>
                <div class="error" data-pg-address-line1-message></div>
            </div>
            <div class="wrapper">
                <label for="line2">Line 2</label>
                <input type="text" id="line2" data-pg-address-line2 />
                <div class="error" data-pg-address-line2-message></div>
            </div>
            <div class="wrapper">
                <label for="city">City</label>
                <input type="text" id="city" data-pg-city />
                <div class="error" data-pg-city-message></div>
            </div>
            <div class="wrapper">
                <label for="state">State/Province</label>
                <input type="text" id="state" data-pg-prov />
                <div class="error" data-pg-prov-message></div>
            </div>
            <div class="wrapper">
                <label for="zip">Zip/Postal Code</label>
                <input type="text" id="zip" data-pg-pc />
                <div class="error" data-pg-pc-message></div>
            </div>
            <div class="wrapper">
                <label for="country">Country</label>
                <input type="text" id="country" data-pg-country />
                <div class="error" data-pg-country-message></div>
            </div>
            <input type="submit" value="Submit" />
        </form>
        <div 
            class="generic-message"
            data-pg-generic-message>
        </div>
        <div data-pg-status></div>
        <script 
            src="https://scripts.postgrid.com/autocomplete.min.js"
            data-pg-key="${apiKey}"    
        ></script>
    </body>
</html>`,
        },
    };
};

const EmbedCode = ({ apiKey }: { apiKey: string }) => {
    const classes = useStyles();

    const { dispatch: notificationDispatch } = useContext(notificationContext);

    const copyToClipboard = (value: string) => {
        const textField = document.createElement('textarea');
        textField.innerHTML = value;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        notificationDispatch({
            type: MessageType.SUCCESS,
            message: 'Copied Successfully',
        });
    };

    const embedData = embedAPICodeGuide(apiKey);
    return (
        <Box pt={2}>
            <Typography>Embed Code</Typography>

            <Box mt={2} />

            <Grid
                container
                direction="row"
                spacing={2}
                style={{
                    marginBottom: '10px',
                    width: '100%',
                }}
            >
                <Grid item xs={6}>
                    <Grid container direction="column">
                        {embedData.steps.map((step, i) => (
                            <Grid container key={i}>
                                <Grid item key={i}>
                                    <Box display="flex">
                                        <Box flexShrink={0}>
                                            <Typography
                                                variant="body2"
                                                style={{ fontWeight: 500 }}
                                            >
                                                {step.title}
                                            </Typography>
                                        </Box>
                                        <Box ml={1} />
                                        <Box flexGrow={1}>
                                            <Typography variant="body2">
                                                {step.desc}
                                            </Typography>
                                            <Typography variant="caption">
                                                {step.explanation}
                                            </Typography>
                                            <Grid item xs={9}>
                                                <Box mt={1} />

                                                {step.code?.map((c: any) =>
                                                    c.source ? (
                                                        <>
                                                            <CodeSection
                                                                title={c.title}
                                                            >
                                                                {c.source.map(
                                                                    (
                                                                        src: any
                                                                    ) => (
                                                                        <>
                                                                            <code
                                                                                style={{
                                                                                    color: '#000',
                                                                                    textTransform:
                                                                                        'none',
                                                                                    fontFamily:
                                                                                        'roboto',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    src
                                                                                }
                                                                            </code>
                                                                            <br />
                                                                        </>
                                                                    )
                                                                )}
                                                            </CodeSection>
                                                            <Box mb={3} />
                                                        </>
                                                    ) : null
                                                )}
                                            </Grid>
                                            {step?.extra && (
                                                <>
                                                    {step.extra}
                                                    <Box mb={3} />
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid item>
                        <Typography>Sample Code</Typography>
                    </Grid>
                    <Grid item>
                        <Box mt={1} />
                    </Grid>
                    <CodeSection title={embedData.sampleCode.title}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <pre
                                    style={{
                                        color: '#000',
                                        textTransform: 'none',
                                        fontFamily: 'roboto',
                                        fontSize: '12px',
                                        marginTop: '0px',
                                    }}
                                >
                                    {embedData.sampleCode.code}
                                </pre>
                            </Grid>
                            <Grid item className={classes.highlight}>
                                <FileCopyIcon
                                    style={{
                                        color: '#8A97C2',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        copyToClipboard(
                                            embedData.sampleCode.code
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CodeSection>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmbedCode;
