import React, {
    useMemo,
    useEffect,
    useState,
    createContext,
    useReducer,
    useContext,
} from 'react';
import { useLocation } from 'react-router-dom';
// Contexts/Services
import { useAuthContext } from '../context/Auth';
import { useService as useEmbeddedSecretService } from '../services/EmbeddedSecrets';

const useInitialQuery = () => {
    const { search } = useLocation();

    // We only want this to run initially
    // As we navigate to different pages, the query will update
    // and the initial query the user passed will not be present.
    // eslint-disable-next-line
    return useMemo(() => new URLSearchParams(search), []);
};

interface EmbedConfig {
    hideInternationalVerification: boolean;
}

interface State {
    loading: boolean;
    error: string;
    config: EmbedConfig;
}

const INITIAL_CONFIG: EmbedConfig = {
    hideInternationalVerification: false,
};

const Context = createContext<State | null>(null);

interface EmbedProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

// Returns the configuration for the current embed session
const useEmbedConfig = (query: URLSearchParams) => {
    const [config, setConfig] = useReducer(
        (current: EmbedConfig, update: Partial<EmbedConfig>) => ({
            ...current,
            ...update,
        }),
        INITIAL_CONFIG
    );

    // Configs
    useEffect(() => {
        const hideChatQuery = query.get('hideChat') === 'true';
        const hideInternationalVerificationQuery =
            query.get('hideInternationalVerification') === 'true';

        // Remove intercom
        if (hideChatQuery) {
            // From https://stackoverflow.com/questions/57388730/how-to-hide-the-intercom-chat-widget-in-specific-pages-in-a-single-page-applicat
            if (window.Intercom) {
                window.Intercom('update', { hide_default_launcher: true });
            }
        }

        if (hideInternationalVerificationQuery) {
            setConfig({ hideInternationalVerification: true });
        }
    }, [query]);

    return config;
};

// This provider handles authenticating the embedded feature(s) and setting up
// the configuration for them.
const EmbedAuthProvider = ({ children }: EmbedProviderProps) => {
    const { state } = useAuthContext();
    const service = useEmbeddedSecretService();
    const query = useInitialQuery();
    const config = useEmbedConfig(query);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        // If the user has an embedded secret attached, we know we have currently
        // authenticated for this session.
        if (state.user?.embeddedSecret) {
            setLoading(false);
            setError('');
            return;
        }

        const userID = query.get('id');
        const secret = query.get('secret');

        if (!userID || !secret) {
            setError(
                'Invalid query parameters. Ensure you are correctly passing your user ID and secret.'
            );
            setLoading(false);
            return;
        }

        let isMounted = true;

        const authenticate = async () => {
            setError('');
            setLoading(true);

            try {
                await service.authenticate(userID, secret);

                if (!isMounted) {
                    return;
                }

                setLoading(false);
            } catch (err: any) {
                if (isMounted) {
                    setError(err.message);
                    setLoading(false);
                }
            }
        };

        authenticate();

        return () => {
            isMounted = false;
        };
    }, [query, service, state]);

    return (
        <Context.Provider value={{ error, loading, config }}>
            {children}
        </Context.Provider>
    );
};

export const useEmbedAuth = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            '`useEmbedAuth` can only be used within an <EmbedAuthProvider />'
        );
    }

    return context;
};

export default EmbedAuthProvider;
