import React from 'react';

import { useBulkVerifyStepsContext } from '../context/BulkVerifyStepsContext';

import { FieldMappingProps } from './FieldMapping';
import FieldMap from './FieldMap';
import FieldMapInputContainer from './FieldMapInputContainer';
import CountrySelector from './CountrySelector';
import HelpToolTipIcon from './HelpToolTipIcon';

const DefaultCountryToolTip = (
    <HelpToolTipIcon title="This is used if your country column is missing or if your address is missing a country." />
);

const CountryFieldMapping: React.FC<FieldMappingProps> = ({ title, value }) => {
    const {
        inputMapping,
        setInputMapping,
        defaultCountry,
        setDefaultCountry,
        international,
    } = useBulkVerifyStepsContext();

    const handleSelect = (e: React.ChangeEvent<{ value: any }>) => {
        setInputMapping({
            ...inputMapping,
            [value]: e.target.value,
        });
    };

    return (
        <>
            <FieldMap
                title={title}
                value={value}
                onSelectChange={handleSelect}
            />
            <FieldMapInputContainer
                title="Default Country"
                toolTip={DefaultCountryToolTip}
            >
                <CountrySelector
                    countryCode={defaultCountry}
                    setCountryCode={setDefaultCountry}
                    showInternationalCountries={international}
                />
            </FieldMapInputContainer>
        </>
    );
};

export default CountryFieldMapping;
