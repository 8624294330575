import React, { useState } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export type HideableTextFieldProps = TextFieldProps & {
    showInitially?: boolean;
};

const HideableTextField: React.FC<HideableTextFieldProps> = ({
    InputProps,
    type,
    showInitially = false,
    ...props
}) => {
    const [hide, setHide] = useState(!showInitially);

    const toggleHide = () => {
        setHide((prev) => !prev);
    };

    return (
        <TextField
            InputProps={{
                ...InputProps,
                endAdornment: (
                    <>
                        <IconButton onClick={toggleHide} size="small">
                            {hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                        {InputProps?.endAdornment}
                    </>
                ),
            }}
            type={hide ? 'password' : type}
            {...props}
        />
    );
};

export default HideableTextField;
