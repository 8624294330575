import React from 'react';
// Contexts
import BulkVerifyStepsProvider from '../context/BulkVerifyStepsContext';
import { useEmbedAuth } from '../context/EmbedAuth';
// Components
import { BulkVerifySteps } from './BulkVerifySteps';
import EmbedWrapper from '../components/EmbedWrapper';

const EmbeddedBulkVerify = () => {
    const { config } = useEmbedAuth();
    return (
        <EmbedWrapper>
            <BulkVerifyStepsProvider
                bulkVerifyListRoute="/embed/bulk_verify"
                hideInternationalVerification={
                    config.hideInternationalVerification
                }
            >
                <BulkVerifySteps />
            </BulkVerifyStepsProvider>
        </EmbedWrapper>
    );
};

export default EmbeddedBulkVerify;
