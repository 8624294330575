import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TextField, { TextFieldProps } from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '7px',
            '& fieldset': {
                borderColor: '#D1D1D1',
            },
        },
        '& .MuiInputBase-root': {
            fontWeight: 300,
        },
    },
}));

const Field = (
    props: TextFieldProps & {
        setValue: (s: string) => void;
    }
) => {
    const { setValue, ...otherProps } = props;

    const classes = useStyles();

    return (
        <TextField
            variant="outlined"
            fullWidth
            onChange={(e) => {
                setValue(e.target.value);
            }}
            {...otherProps}
            classes={classes}
        />
    );
};

export default Field;
