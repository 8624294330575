import React, {
    memo,
    useEffect,
    useContext,
    createContext,
    useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import copy from 'copy-to-clipboard';

// Context/Hooks/Services
import { useNotifcationContext } from '../context/Notification';
import { useAuthContext } from '../context/Auth';
import {
    useService as useEmbeddedSecretService,
    EmbeddedSecret as IEmbeddedSecret,
} from '../services/EmbeddedSecrets';
// Images
import deleteGraphic from '../img/delete-graphic.png';
// MUI Components
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
// Our Components
import Button from './Button';
import HideableTextField from './HideableTextField';
import ConfirmActionDialog from './ConfirmActionDialog';

interface IContext {
    secret: IEmbeddedSecret | undefined | null;
    setSecret: React.Dispatch<
        React.SetStateAction<IEmbeddedSecret | undefined | null>
    >;
}

const Context = createContext<IContext | null>(null);

const useEmbedContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useEmbedContext can only be used within a <EmbeddedIntegrations /> component.'
        );
    }

    return context;
};

const useEmbeddedSecret = (allowEmbedding?: boolean) => {
    const service = useEmbeddedSecretService();
    const [secret, setSecret] = useState<IEmbeddedSecret | null>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!allowEmbedding) {
            return;
        }

        let isMounted = true;

        const fetchSecret = async () => {
            try {
                setLoading(false);
                const sec = await service.get();

                if (!isMounted) {
                    return;
                }

                setSecret(sec);
                setLoading(false);
            } catch (err: any) {
                // The `BaseService` already displays errors. Don't need to
                // show them a second time.
                console.error(err);
                setSecret(undefined);
                setLoading(false);
            }
        };

        fetchSecret();

        return () => {
            isMounted = false;
        };
    }, [service, allowEmbedding]);

    return { secret, setSecret, loading };
};

const useStyles = makeStyles({
    paperRoot: {
        padding: '1.3rem 2rem',
    },
    pointer: {
        cursor: 'pointer',
    },
});

const Loader = () => {
    return (
        <Grid
            container
            justify="center"
            alignItems="center"
            style={{ minHeight: '5vh' }}
        >
            <CircularProgress />
        </Grid>
    );
};

interface ContentProps {
    title: string;
    content: React.ReactNode;
    action: React.ReactNode;
}

const Content = (props: ContentProps) => {
    return (
        <Box
            padding={4}
            border={1}
            borderRadius={10}
            borderColor="#d3d3d3"
            bgcolor="#fcfcfc"
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
            >
                <Grid item container direction="column" xs={7} spacing={2}>
                    <Grid item container spacing={1} direction="column">
                        <Grid item>
                            <Typography variant="h6">{props.title}</Typography>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item>{props.content}</Grid>
                </Grid>
                <Grid item xs={4}>
                    {props.action}
                </Grid>
            </Grid>
        </Box>
    );
};

const EmbeddedSecret = () => {
    const { secret, setSecret } = useEmbedContext();
    const { dispatchSuccess } = useNotifcationContext();
    const { state: authState } = useAuthContext();
    const service = useEmbeddedSecretService();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    // Should never hit if you use the component correctly.
    if (!secret) {
        return (
            <Grid container>
                <Grid item>
                    <Typography color="secondary">
                        No secret available.
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    const retire = async () => {
        try {
            setLoading(true);
            await service.retire(secret.id);
            dispatchSuccess('Secret retired successfully.');
            setSecret(null);
        } catch (err: any) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <ConfirmActionDialog
                open={isOpen}
                onClose={toggleOpen}
                title="Retire Secret"
                text="Are you sure you want to retire your secret? All applications using this secret will no longer be authenticated."
                actionLabel="Retire"
                image={deleteGraphic}
                confirm={retire}
            />
            <Content
                title="Currently Active Secret"
                content={
                    <>
                        <Typography>
                            This is your secret and user ID combination. Use
                            this in your iframe URLs to authenticate yourself
                            while embedding PostGrid Address Verification
                            services into your application. If your secret has
                            been compromised you can retire your secret to
                            restrict access and create a new secret.
                        </Typography>
                        <Typography>
                            <br />
                            <strong>
                                Be sure not to share this secret with others.
                            </strong>
                        </Typography>
                    </>
                }
                action={
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <HideableTextField
                                value={secret.id}
                                variant="outlined"
                                label="Secret"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HideableTextField
                                value={authState.user?.id}
                                variant="outlined"
                                label="User ID"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    copy(
                                        `${window.location.origin}/embed/bulk_verify?id=${authState.user?.id}&secret=${secret.id}`
                                    );
                                    dispatchSuccess('Copied embed URL.');
                                }}
                                fullWidth
                            >
                                Copy URL
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={toggleOpen}
                                disabled={loading}
                                fullWidth
                            >
                                Retire
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
        </>
    );
};

const CreateSecret = () => {
    const service = useEmbeddedSecretService();
    const { setSecret } = useEmbedContext();
    const [loading, setLoading] = useState(false);

    const createSecret = async () => {
        try {
            setLoading(true);
            const secret = await service.create();
            setSecret(secret);
        } catch (err: any) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Content
            title="You do not have a secret"
            content={
                <Typography>
                    Create a secret to start embedding Address Verification
                    services.
                </Typography>
            }
            action={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={createSecret}
                    fullWidth
                    disabled={loading}
                >
                    Create new secret
                </Button>
            }
        />
    );
};

const EmbeddingNotEnabled = () => {
    const history = useHistory();

    const handleClick = () => {
        history.push('/dashboard/support');
    };

    return (
        <Content
            title="Embedding Not Enabled"
            content={
                <Typography>
                    Your organization is not permitted to embed our services. To
                    enable this feature you can reach out to our support team.
                </Typography>
            }
            action={
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    role="link"
                    fullWidth
                >
                    Contact Support
                </Button>
            }
        />
    );
};

interface EmbeddedIntegrationsProps {
    allowEmbedding?: boolean;
}

const EmbeddedIntegrations = memo((props: EmbeddedIntegrationsProps) => {
    const classes = useStyles();
    const { secret, setSecret, loading } = useEmbeddedSecret(
        props.allowEmbedding
    );

    return (
        <Context.Provider value={{ secret, setSecret }}>
            <Paper className={classes.paperRoot}>
                <Grid container direction="column" spacing={4}>
                    <Grid item container spacing={1} direction="column">
                        <Grid item>
                            <Typography variant="h6">Embed Secrets</Typography>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" spacing={2}>
                        <Grid item>
                            <Typography>
                                You can embed our bulk verification pages into
                                your website. To do so, you must first create a
                                secret which will be used to authenticate your
                                integration. For a list of integrations and
                                steps for integrating, check out our guides.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container alignContent="center" justify="center">
                        <Grid item xs={4}>
                            {/* TODO(Apaar): Link directly to a relevant guide */}
                            <Button
                                variant="outlined"
                                color="primary"
                                role="link"
                                onClick={() => {
                                    window.open(
                                        'https://postgrid.readme.io/reference/bulk-verification-embed',
                                        '_blank'
                                    );
                                }}
                                fullWidth
                            >
                                View Guide
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {!props.allowEmbedding ? (
                            <EmbeddingNotEnabled />
                        ) : loading ? (
                            <Loader />
                        ) : secret ? (
                            <EmbeddedSecret />
                        ) : (
                            <CreateSecret />
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Context.Provider>
    );
});

export default EmbeddedIntegrations;
