import { useContext, useMemo, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
    Context as AuthContext,
    State as AuthState,
    ActionType,
    LoginPayload,
} from '../context/Auth';
import {
    MessageType,
    Action as NotificationAction,
    Context as NotificationContext,
} from '../context/Notification';

import { APIRequestError, APIRequestInit, fetchAPI } from './util';

export class Service {
    history: ReturnType<typeof useHistory>;
    notify: (action: NotificationAction) => void;
    logout: () => void;
    tempLogin: (payload: LoginPayload) => void;
    authState?: AuthState;

    constructor(
        history: Service['history'],
        notify: Service['notify'],
        logout: () => void,
        tempLogin: (payload: LoginPayload) => void,
        authState?: AuthState
    ) {
        this.history = history;
        this.notify = notify;
        this.logout = logout;
        this.tempLogin = tempLogin;
        this.authState = authState;
    }

    // TODO Make generic 'fetch' wrapper here which handles errors as well
    async internalFetchAPI(
        path: string,
        init?: APIRequestInit & {
            allow404?: boolean;
        },
        prevErr?: Error
    ): Promise<any> {
        if (this.authState && this.authState.token) {
            init = {
                ...init,
                headers: {
                    ...init?.headers,
                    Authorization: `Bearer ${this.authState.token}`,
                },
            };
        }

        try {
            return await fetchAPI(path, init);
        } catch (err: any) {
            // If we had a previous error, we do not re-do the request as this
            // will lead to infinite requests
            if (prevErr) {
                throw prevErr;
            }

            if (err instanceof APIRequestError) {
                // If they have an embedded secret and they got logged out
                // we will try to redo the request for them again after authenticating.
                if (
                    err.status === 401 &&
                    this.authState?.user?.embeddedSecret
                ) {
                    const userID = this.authState.user.id;
                    const secret = this.authState.user.embeddedSecret;
                    const payload = await this.fetchAPI<LoginPayload>(
                        `/users/${userID}/embed_secrets/${secret}/sessions/`,
                        { method: 'POST' }
                    );

                    Object.assign(payload.user, { embeddedSecret: secret });
                    this.tempLogin(payload);

                    return await this.fetchAPI(path, init, err);
                }

                if (err.status === 401 || err.status === 403) {
                    this.logout();
                    this.history.push('/login');
                }

                if (init && init.allow404 && err.status === 404) {
                    return null;
                }

                if (err.status === 503) {
                    this.history.push(`/maintenance?msg=${err.message}`);
                }
            }

            this.notify({
                type: MessageType.ERROR,
                message: err.message,
            });

            throw err;
        }
    }

    async fetchAPI<T>(
        path: string,
        init?: APIRequestInit,
        prevErr?: Error
    ): Promise<T> {
        return this.internalFetchAPI(path, init, prevErr);
    }

    async fetchAPIAllow404<T>(
        path: string,
        init?: APIRequestInit
    ): Promise<T | null> {
        return this.internalFetchAPI(path, {
            ...init,
            allow404: true,
        });
    }
}

export const useService = () => {
    const history = useHistory();

    const { dispatch } = useContext(NotificationContext);
    const {
        state,
        dispatch: authDispatch,
        dispatchTempLogin,
    } = useContext(AuthContext);

    const logout = useCallback(() => {
        authDispatch({
            type: ActionType.LOGOUT,
        });
    }, [authDispatch]);

    return useMemo(
        () => new Service(history, dispatch, logout, dispatchTempLogin, state),
        [dispatch, history, state, logout, dispatchTempLogin]
    );
};
