import React, { PropsWithChildren, useContext } from 'react';

import { Typography, Grid, Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';

import {
    Context as notificationContext,
    MessageType,
} from '../context/Notification';

const useStyles = makeStyles({
    highlight: {
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
});

const CodeSection = (
    props: PropsWithChildren<{
        title: string;
    }>
) => (
    <Box
        py={0}
        style={{
            border: '1px #dae5ed solid',
            borderRadius: '10px',
            width: '100%',
            overflow: 'hidden',
        }}
    >
        <Typography
            variant="body2"
            style={{
                backgroundColor: '#D4E3FF',
                padding: '8px 14px',
                fontFamily: 'Roboto',
                fontSize: '13px',
            }}
        >
            {props.title}
        </Typography>
        <Box paddingX={2} py={1}>
            {props.children}
        </Box>
    </Box>
);

export const embedAPICodeGuide = (apiKey: string) => {
    return {
        steps: [
            {
                title: 'Step 1:',
                desc: 'Click the View API Documentation button above to access the docs',
                explanation:
                    'There are many endpoints for different purposes. For example, we offer a batch verification endpoint which lets you verify up to 2000 addresses at once.',
                code: null,
            },
            {
                title: 'Step 2:',
                desc: 'Make API calls and confirm they work correctly',
                explanation:
                    'Use the 500 free lookups to try out your integration and make sure everything works correctly. Access the support page if you need assistance with this step.',
                code: null,
            },
            {
                title: 'Step 3:',
                desc: 'Subscribe to an appropriate plan and go live',
                explanation:
                    'Subscribe to one of our API plans to unlock more lookups. Your usage will automatically reset every month. If you want a custom plan catered to your needs, please Contact Us.',
                code: null,
            },
        ],
        sampleCode: {
            title: 'Code',
            code: `    
    // Verify an address using JavaScript and Axios.
    // View API Documentation for examples in other languages.

    axios.post('https://api.postgrid.com/v1/addver/verifications', {
        address: {
            line1: '22-20 bay st',
            city: 'Toronto',
        }
    }, {
        headers: {
            'x-api-key': '${apiKey}'
        }
    }).then((res) => {
        const { data } = res.data;

        // All the missing information is automatically appended.
        console.log(data.status);

        console.log(data.line1);
        console.log(data.line2);
        console.log(data.city);
        console.log(data.provinceOrState);
        console.log(data.postalOrZip);
        console.log(data.country);
    }).catch(console.error);`,
        },
    };
};

const EmbedCode = ({ apiKey }: { apiKey: string }) => {
    const classes = useStyles();

    const { dispatch: notificationDispatch } = useContext(notificationContext);

    const copyToClipboard = (value: string) => {
        const textField = document.createElement('textarea');
        textField.innerHTML = value;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        notificationDispatch({
            type: MessageType.SUCCESS,
            message: 'Copied Successfully',
        });
    };

    const embedData = embedAPICodeGuide(apiKey);
    return (
        <Box pt={2}>
            <Typography>Embed Code</Typography>

            <Box mt={2} />

            <Grid
                container
                direction="row"
                spacing={2}
                style={{
                    marginBottom: '10px',
                    width: '100%',
                }}
            >
                <Grid item xs={6}>
                    <Grid container spacing={2} direction="column">
                        {embedData.steps.map((step, i) => (
                            // <Grid container  key={i}>
                            <Grid item key={i}>
                                <Box display="flex">
                                    <Box flexShrink={0}>
                                        <Typography
                                            variant="body2"
                                            style={{ fontWeight: 500 }}
                                        >
                                            {step.title}
                                        </Typography>
                                    </Box>
                                    <Box ml={1} />
                                    <Box flexGrow={1}>
                                        <Typography variant="body2">
                                            {step.desc}
                                        </Typography>
                                        <Typography variant="caption">
                                            {step.explanation}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            // </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Grid item>
                        <Typography>Sample Code</Typography>
                    </Grid>
                    <Grid item>
                        <Box mt={1} />
                    </Grid>
                    <CodeSection title={embedData.sampleCode.title}>
                        <Grid
                            container
                            justify="space-between"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <pre
                                    style={{
                                        color: '#000',
                                        textTransform: 'none',
                                        fontFamily: 'roboto',
                                        fontSize: '12px',
                                        marginTop: '0px',
                                    }}
                                >
                                    {embedData.sampleCode.code}
                                </pre>
                            </Grid>
                            <Grid item className={classes.highlight}>
                                <FileCopyIcon
                                    style={{
                                        color: '#8A97C2',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        copyToClipboard(
                                            embedData.sampleCode.code
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CodeSection>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EmbedCode;
