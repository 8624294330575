import { useMemo } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';
import { Organization } from './Organization';

export enum BulkListStatus {
    PENDING = 'pending',
    PROCESSED = 'processed',
    FORBIDDEN = 'forbidden',
    FAILED = 'failed',
    PAYMENT_FAILED = 'payment_failed',
}

export interface BulkListMapping {
    line1: string;
    line2?: string;
    city?: string;
    provinceOrState?: string;
    postalOrZip?: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
}

interface verificationStatus {
    verified: number;
    corrected: number;
    failed: number;
}

interface intlVerificationStatus {
    verified: number;
    partially_verified: number;
    unverified: number;
    ambiguous: number;
    conflict: number;
    reverted: number;
}

export interface BulkList {
    id: string;

    name: string;

    result: string;

    count: number;

    createdAt: Date;
    updatedAt: Date;

    status: BulkListStatus;

    errorMessage?: string;

    mappings: BulkListMapping;

    metadata: {
        statusCount: verificationStatus | intlVerificationStatus;
    };
}

export interface BulkListEstimate {
    cost: number;
    unitCost: number;
    ncoaCost?: number;
    prices: {
        threshold: number;
        pricePerAddress: number;
    }[];
}

export interface CreditPrices {
    prices: BulkListEstimate['prices'];
}

interface ListParams {
    skip?: number;
    limit?: number;
}

interface CreateParams {
    name: string;
    useIntlVerification: boolean;
    useGeocode: boolean;
    runNCOA: boolean;
    useProperCase: boolean;
    mappings: BulkListMapping;
    file: File;
    defaultCountry?: string;
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async list(data: ListParams) {
        const params = new URLSearchParams();

        params.set('skip', (data.skip || 0).toString());
        params.set('limit', (data.limit || 10).toString());

        return await this.base.fetchAPI<{
            data: BulkList[];
            count: number;
        }>(`/addver_lists?${params.toString()}`);
    }

    async create(params: CreateParams) {
        const data = new FormData();

        data.set('name', params.name);
        data.set('useIntlVerification', params.useIntlVerification.toString());
        data.set('useGeocode', params.useGeocode.toString());
        data.set('runNCOA', params.runNCOA.toString());
        data.set('useProperCase', params.useProperCase.toString());

        if (params.defaultCountry) {
            data.set('defaultCountry', params.defaultCountry);
        }

        for (const [key, value] of Object.entries(params.mappings)) {
            if (value) {
                data.set(`mappings[${key}]`, value);
            }
        }

        data.set('file', params.file);
        return await this.base.fetchAPI<BulkList>('/addver_lists', {
            method: 'POST',
            body: data,
        });
    }

    async getCreditPrices(useIntlVerification: boolean): Promise<CreditPrices> {
        return await this.base.fetchAPI<CreditPrices>(
            `/addver_lists/credit_prices?useIntlVerification=${useIntlVerification}`
        );
    }

    async getCreditUnitCost(
        count: number,
        useIntlVerification: boolean
    ): Promise<number> {
        const prices = (await this.getCreditPrices(useIntlVerification)).prices;

        let cost = 0;

        for (const price of prices) {
            if (count < price.threshold) {
                cost = price.pricePerAddress;
                break;
            }
        }

        if (cost === 0) {
            cost = prices[prices.length - 1].pricePerAddress;
        }

        return cost;
    }

    async addCredits(count: number, international: boolean) {
        return await this.base.fetchAPI<{ limit: Organization['limit'] }>(
            '/addver_lists/credits',
            {
                method: 'POST',
                body: {
                    count,
                    useIntlVerification: international,
                },
            }
        );
    }

    async estimate(
        count: number,
        useIntlVerification: boolean,
        useGeocode = false,
        runNCOA = false
    ) {
        return await this.base.fetchAPI<BulkListEstimate>(
            `/addver_lists/estimates?count=${count}&useIntlVerification=${useIntlVerification}&useGeocode=${useGeocode}&runNCOA=${runNCOA}`
        );
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
