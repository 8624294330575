import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';

interface HelpToolTipIconProps {
    title: string;
}

const HelpToolTipIcon: React.FC<HelpToolTipIconProps> = ({ title }) => {
    return (
        <Tooltip title={title}>
            <IconButton size="small">
                <HelpIcon />
            </IconButton>
        </Tooltip>
    );
};

export default HelpToolTipIcon;
