import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Grid, { GridProps } from '@material-ui/core/Grid';

const useStyle = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        padding: '20px 35px',
        borderRadius: '5px',
        boxShadow: '0px 1px 2px 1px rgba(232,232,232,0.68)',
        position: 'relative',
    },
}));

const GridPaper = (props: GridProps) => {
    const classes = useStyle();

    return <Grid container {...props} classes={{ root: classes.root }} />;
};

export default GridPaper;
