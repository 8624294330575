import React, { useContext, useEffect, useState } from 'react';

import { LocalFile, parse, ParseStepResult } from 'papaparse';

import {
    StepTitles,
    useBulkVerifyStepsContext,
} from '../context/BulkVerifyStepsContext';

import { BulkListMapping } from '../services/BulkLists';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core';

import Button from './Button';
import FieldMapping from './FieldMapping';
import { Context, MessageType } from '../context/Notification';
import BulkStepLoadingButton from './BulkStepLoadingButton';
import useCSVHeuristics from '../hooks/useCSVHeuristics';
import BulkRowSamples from './BulkRowSamples';

export const ADDRESS_FIELDS = {
    'Address Line 1': 'line1',
    'Address Line 2': 'line2',
    City: 'city',
    'Postal/ZIP Code': 'postalOrZip',
    'Province or State': 'provinceOrState',
    Country: 'country',
} as const;

export const NCOA_ADDRESS_FIELDS = {
    'First Name': 'firstName',
    'Last Name': 'lastName',
    'Address Line 1': 'line1',
    'Address Line 2': 'line2',
    City: 'city',
    'Postal/ZIP Code': 'postalOrZip',
    'Province or State': 'provinceOrState',
    Country: 'country',
} as const;

const US_CA_NAMES = [
    'Canada',
    'CAN',
    'CA',
    'United States',
    'United States of America',
    'US',
    'USA',
].map((x) => x.toLowerCase());

const onlyLine1Present = (mapping: Partial<BulkListMapping>) => {
    if (mapping.line2) return false;
    if (mapping.city) return false;
    if (mapping.postalOrZip) return false;
    if (mapping.provinceOrState) return false;
    if (mapping.country) return false;
    if (!mapping.line1) return false;
    return true;
};

const hasCountry = (
    inputMapping: Partial<BulkListMapping>,
    fileHeaders: string[],
    defaultCountry: string
) => {
    if (inputMapping.country) {
        return true;
    }

    if (fileHeaders.includes(ADDRESS_FIELDS.Country)) {
        return true;
    }

    if (defaultCountry) {
        return true;
    }

    return false;
};

const doCountryValuesExist = async (
    file: File,
    mapping: string = ADDRESS_FIELDS.Country
) => {
    return new Promise((resolve) => {
        parse(file as LocalFile, {
            skipEmptyLines: true,
            header: true,
            step: (row: ParseStepResult<Record<string, string>>) => {
                if (!row.data[mapping]) {
                    resolve(false);
                }
            },
            complete: () => {
                resolve(true);
            },
        });
    });
};

const doInternationalCountriesExist = async (
    file: File,
    inputMapping: Partial<BulkListMapping>
): Promise<boolean> => {
    if (!inputMapping.country) {
        return false; // no country mapping so international addresses are not possible
    }
    return new Promise((resolve) => {
        // check country column for international addresses
        parse(file as LocalFile, {
            skipEmptyLines: true,
            header: true,
            step: (row: ParseStepResult<Record<string, string>>, parser) => {
                if (
                    row.data[inputMapping.country!].trim() &&
                    !US_CA_NAMES.includes(
                        row.data[inputMapping.country!].trim().toLowerCase()
                    )
                ) {
                    resolve(true);
                    parser.abort(); // do not parse further upon encountering international address
                    return;
                }
            },
            complete: () => {
                resolve(false);
            },
        });
    });
};

const WarningTopText = withStyles({
    root: {
        color: '#e08702',
        fontWeight: 'bold',
    },
})(Typography);

const WarningText = withStyles({
    root: {
        color: '#FF9800',
    },
})(Typography);

const Step2 = () => {
    const {
        file,
        inputMapping,
        fileHeaders,
        international,
        setInternational,
        setUseGeocode,
        runNCOA,
        setRunNCOA,
        setUseProperCase,
        setActiveStep,
        defaultCountry,
        setDefaultCountry,
    } = useBulkVerifyStepsContext();
    const { loadingHeuristics } = useCSVHeuristics();

    const { dispatch } = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [internationalPresentLoading, setInternationalPresentLoading] =
        useState(false);
    const [internationalPresent, setInternationalPresent] = useState(false);
    const [internationalOptIn, setInternationalOptIn] = useState(false);

    const onNext = async () => {
        setLoading(true);

        // If there is _only_ line1, assume it has a country and skip
        // country validation
        if (!onlyLine1Present(inputMapping)) {
            if (!hasCountry(inputMapping, fileHeaders, defaultCountry)) {
                dispatch({
                    type: MessageType.ERROR,
                    message:
                        'No country column was mapped.  Please select a default country or supply a country column mapping.',
                });
                setLoading(false);
                return;
            }

            const areCountriesPresent = await doCountryValuesExist(
                file!,
                inputMapping.country
            );

            if (!areCountriesPresent && !defaultCountry) {
                dispatch({
                    type: MessageType.ERROR,
                    message:
                        "Missing 'country' mapped values detected.  Please ensure all records have a 'country' value or set a default value.",
                });

                setLoading(false);
                return;
            }
        }

        if (runNCOA && (!inputMapping.firstName || !inputMapping.lastName)) {
            dispatch({
                type: MessageType.ERROR,
                message:
                    'Please provide mappings for at least one of: "First Name and Last Name"',
            });

            setLoading(false);
            return;
        }

        if (internationalPresent && internationalOptIn) {
            setInternational(true);
        }

        setLoading(false);
        setActiveStep(StepTitles.PREVIEW_AND_IMPORT);
    };

    const onPrev = () => {
        setInternationalPresentLoading(false);
        setInternationalPresent(false);
        setInternationalOptIn(false);
        setInternational(false);
        setUseGeocode(false);
        setRunNCOA(false);
        setUseProperCase(false);
        setDefaultCountry('');
        setActiveStep(StepTitles.UPLOAD_FILE);
    };

    const onSubmit = async (e: React.FormEvent<{}>) => {
        e.preventDefault();

        await onNext();
    };

    useEffect(() => {
        let isMounted = true;
        if (!file || international || !inputMapping.country) {
            return;
        }
        setInternationalPresentLoading(true);
        const checkInternationalFromFile = async () => {
            const isInternational = await doInternationalCountriesExist(
                file,
                inputMapping
            );
            if (!isMounted) {
                return;
            }
            setInternationalPresentLoading(false);

            setInternationalPresent(isInternational);
        };

        checkInternationalFromFile();
        return () => {
            isMounted = false;
        };
    }, [file, inputMapping, international, setInternationalPresent]);

    return (
        <form onSubmit={onSubmit} style={{ width: '100%' }}>
            <Grid container direction="column" alignItems="center" spacing={4}>
                <Grid item>
                    <Typography variant="h6" align="center">
                        Map your columns to our fields
                    </Typography>
                </Grid>

                <Grid item xs={8}>
                    <Alert severity="info" variant="outlined">
                        <Typography color="primary">
                            If you have the address in a single column, you only
                            need to map Address Line 1. The rest of the fields
                            are optional.
                            {runNCOA &&
                                ' For NCOA, please provide mappings for first name and last name.'}
                        </Typography>
                    </Alert>
                </Grid>

                <Grid
                    container
                    item
                    alignItems="center"
                    direction="column"
                    spacing={2}
                >
                    {loadingHeuristics ? (
                        <CircularProgress />
                    ) : (
                        Object.entries(
                            runNCOA ? NCOA_ADDRESS_FIELDS : ADDRESS_FIELDS
                        ).map(([key, value]) => (
                            <FieldMapping key={key} title={key} value={value} />
                        ))
                    )}
                </Grid>
                {internationalPresent && (
                    <Grid item xs={8}>
                        <Alert severity="warning" variant="outlined">
                            <WarningTopText align="center">
                                We have detected that you have international
                                address(es) in your file.
                            </WarningTopText>
                            <WarningText align="center" gutterBottom>
                                Do you want to continue with international
                                verification?{' '}
                                <Checkbox
                                    name="International Select"
                                    onChange={() => {
                                        setInternationalOptIn((prev) => !prev);
                                    }}
                                />
                            </WarningText>
                        </Alert>
                    </Grid>
                )}

                <BulkRowSamples />

                <Grid item justify="center" container spacing={3}>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={onPrev}
                        >
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <BulkStepLoadingButton
                            type="submit"
                            loading={loading}
                            disabled={
                                !international && internationalPresentLoading
                            }
                        >
                            Confirm Field Mappings
                        </BulkStepLoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default Step2;
