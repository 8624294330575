import React, {
    useContext,
    useState,
    MouseEvent as ReactMouseEvent,
    useRef,
    useEffect,
} from 'react';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Backdrop from '@material-ui/core/Backdrop';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/Search';

import { Context as AuthContext, ActionType } from '../context/Auth';

import ListItemLink from './ListItemLink';
import NavItemLink from './NavItemLink';
import {
    BlogIcon,
    Support,
    Subscribe,
    LogoutIcon,
    SettingsIcon,
    CallIcon,
} from './Iconss';

import { PersonIcon, InfoIcon } from './Icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    default as MUIButton,
    ButtonProps as MUIButtonProps,
} from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        boxShadow: '0px 1px 3px #9a9a9a33',
        width: '275px',
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    notchedOutline: {
        borderColor: 'transparent',
    },
    listItemRoot: {
        padding: '8px 30px',
        fontFamily: 'Poppins',
        '&.MuiListItem-button:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    btnRoot: {
        borderRadius: '5px',
        outline: 'none',
        boxShadow: 'none',
        padding: '8px 30px',
    },
    btnLabel: {
        textTransform: 'capitalize',
        fontWeight: 400,
        fontFamily: theme.typography.body1.fontFamily,
    },
}));

const Button = (props: MUIButtonProps) => {
    const classes = useStyles();
    return (
        <MUIButton
            classes={{
                root: classes.btnRoot,
                label: classes.btnLabel,
            }}
            {...props}
        />
    );
};

const TopNav = (props: {
    showSearch?: boolean;
    searchQuery?: (query: string) => void;
    searchText?: String;
}) => {
    const { state, dispatch } = useContext(AuthContext);
    const [informationMenu, setInformationMenu] = useState<boolean>(false);
    const [accountMenu, setAccountMenu] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const [navBackground, setNavBackground] = useState(false);

    const navRef = useRef<boolean>();
    navRef.current = navBackground;

    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 50;
            if (navRef.current !== show) {
                setNavBackground(show);
            }
        };

        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const classes = useStyles();

    const logout = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        dispatch({
            type: ActionType.LOGOUT,
        });
    };

    const history = useHistory();
    return (
        <AppBar
            style={{
                backgroundColor: navBackground ? '#fff' : 'transparent',
                color: '#000',
                width: 'auto',
                marginLeft: '265px',
                left: 0,
                padding: '0 20px 0 55px',
                borderBottom: '1px solid',
                borderColor: '#EFF3F8',
                height: '61px',
            }}
            elevation={0}
            position="fixed"
        >
            <Toolbar
                disableGutters
                style={{
                    height: '60px',
                }}
            >
                <Grid container justify="space-between">
                    <Grid item>
                        {props.showSearch ? (
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Search"
                                fullWidth
                                value={props.searchText}
                                onChange={(e) => {
                                    if (props.searchQuery)
                                        props.searchQuery(e.target.value);
                                }}
                                classes={{ root: classes.root }}
                                style={{
                                    backgroundColor:
                                        informationMenu || accountMenu
                                            ? 'transparent'
                                            : '#fff',
                                    marginTop: '4px',
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        ) : null}
                    </Grid>
                    <Grid
                        justify="flex-end"
                        alignItems="center"
                        container
                        spacing={2}
                    >
                        <Grid item>
                            <Link
                                target="_blank"
                                href="https://postgrid.readme.io/docs/overview-3"
                                color="inherit"
                            >
                                <Button
                                    style={{
                                        textTransform: 'none',
                                        fontWeight: 400,
                                    }}
                                    color="inherit"
                                >
                                    Guides
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                onClick={() =>
                                    history.push('/dashboard/upgrade')
                                }
                                variant="contained"
                            >
                                Subscribe
                            </Button>
                        </Grid>
                        <Grid item>
                            <Grid alignItems="center" container spacing={1}>
                                {/* info button */}
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            setInformationMenu(
                                                !informationMenu
                                            );
                                            setAnchorEl(e.currentTarget);
                                        }}
                                    >
                                        <InfoIcon />
                                    </IconButton>

                                    <Backdrop
                                        open={informationMenu}
                                        onClick={() => {
                                            setInformationMenu(false);
                                            setAnchorEl(null);
                                        }}
                                    >
                                        <Popover
                                            open={informationMenu}
                                            keepMounted
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <p
                                                style={{
                                                    height: '10px',
                                                    width: '10px',
                                                    transform: 'rotate(45deg)',
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    left: '47%',
                                                    overflow: 'visible',
                                                    backgroundColor: '#fff',
                                                }}
                                            />
                                            <List>
                                                <NavItemLink
                                                    icon={SettingsIcon}
                                                    iconColor="#575e71"
                                                    primary="Standard API Docs"
                                                    to={
                                                        'https://avdocs.postgrid.com'
                                                    }
                                                />

                                                <NavItemLink
                                                    icon={SettingsIcon}
                                                    iconColor="#575e71"
                                                    primary="International API Docs"
                                                    to="https://intdocs.postgrid.com"
                                                />

                                                <Box px={2}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="primary"
                                                        style={{
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        RESOURCES
                                                    </Typography>
                                                </Box>

                                                <NavItemLink
                                                    icon={CallIcon}
                                                    primary="Support"
                                                    to="https://postgrid.com/contact"
                                                />

                                                <Divider />

                                                <NavItemLink
                                                    icon={BlogIcon}
                                                    primary="User Guide"
                                                    to="https://www.postgrid.com/help-support/"
                                                />
                                            </List>
                                        </Popover>
                                    </Backdrop>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* user button */}
                        <Grid
                            item
                            onClick={(e) => {
                                setAccountMenu(!accountMenu);
                                setAnchorEl(e.currentTarget);
                            }}
                        >
                            {/* </IconButton> */}
                            <Button
                                disableRipple
                                disableFocusRipple
                                endIcon={<ExpandMoreIcon />}
                                style={{
                                    paddingLeft: '0px',
                                    backgroundColor: 'transparent',
                                    color: '#7C8DB5',
                                }}
                            >
                                <PersonIcon />
                                &nbsp; {state.user?.firstName} &nbsp;
                                {state.user?.lastName}
                            </Button>
                            <Backdrop
                                open={accountMenu}
                                onClick={() => {
                                    setAccountMenu(false);
                                    setAnchorEl(null);
                                }}
                            >
                                <Popover
                                    open={accountMenu}
                                    keepMounted
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <List>
                                        <NavItemLink
                                            to="https://postgrid.com/help-support/"
                                            primary="Support"
                                            icon={Support}
                                        />

                                        <Divider />

                                        <ListItemLink
                                            icon={Subscribe}
                                            to="/dashboard/upgrade"
                                            primary="Subscribe"
                                            topNav
                                        />

                                        <Divider />

                                        <ListItemLink
                                            icon={SettingsIcon}
                                            to="/dashboard/profile"
                                            primary="Profile"
                                            topNav
                                        />

                                        <Divider />

                                        <ListItem
                                            button
                                            disableRipple
                                            onClick={logout}
                                            classes={{
                                                root: classes.listItemRoot,
                                            }}
                                            style={{ paddingTop: '12px' }}
                                        >
                                            <ListItemIcon
                                                style={{
                                                    marginBottom: '-5px',
                                                }}
                                            >
                                                <LogoutIcon />
                                            </ListItemIcon>
                                            <ListItemText disableTypography>
                                                Logout
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Popover>
                            </Backdrop>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};
export default TopNav;
