import React, { PropsWithoutRef } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';

import CloseIcon from '@material-ui/icons/Close';

import Button from '../components/Button';

const useStyles = makeStyles({
    dialogRoot: {
        borderRadius: '14px',
    },
});

export interface DialogBoxProps {
    open: boolean;
    onClose: (e: {}) => void;
    title?: string;
    text?: string;
    children?: JSX.Element;
    actionLabel: string;
    cancelLabel?: string;
    onConfirm: (e: {}) => void;
    onClick?: (e: {}) => void;
    image?: string;
    style?: { [key: string]: any };
    disabled?: boolean;
}

const DialogBox = (props: PropsWithoutRef<DialogBoxProps>) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();

    return (
        <Dialog
            className={classes.dialogRoot}
            maxWidth="xs"
            fullScreen={fullScreen}
            style={{ zIndex: 1300 }}
            {...props}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    props.onConfirm(e);
                }}
            >
                <DialogContent style={{ overflow: 'hidden' }}>
                    <Grid
                        container
                        justify="flex-end"
                        spacing={0}
                        alignItems="center"
                    >
                        <Grid item>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    props.onClose(e);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {props.image ? (
                        <img
                            src={props.image}
                            alt={props.title}
                            width="160"
                            style={{ margin: '10px auto', display: 'block' }}
                        />
                    ) : null}
                    {props.title && (
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                style={{ textAlign: 'center' }}
                            >
                                {props.title}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Box mt={2} />
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        {props.text && (
                            <DialogContentText>{props.text}</DialogContentText>
                        )}

                        <Grid
                            container
                            justify="center"
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                        >
                            <Grid item>{props.children}</Grid>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{ padding: '2rem 0rem', overflow: 'hidden' }}
                >
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={4}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={(e) => {
                                    props.onClose(e);
                                }}
                                fullWidth
                                disabled={props.disabled}
                            >
                                {props.cancelLabel || 'Cancel'}
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                type="submit"
                                disabled={props.disabled}
                            >
                                {props.actionLabel}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DialogBox;
