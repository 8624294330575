import React, {
    Dispatch,
    createContext,
    useReducer,
    PropsWithChildren,
    useContext,
    useCallback,
} from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export enum MessageType {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
}

interface State {
    open: boolean;
    type?: MessageType;
    message?: string;
}

interface MessageAction {
    type: MessageType;
    message: string;
}

interface HideAction {
    type: 'hide';
}

export type Action = MessageAction | HideAction;

const reducer = (state: State, action: Action): State => {
    if (action.type === 'hide') {
        return {
            ...state,
            open: false,
        };
    }

    return {
        open: true,
        type: action.type,
        message: action.message,
    };
};

interface IContext {
    state: State;
    dispatch: Dispatch<Action>;
    dispatchSuccess: (message: string) => void;
    dispatchError: (message: string) => void;
}

export const Context = createContext<IContext>({
    state: { open: false },
    dispatch: () => {},
    dispatchSuccess: () => {},
    dispatchError: () => {},
});

export const Display = (props: PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(reducer, { open: false });

    const dispatchSuccess = useCallback((message: string) => {
        dispatch({ type: MessageType.SUCCESS, message });
    }, []);

    const dispatchError = useCallback((message: string) => {
        dispatch({ type: MessageType.ERROR, message });
    }, []);

    return (
        <Context.Provider
            value={{ state, dispatch, dispatchSuccess, dispatchError }}
        >
            <Snackbar
                open={state.open}
                onClose={() => {
                    dispatch({ type: 'hide' });
                }}
                autoHideDuration={6000}
            >
                <Alert severity={state.type}>{state.message}</Alert>
            </Snackbar>
            {props.children}
        </Context.Provider>
    );
};

export const useNotifcationContext = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useNotifcationContext can only be used within a <Display /> provider'
        );
    }

    return context;
};
