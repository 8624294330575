import React, { useEffect, useContext } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import {
    MessageType,
    Context as NotificationContext,
} from '../context/Notification';

import { useService as useUsersService } from '../services/Users';

const CompleteSignup = () => {
    const history = useHistory();

    const service = useUsersService();

    const { signupID } = useParams<{ signupID: string }>();

    const { dispatch } = useContext(NotificationContext);

    useEffect(() => {
        (async () => {
            try {
                await service.completeSignup(signupID);

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Signed up successfully. Please log in.',
                });

                history.push('/login');
            } catch (err) {
                console.error(err);
            }
        })();
    }, [service, signupID]);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100vw"
            height="100vh"
        >
            <p>Please wait...</p>
        </Box>
    );
};

export default CompleteSignup;
