import { ButtonProps } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import Button from './Button';

interface BulkStepLoadingButtonProps extends ButtonProps {
    loading: boolean;
    disabled?: boolean;
}

const BulkStepLoadingButton: React.FC<BulkStepLoadingButtonProps> = ({
    loading,
    disabled,
    children,
    ...props
}) => {
    return (
        <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || disabled}
            {...props}
        >
            {loading ? <CircularProgress size={24.5} /> : children}
        </Button>
    );
};

export default BulkStepLoadingButton;
