import React from 'react';

import Skeleton, { SkeletonProps } from '@material-ui/lab/Skeleton';
import TableBody, { TableBodyProps } from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const TableBodyLoader = (
    props: TableBodyProps & {
        show: boolean;
        columns: number;
        SkeletonProps?: SkeletonProps;
    }
) => {
    const { show, columns, SkeletonProps, ...otherProps } = props;

    if (!props.children) {
        return null;
    }

    const cells = [];

    for (let i = 0; i < columns; ++i) {
        cells.push(
            <TableCell key={i}>
                <Skeleton {...SkeletonProps} />
            </TableCell>
        );
    }

    if (show) {
        return (
            <TableBody>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
                <TableRow>{cells}</TableRow>
            </TableBody>
        );
    }

    return <TableBody {...otherProps} />;
};

export default TableBodyLoader;
