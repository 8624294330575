import React from 'react';
import { useHistory } from 'react-router-dom';

import Button, { ButtonProps } from './Button';

const GoBackButton = (props: ButtonProps) => {
    const history = useHistory();

    return (
        <Button
            {...props}
            onClick={(e) => {
                history.goBack();
            }}
        />
    );
};

export default GoBackButton;
