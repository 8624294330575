import React from 'react';
// Components
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import EmbedWrapper from '../components/EmbedWrapper';

const InvalidEmbeddedRoute = () => {
    return (
        <EmbedWrapper>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12}>
                    <Alert severity="error" style={{ width: '100%' }}>
                        <AlertTitle>Invalid Integration</AlertTitle>
                        This integration does not exist. Ensure your iframe URL
                        is set correctly. If this problem persists contact us at{' '}
                        <Link href="mailto:support@postgrid.com" component="a">
                            support@postgrid.com
                        </Link>
                        .
                    </Alert>
                </Grid>
            </Grid>
        </EmbedWrapper>
    );
};

export default InvalidEmbeddedRoute;
