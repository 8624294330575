import React, { useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { loadStripe, StripeCardNumberElement } from '@stripe/stripe-js';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import { Grid, Typography, Link, Box, TextField } from '@material-ui/core';

import Divider from '@material-ui/core/Divider';

import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import {
    Subscription,
    Account,
    useService as useOrganizationService,
    useOrganization,
} from '../services/Organization';
import { useService as useBillingService } from '../services/Billing';
import {
    BulkListMapping,
    BulkListEstimate,
    Service as BulkListService,
    useService as useBulkListsService,
} from '../services/BulkLists';

import Button from './Button';
import StripeInput, { StripeInputProps } from '../components/StripeInput';

// HACK Factor into utils
import { formatCents } from './SubscribeCard';

import {
    PaymentMethodContainer,
    PaymentMethodUpdateDialogBox,
} from '../pages/Upgrade';

import DialogBox from './DialogBox';

import {
    AdditionalLookupIcon,
    AddressInFileIcon,
    RemainingLookupIcon,
} from './Icons';
import {
    StepTitles,
    useBulkVerifyStepsContext,
} from '../context/BulkVerifyStepsContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY || '');

const CardField = (props: {
    label: string;
    component: StripeInputProps['component'];
}) => {
    return (
        <TextField
            label={props.label}
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
                inputComponent: StripeInput,
                inputProps: {
                    component: props.component,
                },
            }}
        />
    );
};

const ReportBox = (props: {
    image: () => JSX.Element;
    label: string;
    value: number;
}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="180px"
            border="1px solid #e9e9e9"
            borderRadius="10px"
        >
            <Grid container alignItems="center" direction="column">
                <Grid item>
                    <Box pb={1}>{props.image}</Box>
                </Grid>

                <Grid item>
                    <Typography>{props.label}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="h6">
                        {props.value.toLocaleString()}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const Report = ({
    rowCount,
    remainingLookups,
    international,
}: {
    rowCount: number;
    remainingLookups: number;
    international: boolean;
}) => {
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h6">
                    {international
                        ? 'International Address List Report'
                        : 'Address List Report'}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <ReportBox
                            image={AddressInFileIcon}
                            label="Addresses In The File"
                            value={rowCount}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <ReportBox
                            image={RemainingLookupIcon}
                            label="Remaining Free Addresses"
                            value={remainingLookups}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <ReportBox
                            image={AdditionalLookupIcon}
                            label="Additional Addresses"
                            value={Math.max(rowCount - remainingLookups, 0)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const BulkPrice = ({
    lowerLimit,
    upperLimit,
    pricePerUnit,
    selected,
    international,
    remainingLookups,
}: {
    lowerLimit?: number;
    upperLimit?: number;
    pricePerUnit?: number;
    selected?: boolean;
    international: boolean;
    remainingLookups: number;
}) => {
    const color = selected ? 'primary' : undefined;

    return (
        <Box
            border={1}
            borderColor={selected ? 'primary.main' : '#e9e9e9'}
            borderRadius="7px"
            width="100%"
            minHeight={70}
            px={4}
            py={2}
            display="flex"
            alignItems="center"
        >
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    {!lowerLimit && upperLimit && (
                        <Typography variant="body2">
                            For up to {upperLimit.toLocaleString()} addresses
                        </Typography>
                    )}
                    {lowerLimit && upperLimit && (
                        <Typography variant="body2">
                            For {lowerLimit.toLocaleString()} to{' '}
                            {upperLimit.toLocaleString()} addresses
                        </Typography>
                    )}
                    {lowerLimit && !upperLimit && (
                        <Typography variant="body2">
                            For more than {lowerLimit.toLocaleString()}{' '}
                            addresses
                        </Typography>
                    )}
                    {pricePerUnit === 0 && (
                        <Typography variant="body2">
                            Your remaining {remainingLookups}{' '}
                            {international ? 'international' : 'standard'}{' '}
                            addresses
                        </Typography>
                    )}
                </Grid>

                <Grid item>
                    {pricePerUnit ? (
                        <Grid container item spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="h6" color={color}>
                                    {formatCents(pricePerUnit)}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="caption" color={color}>
                                    /address
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : pricePerUnit === 0 ? (
                        <Typography variant="body2" color={color}>
                            Free
                        </Typography>
                    ) : (
                        <Link href="https://postgrid.com/contact-us">
                            Contact Us
                        </Link>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

const Summary = ({
    service,
    rowCount,
    remaining,
    showCreateAccountDialog,
    onPrev,
    account,
    onNext,
    setShowCreateAccountDialog,
    setAccount,
    paidLookups,
    international,
    remainingLookups,
    useGeocode,
    disableAutoPay,
    runNCOA,
}: {
    service: BulkListService;
    rowCount: number;
    remaining: number;
    account?: Account;
    setShowCreateAccountDialog: (arg1: boolean) => void;
    showCreateAccountDialog: boolean;
    onPrev?: () => void;
    onNext?: () => void;
    paidLookups: number;
    international: boolean;
    setAccount: (arg1: Account) => void;
    remainingLookups: number;
    useGeocode?: boolean;
    disableAutoPay?: boolean;
    runNCOA?: boolean;
}) => {
    const [est, setEst] = useState<BulkListEstimate>();

    useEffect(() => {
        (async () => {
            try {
                setEst(
                    await service.estimate(
                        rowCount,
                        international,
                        useGeocode,
                        runNCOA
                    )
                );
            } catch (err) {
                console.error(err);
            }
        })();
    }, [service, rowCount, remaining, useGeocode, runNCOA, international]);

    if (!est) {
        return null;
    }

    const DisableAutoPay = () => (
        <>
            <Grid item xs={12}>
                <Box
                    border={1}
                    borderColor={
                        paidLookups === 0 ? 'primary.main' : 'secondary.main'
                    }
                    borderRadius="7px"
                    width="100%"
                    minHeight={70}
                    px={4}
                    py={2}
                    display="flex"
                    alignItems="center"
                    lineHeight={2}
                >
                    {paidLookups === 0
                        ? `This will deduct from your remaining ${remainingLookups}
                    lookups. If you would like to add more lookups for future
                    processing, please contact your administrator.`
                        : `This account does not have enough lookups remaining to process this file. Please contact your administrator to add more lookups.`}
                </Box>
            </Grid>

            <Grid item xs={12}>
                <Box mt={1} />
            </Grid>

            <Grid container item justify="space-between">
                <Grid item>
                    <Typography>Addresses in the file</Typography>
                </Grid>

                <Grid item>
                    <Typography>{rowCount.toLocaleString()}</Typography>
                </Grid>
            </Grid>

            <Grid container item xs={12} justify="flex-end">
                <Grid container item xs={12}>
                    <Grid item xs={10}>
                        <Typography>Price Per Additional Address</Typography>
                    </Grid>

                    <Grid item xs={2}>
                        <Typography style={{ textAlign: 'right' }}>
                            {formatCents(est.unitCost)}
                        </Typography>
                    </Grid>
                </Grid>
                {useGeocode && (
                    <Grid container item xs={11}>
                        <Grid item xs={10}>
                            <Typography
                                style={{ color: 'gray', fontSize: '14px' }}
                            >
                                Geocoding fee
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography
                                style={{
                                    textAlign: 'right',
                                    color: 'gray',
                                    fontSize: '14px',
                                }}
                            >
                                {formatCents(1)}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {runNCOA && est.ncoaCost !== undefined && (
                    <Grid container item xs={11}>
                        <Grid item xs={10}>
                            <Typography
                                style={{ color: 'gray', fontSize: '14px' }}
                            >
                                Flat Fee for NCOA
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <Typography
                                style={{
                                    textAlign: 'right',
                                    color: 'gray',
                                    fontSize: '14px',
                                }}
                            >
                                {formatCents(est.ncoaCost)}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid container item justify="space-between">
                <Grid item>
                    <Typography>Remaining Lookups</Typography>
                </Grid>

                <Grid item>
                    <Typography>{remainingLookups.toLocaleString()}</Typography>
                </Grid>
            </Grid>

            {paidLookups === 0 && (
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid container item justify="space-between">
                        <Grid item>
                            <Typography>
                                Remaining Lookups After Processing
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography>
                                {(remainingLookups - rowCount).toLocaleString()}
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6">
                    {disableAutoPay ? 'Summary' : 'Payment Summary'}
                </Typography>
            </Grid>

            {/* HACK(Apaar): Not sure why but using Grid container item didn't work here */}
            <Grid item>
                <Grid container direction="column" spacing={1}>
                    {disableAutoPay ? (
                        <DisableAutoPay />
                    ) : (
                        <>
                            <Grid item>
                                <BulkPrice
                                    pricePerUnit={0}
                                    selected={paidLookups === 0}
                                    international={international}
                                    remainingLookups={remaining}
                                />
                            </Grid>

                            {est.prices.map((price, i) => (
                                <Grid item>
                                    {i === 0 ? (
                                        <BulkPrice
                                            key={i}
                                            upperLimit={price.threshold}
                                            pricePerUnit={price.pricePerAddress}
                                            selected={
                                                paidLookups < price.threshold &&
                                                paidLookups > 1
                                            }
                                            international={international}
                                            remainingLookups={remaining}
                                        />
                                    ) : (
                                        <BulkPrice
                                            key={i}
                                            lowerLimit={
                                                est.prices[i - 1].threshold
                                            }
                                            upperLimit={price.threshold}
                                            pricePerUnit={price.pricePerAddress}
                                            selected={
                                                paidLookups >=
                                                    est.prices[i - 1]
                                                        .threshold &&
                                                paidLookups < price.threshold
                                            }
                                            international={international}
                                            remainingLookups={remaining}
                                        />
                                    )}
                                </Grid>
                            ))}

                            <Grid item>
                                <BulkPrice
                                    lowerLimit={
                                        est.prices[est.prices.length - 1]
                                            .threshold
                                    }
                                    international={international}
                                    remainingLookups={remaining}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Box mt={1} />
                            </Grid>

                            <Grid container item justify="space-between">
                                <Grid item>
                                    <Typography>
                                        Additional Addresses
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography>
                                        {paidLookups.toLocaleString()}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} justify="flex-end">
                                <Grid container item xs={12}>
                                    <Grid item xs={10}>
                                        <Typography>
                                            Price Per Additional Address
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={2}>
                                        <Typography
                                            style={{ textAlign: 'right' }}
                                        >
                                            {formatCents(est.unitCost)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {useGeocode && (
                                    <Grid container item xs={11}>
                                        <Grid item xs={10}>
                                            <Typography
                                                style={{
                                                    color: 'gray',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Geocoding Fee
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Typography
                                                style={{
                                                    textAlign: 'right',
                                                    color: 'gray',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {formatCents(1)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                {/* TODO(Apaar): This entire section is copy pasted from above, not sure why */}
                                {runNCOA && est.ncoaCost !== undefined && (
                                    <Grid container item xs={11}>
                                        <Grid item xs={10}>
                                            <Typography
                                                style={{
                                                    color: 'gray',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Flat Fee for NCOA
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={2}>
                                            <Typography
                                                style={{
                                                    textAlign: 'right',
                                                    color: 'gray',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {formatCents(est.ncoaCost)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid container item justify="space-between">
                                <Grid item>
                                    <Typography>Total Cost</Typography>
                                </Grid>

                                <Grid item>
                                    <Typography>
                                        {formatCents(est.cost)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>

            <Grid container item spacing={2}>
                <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        color="primary"
                        disabled={!onPrev}
                        onClick={onPrev}
                    >
                        Previous
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        color="primary"
                        disabled={
                            (disableAutoPay && (paidLookups > 0 || runNCOA)) ||
                            !onNext
                        }
                        onClick={onNext}
                    >
                        Confirm
                    </Button>
                </Grid>
            </Grid>
            {!disableAutoPay && (paidLookups !== 0 || runNCOA) && (
                <Elements stripe={stripePromise}>
                    <CreatePaymentCard
                        setAccount={setAccount}
                        showCreateAccountDialog={showCreateAccountDialog}
                        setShowCreateAccountDialog={setShowCreateAccountDialog}
                    />
                </Elements>
            )}
        </Grid>
    );
};

export const CreatePaymentCard = ({
    setAccount,
    showCreateAccountDialog,
    setShowCreateAccountDialog,
}: {
    setAccount: (a: Account) => void;
    showCreateAccountDialog: boolean;
    setShowCreateAccountDialog: (arg1: boolean) => void;
}) => {
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const billingService = useBillingService();
    const orgService = useOrganizationService();

    const handleAddPaymentMethod = async () => {
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(
                CardNumberElement
            ) as StripeCardNumberElement,
        });

        if (error) {
            notificationDispatch({
                type: MessageType.ERROR,
                message: error.message as string,
            });

            setLoading(false);

            return;
        }

        if (!paymentMethod) {
            setLoading(false);
            return;
        }

        try {
            await billingService.createPaymentMethod({
                name: 'Default',
                stripePaymentMethodId: paymentMethod.id,
            });

            const acc = await orgService.getAccount();

            if (!acc) {
                setLoading(false);
                return;
            }

            setAccount(acc);

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Payment method created successfully.',
            });
        } catch (err) {
            console.error(err);
        }

        setShowCreateAccountDialog(false);
        setLoading(false);
    };

    return (
        <DialogBox
            open={showCreateAccountDialog}
            onClose={() => setShowCreateAccountDialog(false)}
            actionLabel="Create"
            onConfirm={handleAddPaymentMethod}
            disabled={loading}
        >
            <form onSubmit={handleAddPaymentMethod}>
                <Grid
                    container
                    direction="column"
                    xs={12}
                    spacing={3}
                    style={{ width: '300px' }}
                >
                    <Grid item>
                        <Typography variant="h6">
                            Create Payment Method
                        </Typography>
                    </Grid>
                    <Grid container item justify="center" spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <CardField
                                    label="Card Number"
                                    component={CardNumberElement}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            spacing={2}
                            direction="row"
                        >
                            <Grid item xs={6}>
                                <CardField
                                    label="Expiry Date"
                                    component={CardExpiryElement}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CardField
                                    label="Security Code"
                                    component={CardCvcElement}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </DialogBox>
    );
};

const Step3 = () => {
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const history = useHistory();

    const isEmbed = history.location.pathname.includes('embed');

    const service = useBulkListsService();
    const orgService = useOrganizationService();
    const org = useOrganization([]);

    const {
        file,
        fileRowCount: rowCount,
        inputMapping,
        international,
        useGeocode,
        runNCOA,
        useProperCase,
        setActiveStep,
        defaultCountry,
        prevRoute,
    } = useBulkVerifyStepsContext();

    const [currentlyActivePlan, setCurrentlyActivePlan] =
        useState<Subscription | null>(null);

    const [account, setAccount] = useState<Account>();

    const [showUpdateDialogBox, setShowUpdateDialogBox] = useState(false);

    const [showCreateAccountDialogBox, setShowCreateAccountDialog] =
        useState(false);

    const remaining =
        org &&
        (international
            ? Math.max(org.limit.intlBulkLookups - org.usage.intlBulkLookups, 0)
            : Math.max(org.limit.bulkLookups - org.usage.bulkLookups, 0));

    const paidLookups =
        remaining !== undefined ? Math.max(rowCount - remaining, 0) : 0;

    const [loading, setLoading] = useState(false);

    const onPrev = () => {
        setActiveStep(StepTitles.MAP_FIELDS);
    };

    const getAccount = useCallback(async () => {
        try {
            setLoading(true);

            setAccount(await orgService.getAccount());
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    }, [orgService]);

    const getCurrentPlan = useCallback(async () => {
        try {
            setLoading(true);

            const res = await orgService.getSubscription();

            if (res) {
                setCurrentlyActivePlan(res);
            }
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    }, [orgService]);

    useEffect(() => {
        getAccount();
        getCurrentPlan();
    }, [showUpdateDialogBox, getAccount, getCurrentPlan]);

    const onNext = async () => {
        setLoading(true);

        if ((paidLookups > 0 || runNCOA) && !account) {
            setShowCreateAccountDialog(true);
            setLoading(false);

            return;
        }

        await service.create({
            name: file!.name,
            useIntlVerification: international,
            useGeocode,
            runNCOA,
            useProperCase,
            mappings: inputMapping as BulkListMapping,
            file: file!,
            defaultCountry,
        });

        setLoading(false);

        notificationDispatch({
            type: MessageType.SUCCESS,
            message: 'File has uploaded successfully.',
        });

        history.push(prevRoute);
    };

    return (
        <>
            {org && remaining !== undefined && (
                <PaymentMethodUpdateDialogBox
                    org={org}
                    open={showUpdateDialogBox}
                    onClose={() => setShowUpdateDialogBox(false)}
                />
            )}
            <Grid container spacing={1}>
                <Grid item xs={7}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item>
                            <Typography variant="h5">
                                {international
                                    ? 'Your international address list report'
                                    : 'Your address list report'}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography display="inline">
                                Here's what we found for the list:
                            </Typography>
                            <Typography display="inline" color="primary">
                                {' '}
                                {file!.name}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Box my={2}>
                                <Divider />
                            </Box>
                        </Grid>

                        {remaining !== undefined && (
                            <Grid item>
                                <Report
                                    rowCount={rowCount}
                                    remainingLookups={remaining}
                                    international={international}
                                />
                            </Grid>
                        )}

                        <Grid item>
                            <Box mt={2} />
                        </Grid>

                        {account && (
                            <Grid item>
                                <PaymentMethodContainer
                                    account={account}
                                    currentlyActivePlan={currentlyActivePlan}
                                    setShowUpdateDialogBox={
                                        setShowUpdateDialogBox
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={1}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        width={1}
                        height={1}
                    >
                        <Divider orientation="vertical" />
                    </Box>
                </Grid>

                {/* HACK(Apaar): A mockup of the payment summary page */}
                {remaining !== undefined && (
                    <Grid item xs={4}>
                        <Summary
                            service={service}
                            onPrev={loading ? undefined : onPrev}
                            onNext={loading ? undefined : onNext}
                            rowCount={rowCount}
                            remaining={remaining}
                            account={account}
                            setShowCreateAccountDialog={
                                setShowCreateAccountDialog
                            }
                            setAccount={setAccount}
                            paidLookups={paidLookups}
                            international={international}
                            showCreateAccountDialog={showCreateAccountDialogBox}
                            remainingLookups={remaining}
                            useGeocode={useGeocode}
                            disableAutoPay={isEmbed}
                            runNCOA={runNCOA}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};
export default Step3;
