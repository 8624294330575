import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
const styles = makeStyles({
    chip: {
        borderRadius: '3px',
        padding: '0px 6px',
        backgroundColor: '#14C868',
        color: 'white',
        textAlign: 'center',
        fontSize: '12px',
    },
});
const TextInput = ({
    label,
    style,
    size,
    color,
}: {
    label: string;
    style?: any;
    size?: string;
    color?: string;
}) => {
    const classes = styles();
    return (
        <Typography variant="subtitle1" style={style} className={classes.chip}>
            {label}
        </Typography>
    );
};
export default TextInput;
