import React from 'react';
//
import { useEmbedAuth } from '../context/EmbedAuth';
// Components
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Typography from '@material-ui/core/Typography';

const Loader = () => {
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={4}
        >
            <Grid item>
                <CircularProgress size={100} />
            </Grid>
            <Grid item>
                <Box fontStyle="italic">
                    <Typography style={{ color: '#bababa' }}>
                        Authenticating with PostGrid...
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

const ErrorDisplay = ({ error }: { error: string }) => {
    return (
        <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
                <Alert severity="error" style={{ width: '100%' }}>
                    <AlertTitle>PostGrid Authentication Error</AlertTitle>
                    {error}
                </Alert>
            </Grid>
        </Grid>
    );
};

interface EmbedWrapperProps {
    children: React.ReactNode | React.ReactNode[];
}

const EmbedWrapper = ({ children }: EmbedWrapperProps) => {
    const { loading, error } = useEmbedAuth();

    return (
        <Box padding={4} height="100vh" display="flex">
            <Box width="100%">
                {loading ? (
                    <Loader />
                ) : error ? (
                    <ErrorDisplay error={error} />
                ) : (
                    children
                )}
            </Box>
        </Box>
    );
};

export default EmbedWrapper;
