import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { makeStyles, Link, Toolbar, Typography } from '@material-ui/core';

import signUpImg from '../img/signUp.png';
import logo from '../logo.png';

const useStyles = makeStyles({
    root: {
        margin: '0',
        padding: '0',
        boxSizing: 'border-box',
        overflowX: 'hidden',
        height: '97vh',
        backgroundColor: '#fff',
    },
    padding: {
        padding: '1.5rem',
    },
    shadow: {
        boxShadow: '4px 5px 34px 9px #f2f2f2,-4px -5px 34px 9px #f2f2f2',
    },
});

const SignupCheckEmail = () => {
    const history = useHistory();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const email = queryParams.get('email') || '';

    const classes = useStyles();

    return (
        <Grid
            container
            spacing={0}
            alignItems="flex-start"
            className={classes.root}
        >
            <Grid item md={12}>
                <Toolbar style={{ borderBottom: '1px lightgrey solid' }}>
                    <img src={logo} width="150" alt="logo" />
                </Toolbar>
            </Grid>

            <Grid
                md={12}
                container
                alignContent="center"
                alignItems="center"
                justify="center"
                direction="row"
                spacing={5}
            >
                <Grid
                    item
                    container
                    md={5}
                    justify="center"
                    alignContent="center"
                    className={`${classes.shadow} ${classes.padding}`}
                    spacing={2}
                >
                    <Grid item>
                        <img
                            src={signUpImg}
                            alt="Sign up Cover pic"
                            width="350"
                        />
                    </Grid>
                    <Grid item sm={8}>
                        <Typography
                            style={{ textAlign: 'center' }}
                            variant="h5"
                        >
                            Great! You've successfully signed up for PostGrid
                        </Typography>
                    </Grid>
                    <Grid item sm={7}>
                        <Typography
                            style={{ textAlign: 'center' }}
                            variant="caption"
                        >
                            We've sent you a link to confirm your email address.
                            Please check your inbox. It could take up to 10
                            minutes to show up in your inbox.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container alignItems="center" item md={4}>
                    <Grid
                        item
                        container
                        spacing={2}
                        className={classes.padding}
                    >
                        <Grid item>
                            <Typography variant="h5">
                                Didn't receive an email?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography display="block" variant="caption">
                                1. Is{' '}
                                <span style={{ color: '#2569E6' }}>
                                    {email}{' '}
                                </span>{' '}
                                the correct email, without any typos?
                            </Typography>
                            <Typography variant="caption">
                                if not,{' '}
                                <Link
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        history.push('/signup');
                                    }}
                                >
                                    restart the signup process
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography display="block" variant="caption">
                                2. Check your spam folder
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography variant="h5">
                                Still having trouble?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                <Link
                                    style={{ cursor: 'pointer' }}
                                    href="https://www.postgrid.com/contact-us/"
                                    color="primary"
                                >
                                    Contact us
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SignupCheckEmail;
