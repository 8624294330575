import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import CopyToClipboard from './CopyToClipboard';

type APIKeyProps = {
    value: string;
    defaultHide?: boolean;
};

const APIKey = ({ value, defaultHide = true }: APIKeyProps) => {
    const [hide, setHide] = useState(defaultHide);

    const toggleHide = () => {
        setHide((prev) => !prev);
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width={`${value.length * 10}px`}
        >
            {hide ? value.replaceAll(/./g, '•') : value}
            <Box display="flex" flexDirection="row">
                <IconButton onClick={toggleHide} size="small">
                    {hide ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <CopyToClipboard value={value} />
            </Box>
        </Box>
    );
};

export default APIKey;
