import React, {
    useState,
    useContext,
    createContext,
    useEffect,
    useMemo,
} from 'react';
import { BulkListMapping } from '../services/BulkLists';

export enum StepTitles {
    UPLOAD_FILE = 'Upload File',
    MAP_FIELDS = 'Map Fields',
    PREVIEW_AND_IMPORT = 'Preview and Import',
}

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface BulkVerifyStepsState {
    activeStep: StepTitles;
    setActiveStep: SetState<StepTitles>;
    file: File | null;
    setFile: SetState<File | null>;
    fileHeaders: string[];
    setFileHeaders: SetState<string[]>;
    fileRowCount: number;
    setFileRowCount: SetState<number>;
    inputMapping: Partial<BulkListMapping>;
    setInputMapping: SetState<Partial<BulkListMapping>>;
    international: boolean;
    setInternational: SetState<boolean>;
    useGeocode: boolean;
    setUseGeocode: SetState<boolean>;
    runNCOA: boolean;
    setRunNCOA: SetState<boolean>;
    useProperCase: boolean;
    setUseProperCase: SetState<boolean>;
    defaultCountry: string;
    setDefaultCountry: (c: string) => void;
    prevRoute: string;
    hideInternationalVerification: boolean;
}

const BulkVerifyStepsContext = createContext<BulkVerifyStepsState | undefined>(
    undefined
);

interface BulkVerifyStepsProviderProps {
    bulkVerifyListRoute?: string;
    children: React.ReactNode | React.ReactNode[];
    hideInternationalVerification?: boolean;
}
// TODO: Change context to be prettier
const BulkVerifyStepsProvider = ({
    children,
    bulkVerifyListRoute,
    hideInternationalVerification = false,
}: BulkVerifyStepsProviderProps) => {
    const [activeStep, setActiveStep] = useState(StepTitles.UPLOAD_FILE);
    const [file, setFile] = useState<File | null>(null);
    const [fileHeaders, setFileHeaders] = useState<string[]>([]);
    const [fileRowCount, setFileRowCount] = useState(0);
    const [inputMapping, setInputMapping] = useState<Partial<BulkListMapping>>(
        {}
    );
    const [international, setInternational] = useState(false);
    const [useGeocode, setUseGeocode] = useState(false);
    const [runNCOA, setRunNCOA] = useState(false);
    const [useProperCase, setUseProperCase] = useState(false);
    const [defaultCountry, setDefaultCountry] = useState('');

    const prevRoute = useMemo(() => {
        return bulkVerifyListRoute ?? '/dashboard/bulk_verify';
    }, [bulkVerifyListRoute]);

    useEffect(() => {
        // If they change the file the old mappings may not make sense
        setInputMapping({});
    }, [file]);

    const value = {
        activeStep,
        setActiveStep,
        file,
        setFile,
        fileHeaders,
        setFileHeaders,
        fileRowCount,
        setFileRowCount,
        inputMapping,
        setInputMapping,
        international,
        setInternational,
        useGeocode,
        setUseGeocode,
        runNCOA,
        setRunNCOA,
        useProperCase,
        setUseProperCase,
        defaultCountry,
        setDefaultCountry,
        prevRoute,
        hideInternationalVerification,
    };

    return (
        <BulkVerifyStepsContext.Provider value={value}>
            {children}
        </BulkVerifyStepsContext.Provider>
    );
};

export const useBulkVerifyStepsContext = () => {
    const context = useContext(BulkVerifyStepsContext);
    if (context === undefined) {
        throw new Error(
            'useBulkVerifyStepsContext can only be used within a BulkVerifyStepsProvider'
        );
    }
    return context;
};

export default BulkVerifyStepsProvider;
