import { useMemo, useState, useEffect } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';

export type Interval = 'month' | 'year';

export interface Organization {
    id: string;

    name: string;

    stripeSubscriptionId: string;

    allowInvitingUsersWithoutSubscribing: boolean;

    allowEmbedding: boolean;

    accounts: {
        id: string;
        isSubscriptionAccount: boolean;
        updatedAt: Date;
    }[];

    usage: {
        lookups: number;

        verification: number;
        completion: number;
        parse: number;

        bulkLookups: number;
        intlBulkLookups: number;

        intlLookups: number;

        intlVerification: number;
        intlCompletion: number;
    };

    limit: {
        lookups: number;
        intlLookups: number;
        bulkLookups: number;
        intlBulkLookups: number;
    };

    enableBulkGeocoding?: boolean;
    enableBulkNCOA?: boolean;
}

export interface Subscription {
    plan?: {
        id: string;
        nickname: string;
        active: boolean;
        interval: Interval;

        amount: number;
        currency: string;
    };
    created: number;

    amount: number;

    status: string;
    description: string;

    metadata: {
        additionalLookupCharge?: number;
        additionalIntlLookupCharge?: number;
    };
}

export interface Plan {
    id: string;
    country?: string;
    name: string;

    amount: number;
    stripePriceId: string;
    billingInterval: Interval;

    limit?: number;
    intlLimit?: number;

    enterprise?: boolean;
    organization?: string;

    description?: string;

    metadata: {
        additionalLookupCharge?: number;
        additionalIntlLookupCharge?: number;
    };
}

export interface Invoice {
    id: string;
    created: number;
    customer_name: string;
    amount_paid: number;
    amount_due: number;
    invoice_pdf: string;
    status: 'draft' | 'open' | 'paid' | 'uncollectible' | 'void';
}

export interface Account {
    id: string;

    name: string;

    stripePaymentMethodId: string;

    card: {
        last4: string;
        expMonth: string;
        expYear: string;
    };
}

interface CreateSubscription {
    cancel_url: string;
    id: string;
    success_url: string;
}

interface UpdatePlan {
    plan: Subscription;
    items: {
        url: string;
    };
}

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }
    async get() {
        return await this.base.fetchAPI<Organization>(
            `/organizations/${this.base.authState?.user?.organization}`
        );
    }

    async getSubscription() {
        return await this.base.fetchAPIAllow404<Subscription>(
            '/organizations/subscription'
        );
    }

    async getPlans() {
        return await this.base.fetchAPI<Plan[]>('/subscription_prices/addver');
    }

    async subscribeToPlan(planId: string) {
        return await this.base.fetchAPI<CreateSubscription>(
            `/organizations/subscription_sessions/${planId}`,
            {
                method: 'POST',
                body: {
                    successUrl: `${process.env.REACT_APP_URL}/dashboard/upgrade?response=success`,
                    cancelUrl: `${process.env.REACT_APP_URL}/dashboard/upgrade?response=failure`,
                },
            }
        );
    }

    async changePlan(planId: string) {
        return await this.base.fetchAPI<UpdatePlan>(
            `/organizations/upgrades/${planId}`,
            {
                method: 'POST',
            }
        );
    }

    async getInvoices() {
        const invoices: Invoice[] = [];

        const res = await this.base.fetchAPI<{
            data: Invoice[];
            hasMore: boolean;
        }>(`/organizations/invoices?limit=100`);

        invoices.push(...res.data);

        return invoices;
    }

    async getAccount() {
        // HACK Terrible API
        const accounts = await this.base.fetchAPI<Account[]>('/accounts');

        return accounts.length > 0
            ? await this.base.fetchAPI<Account>(`/accounts/${accounts[0]?.id}`)
            : undefined;
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};

// Helper which reloads an organization whenever the given dependencies change
export const useOrganization = (dependencies: any[]) => {
    const service = useService();

    const [org, setOrg] = useState<Organization>();

    useEffect(() => {
        (async () => {
            try {
                setOrg(await service.get());
            } catch (err) {
                console.error(err);
            }
        })();
    }, [service, ...dependencies]);

    return org;
};
