import { useEffect, useState } from 'react';

import { useBulkVerifyStepsContext } from '../context/BulkVerifyStepsContext';
import { BulkListMapping } from '../services/BulkLists';

import { ADDRESS_FIELDS } from '../components/BulkStep2';

const validateHeader = (
    header: string,
    uniqueValue: string,
    uniqueHeaderIndex: number,
    headerIndex: number
) => {
    if (header.toLocaleLowerCase().includes(uniqueValue)) {
        // A value of 0 means we have not seen the value before
        // as the values are initialized to 0
        if (uniqueHeaderIndex === 0) {
            // Return the index + 1 because the header could be at
            // position 0 and then our checks would not work for checking
            // if the index is 0 or negative.
            return headerIndex + 1;
        } else {
            return -1;
        }
    }
    return uniqueHeaderIndex;
};

const updateMapping = (
    mapping: Partial<BulkListMapping>,
    valueToUpdate: typeof ADDRESS_FIELDS[keyof typeof ADDRESS_FIELDS],
    headers: string[],
    first: number,
    second?: number
) => {
    const firstVal = headers[first - 1];
    // Subtract 1 from the index when we check the position because we
    // add 1 to the index when we validate the header
    if (second !== undefined) {
        const secondVal = headers[second - 1];
        // If they are the same index then we can set the value regardless
        // This can occur when we pass `ProvinceOrState` as `State` and `Province`
        // will both appear but at the same index.
        if (first === second && firstVal) {
            mapping[valueToUpdate] = firstVal;
            return;
        }
        if (first > 0 && second === 0 && firstVal) {
            mapping[valueToUpdate] = firstVal;
        }
        if (second > 0 && first === 0 && secondVal) {
            mapping[valueToUpdate] = secondVal;
        }

        return;
    }

    if (first > 0 && firstVal) {
        mapping[valueToUpdate] = firstVal;
    }
};

const parseHeaders = (headers: string[]) => {
    const defaultMapping: Partial<BulkListMapping> = {};

    // Store a number to check the index of the header.
    //
    // Store the index of the header + 1 in the number if it is the first time
    // we have seen the unique header (value is 0).
    // Add +1 to the index to be able to check the case of the first time we
    // are seeing the unique value ('name' set to 0 initially) and that
    // value is the first in the header list (index 0).
    // When we insert the value into the list, subtract 1 from the index value
    // to account for that.
    // If we have seen the header before (value is greater than 0), then we
    // set the value to a negative value to indicate that this value is not
    // unique.
    let addressIndex = 0;
    let line1Index = 0;
    let line2Index = 0;
    let provinceIndex = 0;
    let stateIndex = 0;
    let postalIndex = 0;
    let zipIndex = 0;
    let countryIndex = 0;
    let cityIndex = 0;

    headers.forEach((header, i) => {
        addressIndex = validateHeader(header, 'address', addressIndex, i);
        line1Index = validateHeader(header, 'line1', line1Index, i);
        line2Index = validateHeader(header, 'line2', line2Index, i);
        provinceIndex = validateHeader(header, 'province', provinceIndex, i);
        stateIndex = validateHeader(header, 'state', stateIndex, i);
        postalIndex = validateHeader(header, 'postal', postalIndex, i);
        zipIndex = validateHeader(header, 'zip', zipIndex, i);
        countryIndex = validateHeader(header, 'country', countryIndex, i);
        cityIndex = validateHeader(header, 'city', cityIndex, i);
    });

    // If we find 'address' uniquely then map that
    // Only do this if we do not find a 'line1' or a 'line2'
    if (addressIndex > 0 && line1Index === 0 && line2Index === 0) {
        updateMapping(defaultMapping, 'line1', headers, addressIndex);
    } else {
        updateMapping(defaultMapping, 'line1', headers, line1Index);
        updateMapping(defaultMapping, 'line2', headers, line2Index);
    }

    updateMapping(
        defaultMapping,
        'provinceOrState',
        headers,
        provinceIndex,
        stateIndex
    );
    updateMapping(
        defaultMapping,
        'postalOrZip',
        headers,
        postalIndex,
        zipIndex
    );
    updateMapping(defaultMapping, 'country', headers, countryIndex);
    updateMapping(defaultMapping, 'city', headers, cityIndex);

    return defaultMapping;
};

/**
 * On render, parse the headers in the `BulkVerifyContext` and set the
 * `inputMappin` values automatically to values if they uniquely exists in the
 * CSV headers.
 */
const useCSVHeuristics = () => {
    const { setInputMapping, fileHeaders } = useBulkVerifyStepsContext();
    const [loadingHeuristics, setLoadingHeuristics] = useState(false);

    useEffect(() => {
        setLoadingHeuristics(true);
        const defaultMapping = parseHeaders([...fileHeaders]);
        setInputMapping(defaultMapping);
        setLoadingHeuristics(false);
    }, [fileHeaders, setInputMapping]);

    return { loadingHeuristics };
};

export default useCSVHeuristics;
