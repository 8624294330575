import { useMemo } from 'react';

import { Service as BaseService, useService as useBaseService } from './Base';

interface GetParams {
    startDate?: Date;
    endDate?: Date;

    tzName?: string;
}

export type Analytics = {
    date: Date;
    avLookup: {
        verification: number;
        completion: number;
        parse: number;

        bulk: number;
        intlBulk: number;

        intlCompletion: number;
        intlVerification: number;

        verified: number;
        corrected: number;
        failed: number;
    };
}[];

export class Service {
    base: BaseService;

    constructor(service: BaseService) {
        this.base = service;
    }

    async get(data: GetParams = {}) {
        if (!data.startDate) {
            // One week ago
            const d = new Date();

            d.setDate(d.getDate() - 7);

            data.startDate = d;
        }

        if (!data.endDate) {
            data.endDate = new Date();
        }

        const params = new URLSearchParams();

        params.set('startDate', data.startDate.toUTCString());
        params.set('endDate', data.endDate.toUTCString());

        params.set(
            'tzName',
            data.tzName || Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        return await this.base.fetchAPI<Analytics>(
            `/analytics?${params.toString()}`
        );
    }
}

export const useService = () => {
    const service = useBaseService();

    return useMemo(() => new Service(service), [service]);
};
