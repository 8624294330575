import logo from '../logo.png';
import loginGraphic from '../img/login-image.png';

import React, { useState, useContext, FormEvent } from 'react';

import { Redirect, Link as RouterLink } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import InputAdornment from '@material-ui/core/InputAdornment';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { useService as useBaseService } from '../services/Base';

import {
    Context as AuthContext,
    LoginPayload,
    ActionType,
} from '../context/Auth';
import { PM_AUTH_ENDPOINT_URL, PM_LOGIN_URL } from '../config';

import ConfirmActionDialog from '../components/ConfirmActionDialog';
import Button from '../components/Button';
import { CircularProgress } from '@material-ui/core';

// HACK These should be factored
export const RequiredField = (props: {
    label: string;
    type: string;
    value: string;
    setValue: (value: string) => void;
    autoFocus?: boolean;
    disabled?: boolean;
    siqatrib?: string;
}) => {
    return (
        <>
            <Typography color="textSecondary">{props.label}</Typography>
            <TextField
                required
                variant="outlined"
                type={props.type}
                value={props.value}
                onChange={(e) => {
                    props.setValue(e.target.value);
                }}
                size="small"
                autoFocus={props.autoFocus}
                fullWidth
                disabled={props.disabled}
                inputProps={{ siqatrib: props.siqatrib }}
            />
        </>
    );
};

export const PasswordField = <T,>(props: {
    label: string;
    value: string;
    setValue: (v: string) => void;
    showPassword: boolean;
    setShowPassword?: (v: boolean) => void;
    inputRef?: React.Ref<T>;
    disableMinLength?: boolean;
}) => {
    return (
        <>
            <Typography color="textSecondary">{props.label}</Typography>
            <TextField
                required
                variant="outlined"
                type={props.showPassword ? 'text' : 'password'}
                value={props.value}
                onChange={(e) => {
                    props.setValue(e.target.value);
                }}
                size="small"
                fullWidth
                inputRef={props.inputRef}
                inputProps={{
                    minLength:
                        !props.disableMinLength && props.setShowPassword
                            ? 8
                            : undefined,
                }}
                InputProps={{
                    endAdornment: props.setShowPassword && (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                    props.setShowPassword!(!props.showPassword)
                                }
                            >
                                {props.showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};

const Login = (props: { allowImpersonation?: boolean }) => {
    const service = useBaseService();

    const { state: authState, dispatch: authDispatch } =
        useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [showLoginRedirect, setShowLoginRedirect] = useState(false);
    const [impersonatedEmail, setImpersonatedEmail] = useState<string>();

    const [loading, setLoading] = useState(false);

    if (authState.user) {
        return <Redirect to="/dashboard" />;
    }

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setLoading(true);

            const res = await service.fetchAPI<LoginPayload>('/users/auth', {
                method: 'POST',
                body: {
                    email,
                    password,
                    impersonatedEmail,
                },
            });

            authDispatch({
                type: ActionType.LOGIN,
                payload: res,
            });
        } catch (err) {
            // Try authenticating with PM
            // If this fails, we revert to the original error. No need to catch
            // errors here since they would be misleading.
            if (PM_AUTH_ENDPOINT_URL && PM_LOGIN_URL) {
                try {
                    const res = await fetch(PM_AUTH_ENDPOINT_URL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: email.toLowerCase().trim(),
                            password,
                        }),
                    });

                    if (res.status === 201) {
                        setShowLoginRedirect(true);
                    }
                } catch {}
            }

            console.error(err);
        }

        setEmail('');
        setPassword('');

        setLoading(false);
    };

    return (
        <form onSubmit={onSubmit}>
            <ConfirmActionDialog
                title="Wrong Login Page"
                actionLabel="Take me there"
                confirm={() => {
                    window.location.assign(PM_LOGIN_URL);
                }}
                open={showLoginRedirect}
                onClose={() => setShowLoginRedirect(false)}
                text="You may be trying to sign into the Print & Mail dashboard."
            />

            <Backdrop open={loading} style={{ zIndex: 2000 }}>
                <CircularProgress />
            </Backdrop>

            <Grid
                container
                alignItems="center"
                style={{ minHeight: '100vh', backgroundColor: 'white' }}
            >
                <Grid item xs={12} sm={6} style={{ height: '100vh' }}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <img
                            src={loginGraphic}
                            alt="Login Graphic"
                            style={{
                                display: 'block',
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box px={5}>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={2}
                        >
                            <Grid item>
                                <img src={logo} alt="logo" />
                            </Grid>

                            <Grid item>
                                <Typography variant="h6">
                                    Log in to the Address Verification Dashboard
                                </Typography>
                            </Grid>

                            <Grid item>
                                <RequiredField
                                    label="Email"
                                    value={email}
                                    setValue={setEmail}
                                    type="email"
                                    autoFocus
                                />
                            </Grid>

                            <Grid item>
                                <PasswordField
                                    label="Password"
                                    value={password}
                                    setValue={setPassword}
                                    showPassword={showPassword}
                                    setShowPassword={setShowPassword}
                                    disableMinLength
                                />
                            </Grid>

                            {props.allowImpersonation && (
                                <Grid item>
                                    <TextField
                                        variant="outlined"
                                        type="email"
                                        label="Login As"
                                        value={impersonatedEmail}
                                        onChange={(e) => {
                                            setImpersonatedEmail(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item>
                                <Typography align="right">
                                    <Link
                                        component={RouterLink}
                                        to="/forgot_password"
                                    >
                                        Forgot your password?
                                    </Link>
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    size="large"
                                    style={{ height: '55px' }}
                                >
                                    Sign In
                                </Button>
                            </Grid>

                            <Grid item>
                                <Box mt={4} />
                            </Grid>

                            <Grid item>
                                <Typography align="center" variant="body1">
                                    Don't have an account?{' '}
                                    <Link component={RouterLink} to="/signup">
                                        Sign up now
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default Login;
