import React, { useContext, useEffect, useState, useRef } from 'react';

import {
    Typography,
    Grid,
    Paper,
    makeStyles,
    Link,
    createMuiTheme,
    Theme,
    ThemeProvider,
    Divider,
} from '@material-ui/core';

import { ActionType, Context as AuthContext } from '../context/Auth';
import { Context as NotificationContext } from '../context/Notification';
import { MessageType } from '../context/Notification';

import { useOrganization } from '../services/Organization';
import { useService as useUserService } from '../services/Users';

import passwordDialogImage from '../img/passwordShield.png';
import TextField from '../components/TextInput';
import Button from '../components/Button';
import DialogBox from '../components/DialogBox';
import TopNav from '../components/TopNav';
import EmbeddedIntegrations from '../components/EmbeddedIntegrations';
import { PasswordField } from './Login';

const useStyles = makeStyles({
    paperRoot: {
        padding: '1.3rem 2rem',
    },
    pointer: {
        cursor: 'pointer',
    },
});

interface ProfileInterface {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
    memberSince: Date;
    password: string;
}

const EditProfile = ({
    setProfile,
    profile,
    setEdit,
}: {
    setProfile: (arg1: ProfileInterface) => void;
    profile: ProfileInterface;
    setEdit: (arg1: boolean) => void;
}) => {
    const { dispatch: authStateDispatch } = useContext(AuthContext);
    const { dispatch: notificationDispatch } = useContext(NotificationContext);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showDialogBox, setShowDialogBox] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleInputChange =
        (name: string) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setProfile({ ...profile!, [name]: e.target.value });
        };

    const classes = useStyles();
    const userService = useUserService();

    const confirmRef = useRef<HTMLInputElement>();

    useEffect(() => {
        const match = newPassword === confirmPassword;

        if (confirmRef.current) {
            confirmRef.current.setCustomValidity(
                match ? '' : 'Passwords do not match.'
            );
        }
    }, [newPassword, confirmPassword]);

    const handleProfileUpdate = async () => {
        try {
            setLoading(true);

            const newUser = await userService.updateUser(profile!);

            setEdit(false);

            notificationDispatch({
                type: MessageType.SUCCESS,
                message: 'Profile updated successfully',
            });

            authStateDispatch({
                type: ActionType.UPDATE_USER,
                user: newUser,
            });
        } catch (err) {
            console.error(err);
        }

        setLoading(false);
    };

    return (
        <Grid
            container
            item
            direction="column"
            spacing={3}
            style={{ margin: '1.2rem 0.2rem' }}
        >
            <Grid container item spacing={3}>
                <Grid item sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="firstName"
                        label="First Name"
                        onChange={(e) => handleInputChange('firstName')(e)}
                        defaultValue={profile.firstName}
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        id="lastName"
                        label="Last Name"
                        onChange={(e) => handleInputChange('lastName')(e)}
                        defaultValue={profile.lastName}
                    />
                </Grid>
            </Grid>

            <Grid item>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    id="companyName"
                    label="Company Name"
                    onChange={(e) => handleInputChange('companyName')(e)}
                    defaultValue={profile.companyName}
                />
            </Grid>

            <Grid item>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    id="email"
                    label="Email Address"
                    onChange={(e) => handleInputChange('email')(e)}
                    defaultValue={profile.email}
                />
            </Grid>

            <Grid item>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    id="memberSince"
                    label="Member Since"
                    defaultValue={profile.memberSince.toString()}
                />
            </Grid>

            <Grid item>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled
                    id="password"
                    label="Password"
                    defaultValue={profile.password}
                    InputProps={{
                        endAdornment: (
                            <Link
                                variant="body2"
                                onClick={() => {
                                    setShowDialogBox(true);
                                }}
                                className={classes.pointer}
                                color="primary"
                            >
                                Change&nbsp;Password
                            </Link>
                        ),
                    }}
                />
            </Grid>

            {showDialogBox && (
                <DialogBox
                    open={showDialogBox}
                    title=""
                    actionLabel="Submit"
                    cancelLabel="Cancel"
                    onClose={() => {
                        setShowDialogBox(false);
                    }}
                    image={passwordDialogImage}
                    onConfirm={async () => {
                        setShowDialogBox(false);

                        try {
                            if (newPassword !== confirmPassword) {
                                return;
                            }

                            await userService.updatePassword({
                                oldPassword,
                                newPassword,
                            });
                            notificationDispatch({
                                type: MessageType.SUCCESS,
                                message: 'Password changed successfully',
                            });
                        } catch (err) {
                            console.error(err);
                        }
                        setOldPassword('');
                        setNewPassword('');
                        setConfirmPassword('');
                    }}
                >
                    <Grid container spacing={1} justify="center">
                        <Typography
                            variant="h6"
                            style={{ textAlign: 'center' }}
                        >
                            Change Password
                        </Typography>
                        <Grid item xs={11}>
                            <PasswordField
                                label="Old Password"
                                value={oldPassword}
                                setValue={setOldPassword}
                                showPassword={showOldPassword}
                                setShowPassword={setShowOldPassword}
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <PasswordField
                                label="New Password"
                                value={newPassword}
                                setValue={setNewPassword}
                                showPassword={showNewPassword}
                                setShowPassword={setShowNewPassword}
                            />
                            <PasswordField
                                label="Confirm New Password"
                                inputRef={confirmRef}
                                value={confirmPassword}
                                setValue={setConfirmPassword}
                                showPassword={showConfirmPassword}
                                setShowPassword={setShowConfirmPassword}
                            />
                        </Grid>
                    </Grid>
                </DialogBox>
            )}

            <Grid item container spacing={1}>
                <Grid item>
                    <Button
                        fullWidth
                        variant="outlined"
                        onClick={() => setEdit(false)}
                    >
                        Cancel
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={handleProfileUpdate}
                        disabled={loading}
                    >
                        Update
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const ProfileItem = ({
    fieldName,
    fieldValue,
}: {
    fieldName: string;
    fieldValue: string | Date;
}) => {
    return (
        <>
            <Typography variant="caption">{fieldName}</Typography>
            <Divider
                style={{ backgroundColor: '#e8e8e8', margin: '5px 0px' }}
            />
            <Typography variant="body2">{fieldValue.toString()}</Typography>
        </>
    );
};

const Profile = () => {
    const classes = useStyles();

    const { state: authState } = useContext(AuthContext);

    const org = useOrganization([]);

    const [edit, setEdit] = useState(false);

    const [profile, setProfile] = useState<ProfileInterface>();

    useEffect(() => {
        setProfile({
            firstName: authState?.user?.firstName || '',
            lastName: authState?.user?.lastName || '',
            companyName: org?.name || '',
            email: authState?.user?.email || '',
            memberSince: authState?.user?.createdAt || new Date(),
            password: '********',
        });
    }, [org, authState]);

    return (
        <>
            <TopNav />
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Paper className={classes.paperRoot}>
                        <Grid container spacing={3}>
                            <Grid
                                container
                                item
                                justify="space-between"
                                xs={12}
                                style={{ borderBottom: '1px #f2f2f2 solid' }}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        User Information
                                    </Typography>
                                </Grid>
                                {!edit && (
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={!profile}
                                            onClick={() => {
                                                setEdit(true);
                                            }}
                                        >
                                            Edit Profile
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>

                            {profile &&
                                (edit ? (
                                    <EditProfile
                                        profile={profile}
                                        setEdit={setEdit}
                                        setProfile={setProfile}
                                    />
                                ) : (
                                    <ThemeProvider
                                        theme={(theme: Theme) =>
                                            createMuiTheme({
                                                ...theme,
                                                typography: {
                                                    ...theme.typography,
                                                    caption: {
                                                        ...theme.typography
                                                            .body2,
                                                        fontFamily: 'Roboto',
                                                        fontSize: '0.865rem',
                                                        color: '#A5A5A5',
                                                        fontWeight: 400,
                                                    },
                                                    body2: {
                                                        ...theme.typography
                                                            .body2,
                                                        fontFamily: 'Roboto',
                                                        fontSize: '0.965rem',
                                                        color: 'black',
                                                    },
                                                },
                                            })
                                        }
                                    >
                                        <Grid
                                            container
                                            item
                                            direction="column"
                                            spacing={3}
                                            style={{ margin: '1.2rem 0.2rem' }}
                                        >
                                            <Grid
                                                container
                                                justify="space-between"
                                                item
                                                spacing={3}
                                            >
                                                <Grid item sm={5}>
                                                    <ProfileItem
                                                        fieldName="First Name"
                                                        fieldValue={
                                                            profile.firstName
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item sm={5}>
                                                    <ProfileItem
                                                        fieldName="Last Name"
                                                        fieldValue={
                                                            profile.lastName
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item>
                                                <ProfileItem
                                                    fieldName="Company Name"
                                                    fieldValue={
                                                        profile.companyName
                                                    }
                                                />
                                            </Grid>

                                            <Grid item>
                                                <ProfileItem
                                                    fieldName="Email Address"
                                                    fieldValue={profile.email}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <ProfileItem
                                                    fieldName="Member Since"
                                                    fieldValue={
                                                        profile.memberSince
                                                    }
                                                />
                                            </Grid>

                                            <Grid item>
                                                <ProfileItem
                                                    fieldName="Password"
                                                    fieldValue={
                                                        profile.password
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </ThemeProvider>
                                ))}
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item>
                    <EmbeddedIntegrations
                        allowEmbedding={org?.allowEmbedding}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export { Profile as default, ProfileItem };
